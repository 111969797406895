import React, { useState, useEffect } from 'react'
import date from '../../services/global'
import $ from 'jquery'
import Toast from '../../components/util/Toast'
import serviceReport from '../../services/report'
import ReactTooltip from 'react-tooltip'
import ModalSpiner from '../../components/util/ModalSpiner'
import helper from '../../services/helper'
const GeneralReport = () => {
  const [fechaInicio, setFechaInicio] = useState('')
  const [fechaFin, setFechaFin] = useState('')
  const [total, setTotal] = useState(0)
  const d = date.getDate()
  const [data, setData] = useState([])
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  const [spiner, setSpiner] = useState(false)
  useEffect(() => {
    try {
      $('#campaing-table-report').dataTable().fnDestroy()
    } catch (e) {
    }
  }, [])
  const handleGenerar = () => {
    if ((fechaInicio === '' && fechaFin === '') || ('' + fechaInicio === 'null' && '' + fechaFin === 'null')) {
      global.LABEL_INPUT_ERROR = global.CAMPAIGN_FECHA
      $('#fechaInicio').addClass('error')
      $('#fechaFin').addClass('error')
      mostrarMensaje('400', global.LABEL_INPUT_ERROR)
    } else if (fechaInicio === '' || fechaInicio === 'null') {
      global.LABEL_INPUT_ERROR = global.CAMPAIGN_FECHA + ' inicio'
      $('#fechaInicio').addClass('error')
      mostrarMensaje('400', global.LABEL_INPUT_ERROR)
    } else if (fechaFin === '' || fechaFin === 'null') {
      $('#fechaFin').addClass('error')
      global.LABEL_INPUT_ERROR = global.CAMPAIGN_FECHA + ' finalización'
      mostrarMensaje('400', global.LABEL_INPUT_ERROR)
    } else if (fechaInicio > fechaFin) {
      $('#fechaInicio').addClass('error')
      mostrarMensaje('400', 'Su rango de fecha es inválido')
    } else {
      setSpiner(true)
      service()
    }
  }
  const service = async () => {
    $('#campaing-table-report').dataTable().fnDestroy()
    const serviceRe = await serviceReport.getReportGeneral({ fechaInicio, fechaFin })
    if (serviceRe.data.code === 200) {
      setSpiner(false)
      setData(serviceRe.data.data)
      setTotal(serviceRe.data.data.length)
      if (serviceRe.data.data.length > 0) {
        $('.fa-file-pdf').addClass('green-color')
        $('.fa-arrow-down').addClass('red-color')
        $('.btnPDF').addClass('color-border')
      } else {
        $('.fa-file-pdf').removeClass('green-color')
        $('.fa-arrow-down').removeClass('red-color')
        $('.btnPDF').removeClass('color-border')
      }
      $('#campaing-table-report').find('tr').each(function () {
        $(this).css('text-align', 'center')
      })
      $('#campaing-table-report').DataTable({
        initComplete: function (settings, json) {
          $('body').find('.dataTables_scrollBody').addClass('scrollbar')
        },
        scrollY: '250px',
        scrollCollapse: true,
        order: [[2, 'desc']],
        lengthMenu: [5, 10, 20],
        language: {
          decimal: '',
          emptyTable: 'No hay información',
          info: 'Mostrando _START_ a _END_ de un total de _TOTAL_ Registros',
          infoEmpty: 'Mostrando 0 to 0 of 0 Registros',
          infoFiltered: '(Filtrado de _MAX_ total Registros)',
          infoPostFix: '',
          thousands: ',',
          lengthMenu: 'Mostrar _MENU_ Registros',
          loadingRecords: 'Cargando...',
          processing: 'Procesando...',
          search: 'Buscar:',
          zeroRecords: 'Sin resultados encontrados',
          paginate: {
            first: 'Primero',
            last: 'Ultimo',
            next: '>',
            previous: '<'
          }
        }
      })
    }
  }
  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000)
  }
  const HandleError = () => {
    $('#fechaFin').removeClass('error')
    $('#fechaInicio').removeClass('error')
  }
  const handlePDF = async () => {
    if ($('.fa-file-pdf').hasClass('green-color')) {
      setSpiner(true)
      const localData = JSON.parse(window.localStorage.getItem('loggedUser'))
      const user = helper.toTitleCase(localData.nombres.split(' ')[0]) + ' ' + helper.toTitleCase(localData.apellidos.split(' ')[0])
      const servicePdf = await serviceReport.getPDFCampaingn({ fechaInicio, fechaFin, user })
      setSpiner(false)
      if (servicePdf.code === 200) {
        const url = window.URL.createObjectURL(new Blob([servicePdf.data]))
        const link = document.createElement('a')
        console.log(link)
        link.href = url
        link.setAttribute('download', 'reporte-general.pdf')
        document.body.appendChild(link)
        link.click()
      }
    }
  }
  return (
    <div>
      <ModalSpiner opt={spiner} />
      {isOpenToast && <Toast message={messageToast} codigo={codigoToast} />}
      <div className="mb-12 panel-group mt-3">
        <div className='col mb-1 mt-2'>
          <span className='campaing-tittle'><b>Reporte personalizado</b></span>
        </div>
        <hr/>
        <div className="row justify-conten-around mt-4 ml-2 align-items-end">
          <div className="col-md-3 form-group" id='dates_'>
              <span className="text-input name-input every-label" id='error_fecha'></span>
              <label className='grey-color'>Fecha de inicio</label>
              <input className='form-control item' id='fechaInicio' type="date" name="dob" value={fechaInicio} onChange={({ target }) => setFechaInicio(target.value)} max={d} onClick={HandleError} />
          </div>
          <div className="col-md-3 form-group" id='dates_'>
            <span className="text-input name-input every-label" id='error_fecha'></span>
            <label className='grey-color'>Fecha de finalización</label>
            <input className='form-control item' id='fechaFin' type="date" name="dob" value={fechaFin} onChange={({ target }) => setFechaFin(target.value)} max={d} onClick={HandleError} />
          </div>
          <div className='form-group col-1 p-1 ml-4 mr-2' >
            <label className='grey-color'>Tipo de archivo</label>
            <div className='row col btnPDF ml-3' onClick={handlePDF}><i className="far fa-file-pdf"></i> <i className="fa fa-arrow-down ml-1" aria-hidden="true"></i></div>
          </div>
          <div className='col-md-2 ml-auto mb-3'>
            <button className='btn btn-success btn-campania contacts report-btn'onClick={handleGenerar} >Generar</button>
          </div>
        </div>
        <div className="mb-12 panel-group mt-4 ml-3">
          <div className='row mb-1 mt-2'>
            <div className='col-5'>
              <span className='campaing-tittle'><b>Historial de campañas</b></span>
            </div>
            <div className='col-7 text-align-end pr-5'>
              <p className='ml-4'><i className='fa fa-chart-pie mr-2'></i> Actualmente tienes <b>{total}  reportes</b></p>
            </div>
          </div>
        </div>
        <hr className='linea'/>
        <div className='mt-3 pr-4  data-report'>
            <table id='campaing-table-report' className="table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Canal</th>
                <th>Plantilla</th>
                <th>Total de envíos</th>
                <th>Envíos exitosos</th>
                <th>Fecha de programación</th>
              </tr>
            </thead>
            <tbody id='tbody'>
            {data.map((val) => {
              return (<React.Fragment key={val.id}>
                                  <tr>
                                    <td className='dataTables_empty'>
                                      {val.nombreCampana.length > 30
                                        ? <div className='ajust-text' data-tip={`<p class="content-tool"> ${val.nombreCampana.toLowerCase()}</p>`} data-html={true}>
                                        {val.nombreCampana.toLowerCase()}
                                        <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                        </div>

                                        : <>
                                        <span>{val.nombreCampana.toLowerCase()}</span>
                                        </>
                                      }
                                    </td>
                                    <td>{val.tipoPlantilla === 'T' ? 'SMS' : val.tipoPlantilla === 'H' ? 'Mail' : val.tipoPlantilla === 'E' ? 'WhatsApp' : ''}</td>
                                    <td className='dataTables_empty'>
                                      {val.nombrePlantilla.length > 30
                                        ? <div className='ajust-text' data-tip={`<p class="content-tool"> ${val.nombrePlantilla.toLowerCase()}</p>`} data-html={true}>
                                        {val.nombrePlantilla.toLowerCase()}
                                        <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                        </div>

                                        : <>
                                        <span>{val.nombrePlantilla.toLowerCase()}</span>
                                        </>
                                      }
                                    </td>
                                    <td>{val.enviados}</td>
                                    <td>{val.exitosos}</td>
                                    <td >{val.fechaProgramacion} </td>
                                  </tr>
                                  </React.Fragment>
              )
            }
            )}
            </tbody>
            </table>
            </div>
      </div>
    </div>
  )
}
export default GeneralReport

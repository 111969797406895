/* eslint-disable indent */
import '../../scss/_login.scss'
import React, { useState, useEffect } from 'react'
import Banner from './Banner'
import ModalSpiner from '../util/ModalSpiner'
import ModalNewMessages from '../util/ModalGeneral'
import loginServices from '../../services/login'
import Footer from './Footer'
import ImgLog from '../util/imgNotify'
import $ from 'jquery'

export default function Login () {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [spiner, setSpiner] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [header, setHeader] = useState('')
  const [body, setBody] = useState('')
  const [coloHeader, setColorHeader] = useState('')
  const [modalButton, setModalButton] = useState('')
  const [handle, setHandle] = useState()
  const [closes, setCloses] = useState()
  const [hclose, setHclose] = useState()

  useEffect(() => {
    servicioParameter()
  }, [])
  const something = (event) => {
    if (event.keyCode === 13) {
      $('#btnLogin').click()
    }
  }

  const servicioParameter = async (event) => {
    const idGrupo = 33
    const parametro = await loginServices.getParametrosPorId(idGrupo)
    if ('' + parametro.data.data.row.valor === 'S') {
      $('#registro').removeClass('no-success-panel')
    }
  }

  const handleClose = (op) => () => {
    setIsOpen(false)
  }
  const handleRef = (op) => () => {
    window.location.href = global.ROUTE_RECUPERAR
  }
  const loginHandler = async (event) => {
    event.preventDefault()
    $(global.FIELD).html(' ')
    $(global.INPUT_FIELD).removeClass('error')
    setSpiner(true)
    try {
      const userData = await loginServices.login({
        username,
        password
      })
      // console.log('userData', userData)
      if (userData.error === 1) {
        setSpiner(false)
        $(global.FIELD).html(global.LABEL_INPUT_ERROR)
        $(global.INPUT_FIELD).addClass('error')
      } else if (userData.error === 401) {
        setSpiner(false)
        setIsOpen(true)
        setColorHeader('text-center')
        setBody(global.BODY)
        setModalButton('Aceptar')
        setHandle(handleClose)
        setHclose(handleClose)
        setCloses('X')
        $('.MuiDialogContentText-root').addClass('text-center')
       } else if (userData.error === 423) {
        setSpiner(false)
        setIsOpen(true)
        setColorHeader('text-center')
        setBody(global.BODY)
        setModalButton('Desbloquear cuenta')
        setHandle(handleRef)
        setHclose(handleClose)
        setCloses('X')
        $('.MuiDialogContentText-root').addClass('text-center')
      } else if (userData.error === 404) {
        setSpiner(false)
        setIsOpen(true)
        setColorHeader('text-center')
        setBody(global.BODY)
        setModalButton('Aceptar')
        setHandle(handleClose)
        setHclose(handleClose)
        setCloses('X')
        $('.MuiDialogContentText-root').addClass('text-center')
      } else if (userData.error === 400) {
        setSpiner(false)
        setIsOpen(true)
        setColorHeader('text-center')
        setBody(global.BODY)
        setModalButton('Aceptar')
        setHandle(handleClose)
        setHclose(handleClose)
        setCloses('X')
        $('.MuiDialogContentText-root').addClass('text-center')
      } else {
        setTimeout(function () {
          setSpiner(false)
          window.location.href = userData.url
        }, 2000)
      }
    } catch (e) {
      setSpiner(false)
      setIsOpen(true)
      setHeader(global.MODAL_HEAD_ERROR)
      setBody(global.ERROR_TRYCATCH)
      setModalButton('Aceptar')
      setTimeout(function () {
        setIsOpen(false)
      }, 3000)
    }
  }
  return (
    <div className="background0" id="backSelect">
    <ImgLog />
      <div className="backgroundCircle"></div>
      <ModalNewMessages isOpen={isOpen} header={header} body={body} color={coloHeader} button={modalButton} handle={handle} close={closes} hclose={hclose}/>
      <ModalSpiner opt={spiner} />
      <div className="loginContainer container-fluid">
        <Banner />
        <div className="login-form">
          <div className="col-md-auto container-bienvenida">
            <div className="mb-4">
              <h3><b>{global.WELCOME}</b></h3>
              <p className="mb-4"><b>{global.WELCOME_STRING} </b></p>
            </div>
            <div className="form-group ml-5">
              <div className="wrap-input100 validate-input m-b-26 every-input" id='user' data-validate="Username is required">
                <span className="label-input100"><i className="fa fa-user"></i></span><span className="text-input user-input every-label"></span>
                <input maxLength="150" minLength="1" onKeyDown={(e) => something(e) } onChange={({ target }) => setUsername(target.value)} className="input100 color-label" type="text" value={username} name="Username" placeholder={global.USERNAME } id='user' />
                <span className="focus-input100"></span>
              </div>
            </div>
            <div className="form-group ml-5 mb-4">
              <div className="wrap-input100 validate-input m-b-26 every-input" id='pass' data-validate="Username is required">
                <span className="label-input100"><i className='fa fa-lock'></i></span><span className="text-input pass-input every-label"></span>
                <input maxLength="20" minLength="1" onKeyDown={(e) => something(e) } onChange={({ target }) => setPassword(target.value)} value={password} className="input100 color-label" type="password" name="Password" placeholder={global.PASSWORD} id='pass' />
                <span className="focus-input100"></span>
              </div>
            </div>
            <button onClick={loginHandler} id='btnLogin' className="btn btn-block btn-primary login0 rounded-pill">Ingresar</button>
              <span className="col-12 pass mt-2"> <a href={global.ROUTE_PASSWORD}className="forgot-pass color-restore"> {global.FORGOT}</a></span>
              <span className="color-restore col-12 row m-0 registro no-success-panel" id='registro'>{global.NEW_USER}<a href={global.ROUTE_CREATEUSER} className="forgot-pass"> <p className='notify-color position'><b> Regístrate</b></p></a></span>
          </div>
        </div>
      </div>
      <div className='footer-login'><Footer /></div>
    </div>
  )
}

/* eslint-disable no-trailing-spaces */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */
/* eslint-disable comma-spacing */
/* eslint-disable keyword-spacing */
/* eslint-disable space-before-blocks */
/* eslint-disable array-callback-return */
/* eslint-disable arrow-spacing */
/* eslint-disable no-undef */
/* eslint-disable quotes */
/* eslint-disable semi */
/* eslint-disable indent */
/* eslint-disable padded-blocks */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-const */
import { useState } from 'react'
import { MDBDataTableV5 } from 'mdbreact'

const TableCheck = ({ mostrarContent, listas, agregaListaAll, agregaLista, lista, listasDataGeneral,setPageSelect }) => {

   let dataTable = {
        columns: [
          {
            label: 'Acción',
            field: 'accion',
            width: 500
          },
          {
            label: 'Nombre de la Lista',
            field: 'nameList',
            width: 500
          },
          {
            label: 'Fecha Creación',
            field: 'dateCreate',
            width: 150
          },
          {
            label: 'Tipo Canal',
            field: 'typeCanal',
            width: 500
          },
          {
            label: 'Suscriptores',
            field: 'suscrpters',
            width: 100
          }
        ],
        rows: listas
      }
    let listaSelct = JSON.parse(localStorage.getItem('list'))
    let pageSelect = 1;
    const handleChange = (value) => {
        localStorage.setItem('pageSelect', value.activePage)
        console.log('value', value);
        console.log('lista', lista);
        console.log('listasDataGeneral', listasDataGeneral);
        // setPageSelect(value.activePage)
        pageSelect = value.activePage
        if(listasDataGeneral.length > 0){
            listasDataGeneral.map((li)=>{
                console.log(`#inputTable${li.idGrupo}`);
                $(`#inputTable${li.idGrupo}`).prop('checked', false)
            })
        }
        setTimeout(()=>{
            listaSelct = JSON.parse(localStorage.getItem('list'))
            if(listaSelct.length > 0){
                listaSelct.map((li)=>{
                    console.log(`#inputTable${li}`);
                    $(`#inputTable${li}`).prop('checked', true)
                })
            }            
         
        }, 200)

    }
    const agregaListaAllTable = (val) => {
      if(listaSelct){ listaSelct = [] }
      
      if(listasDataGeneral.length > 0){
          listasDataGeneral.map((li)=>{
            if(val){
              if(li.permitido === 'true'){
                console.log(`#inputTable${li.idGrupo}`);
                $(`#inputTable${li.idGrupo}`).prop('checked', val)
              }
            }else{
              $(`#inputTable${li.idGrupo}`).prop('checked', val)
            }
         
          })
      }
      agregaListaAll(val)
    }
  return (
    <>
                  <div className='header-all-group'>
                      <span className="text-input name-input every-label" id='errorListas'></span>
                      <input onClick={(e) => { agregaListaAllTable(e.target.checked) }} type="checkbox" className="ml-1 col-1 w-check check-color-list"></input>
                  </div>
                        <MDBDataTableV5
                            hover
                            id='tableDataReg'
                            entriesOptions={[5, 20, 25]}
                            entries={5}
                            entrieslabel={'Mostrando'}
                            pagesAmount={4}
                            data={dataTable}
                            pagingTop
                            searchTop
                            searchBottom={false}
                            barReverse
                            displayStart={10}
                            onPageChange={ value => handleChange(value) }
                        />
                         <div className="text-center">
                            <div className='btn-sgt-step3'><button onClick={ () => mostrarContent(3)} className='btnDelete' >Siguiente</button></div>
                        </div>
    </>
  )
}

export default TableCheck

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import InputEmoji from 'react-input-emoji'
import '../../../scss/_tenmplateSms.scss'
import ModalSpiner from '../../../components/util/ModalSpiner'
import templateServices from '../../../services/templates'
import ModalCancelTemplate from '../../../components/util/ModalCancelTemplate'
import Toast from '../../../components/util/Toast'
import $ from 'jquery'
const EditTemplate = () => {
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  const [nameTemplate, setNameTemplate] = useState('')
  const [text, setText] = useState('')
  const [descTemplate, setDescTemplate] = useState('')
  const [spiner, setSpiner] = useState(false)
  const [isOpenC, setIsOpenC] = useState(false)
  const [headerC, setHeaderC] = useState('')
  const [handleC, setHandleC] = useState()
  const [bodyC, setBodyC] = useState('')
  const [modalButtonC, setModalButtonC] = useState('')
  const [coloHeaderC, setColorHeaderC] = useState('')
  const [modalButtonCerrarC, setModalButtonCerrarC] = useState('')
  const [type, setType] = useState('')
  const [opcion, setOpcion] = useState(1)

  useEffect(() => {
    const idPlantillaSms = localStorage.getItem('idPlantillaSms')
    if (idPlantillaSms) {
      loadEditTemplate()
    }
  }, [])

  const loadEditTemplate = async () => {
    try {
      setSpiner(true)
      const resp = await templateServices.loadTemplatesEdit()
      setSpiner(false)
      mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
      if (resp.error === 200) {
        setNameTemplate(resp.data.data.nombre)
        setText(resp.dataDet.data.row[0].contenido)
        setDescTemplate(resp.data.data.descripcion)
      }
    } catch (e) {
    }
  }

  const automaticSave = async () => {
    try {
      saveSmsTemplates()
    } catch (e) {
    }
  }

  setInterval(automaticSave, 15000000)

  const saveSmsTemplates = () => {
    save()
  }

  const save = async () => {
    $('#nameTemplate').removeClass('error')
    $('#descTemplate').removeClass('error')
    $('#textTemplate').removeClass('error')
    setSpiner(true)
    const data = await templateServices.saveHeadTemplatesSms({
      nameTemplate,
      text,
      descTemplate
    })
    if (data.errorValidation !== 0) {
      mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
      setOpcion(data.validate.clickPanel)
      data.validate.nameTemplate && $('#' + data.validate.nameTemplate).addClass('error')
      data.validate.descTemplate && $('#' + data.validate.descTemplate).addClass('error')
      data.validate.textTemplate && $('#' + data.validate.textTemplate).addClass('error')
    } else {
      setOpcion(1)
      mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
    }
    setSpiner(false)
  }

  const detail = async () => {
    try {
      setSpiner(true)
      await templateServices.saveDetailTemplatesSms({
        nameTemplate,
        descTemplate,
        text
      })
      setSpiner(false)
      message(global.LABEL_INPUT_ERROR)
    } catch (e) {
    }
  }

  const handleClose = () => () => {
    setIsOpenC(false)
  }

  const clear = () => {
    setNameTemplate('')
    setDescTemplate('')
    setText('')
    mostrarMensaje('200', 'Se limpió el formulario')
  }

  const cancelSmsTemplates = () => {
    setIsOpenC(true)
    setColorHeaderC(global.MODAL_HEAD_CANCEL_TEMPLATE)
    setBodyC(global.MODAL_CANCEL_TEMPLATE)
    setModalButtonC('Aceptar')
    setModalButtonCerrarC('Cancelar')
    setHandleC(handleClose)
    setType('T')
  }

  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000)
  }

  const message = (message) => {
    $('.' + global.ERROR_LABEL + '-alert').html('')
    $('.' + global.ERROR_LABEL + '-alert').append(message)
    $('.' + global.ERROR_LABEL).removeClass(global.ERROR_LABEL + '-alert-hide')
    $('.' + global.ERROR_LABEL).addClass(global.ERROR_LABEL + '-alert-show')
    setTimeout(function () {
      $('.' + global.ERROR_LABEL + '-alert').html('')
      $('.' + global.ERROR_LABEL).removeClass(global.ERROR_LABEL + '-alert-show')
      $('.' + global.ERROR_LABEL).addClass(global.ERROR_LABEL + '-alert-hide')
    }, 5000)
  }

  const handleSegment = (opt) => {
    setOpcion(opt)
  }

  const removeError = (opt) => {
    $('#' + opt.id).removeClass('error')
  }
  return (
    <div className='container-fluid-master'>
      {isOpenToast && <Toast message={messageToast} codigo={codigoToast} />}
      <ModalSpiner opt={spiner} />
      <form>
        <div className="row">
          <div className="col">
            <div className='col-md-7'><a href={global.ROUTE_TEMPLASTES}><h4><i className="fa fa-chevron-left" aria-hidden="true"></i>{global.RETURN_TEMPLATE}</h4></a></div>
          </div>
        </div>
      </form>
      <hr className='hr2' />
      <h3 className='center'><b>{global.CREATE_SMS_TEMPLATE} </b></h3>
      <hr className='hr2' />
      <form>
        <div className="row">
          <div className="col-md-2">
            <a type="button" title={global.DELETE_BTN} onClick={clear} className="btn btn-outline-dark"><i className='fa fa-eraser'></i></a>
          </div>
        </div>
      </form>
      <ModalCancelTemplate isOpen={isOpenC} type={type} header={coloHeaderC} body={bodyC} color={coloHeaderC} button={modalButtonC} buttonCerrar={modalButtonCerrarC} handle={handleC} />
      <div className='row col-12 align-items-center justify-content-center mb-2'>
        <div className='col-4 p-text-l'>
          <div type='button' onClick={() => handleSegment(1)}>{global.CONTENT}
            <div className={opcion === 2 ? 'd-none' : 'success-panel'} id='panel-sub'>
              <hr className='sub-color-template'></hr>
            </div>
          </div>
        </div>
        <div className='col-4'></div>
        <div className='col-4'>
          <div type='button' onClick={() => handleSegment(2)}>{global.TEMPLATE_CONFIG}
            <div className={opcion === 1 ? 'd-none' : 'success-panel'} id='panel-sub2'>
              <hr className='sub-color2-template'></hr>
            </div>
          </div>
        </div>
        {opcion === 1

          ? <div className='container-fluid'>
            <div className="form-group">
              <label className="form-label"><b>{global.TEMPLATE_NAME_SMS}</b><span className="text-input name-input"></span> </label><strong className="obl">{global.REQUIRED}</strong>
              <input onKeyUp={({ target }) => removeError(target)} id="nameTemplate" placeholder={global.TEMPLATE_NAME_SMS} value={nameTemplate} type="text" onChange={({ target }) => setNameTemplate(target.value)} minLength="20" maxLength={global.TEMPLATE_NAME_LENGTH} className="form-control item" pattern='Ingrese Nombre' />
            </div>
            <div className="form-group">
              <label className="form-label"><b>{global.TEMPLATE_DESCRIPTION_SMS}</b><span className="text-input name-input"></span> </label>
              <input onKeyUp={({ target }) => removeError(target)} id="descTemplate" placeholder={global.TEMPLATE_DESCRIPTION_SMS} value={descTemplate} type="text" onChange={({ target }) => setDescTemplate(target.value)} minLength="20" maxLength={global.DESCRIPTION_LENGTH} className="form-control item" pattern='Ingrese Nombre' />
            </div>
          </div>
          : <div className='container-fluid'>
            <div className="form-group">
              <label className="form-label"><b>{global.TEMPLATE_ASUN_SMS}</b><span className="text-area"></span> </label><strong className="obl">{global.REQUIRED}</strong>
              <textarea onKeyUp={({ target }) => removeError(target)} id="textTemplate" rows='5' placeholder={global.TEMPLATE_ASUN_SMS} value={text} type="text" onChange={({ target }) => setText(target.value)} minLength="0" maxLength={global.SMS_LENGTH} className="form-control item" />
              <div className='count-text'>
                <span>{`${text.length}/${global.SMS_LENGTH}`}</span>
              </div>
            </div>
          </div>
        }
      </div>
      <div className="container">
        <div className="row">
          <div className="text-center form-inline-div">
            <div className="form-group">
              <button onClick={cancelSmsTemplates} className='btnCancel' >{global.CANCEL}</button> {' '}{' '}{' '}
              <button onClick={saveSmsTemplates} className='btnAcep' >{global.ACCEPT}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditTemplate

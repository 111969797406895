/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useRef, useEffect } from 'react'
import '../../../scss/_templates.scss'
import ModalHtmlTemplate from '../../../components/util/ModalHtmlTemplate'
import ModalConfiguracionTemplates from '../../../components/util/ModalConfiguracionTemplates'
import ModalSpiner from '../../../components/util/ModalSpiner'
import ModalNewMessages from '../../../components/util/ModalGeneral'
import ModalHtmlBasic from '../../../components/util/ModalHtmlRepo'
import templateServices from '../../../services/templates'
import ModalCancelTemplate from '../../../components/util/ModalCancelTemplatereturn'
import { template } from './template'

import $ from 'jquery'
const CreateTemplate = () => {
  const [nameTemplate, setNameTemplate] = useState('')
  const [descTemplate, setDescTemplate] = useState('')
  const [nameRem, setNameRem] = useState('')
  const [descRem, setDescRem] = useState('')
  const [asunto, setAsunto] = useState('')
  const [spiner, setSpiner] = useState(false)
  const [isOpenM, setIsOpenM] = useState(false)
  const [headerM, setHeaderM] = useState('')
  const [handle4, setHandle4] = useState()
  const [bodyM, setBodyM] = useState('')
  const [modalButtonM, setModalButtonM] = useState('')
  const [coloHeaderM, setColorHeaderM] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [coloHeader, setColorHeader] = useState('')
  const [header, setHeader] = useState('')
  const [handle, setHandle] = useState()
  const [modalButton, setModalButton] = useState('')
  const [body, setModalBody] = useState('')
  const emailEditorRef = useRef(null)
  const [selectedFile, setSelectedFile] = useState()
  const [selectedFileAdd, setSelectedFileAdd] = useState()
  const [isOpenHtml, setIsOpenHtml] = useState(false)
  const [headerHtml, setHeaderHtml] = useState('')
  const [handle2, setHandle2] = useState()
  const [bodyHtml, setBodyHtml] = useState('')
  const [modalButtonHtml, setModalButtonHtml] = useState('')
  const [coloHeaderHtml, setColorHeaderHtml] = useState('')
  const [imgData, setImgData] = useState([])
  const [fileData, setFileData] = useState([])
  const [showLoad, setShowLoad] = useState(true)
  const [isOpenC, setIsOpenC] = useState(false)
  const [headerC, setHeaderC] = useState('')
  const [handleC, setHandleC] = useState()
  const [bodyC, setBodyC] = useState('')
  const [modalButtonC, setModalButtonC] = useState('')
  const [coloHeaderC, setColorHeaderC] = useState('')
  const [modalButtonCerrarC, setModalButtonCerrarC] = useState('')
  const [type, setType] = useState('')
  // unlayer.loadDesign(template)

  useEffect(() => {
    setSpiner(true)
    const idPlantillaSms = localStorage.getItem('idPlantilla')
    if (idPlantillaSms) {
      loadEditTemplate()
    }
    getImages()
    $('#panelColapse').click()
    setTimeout(function () {
      setSpiner(false)
    }, 4000)
  }, [])
  const HandleReturn = () => {
    if (window.localStorage.getItem('wizard') === '1') {
      window.location.href = global.ROUTE_WELCOME
      window.localStorage.removeItem('wizard')
    } else {
      window.localStorage.setItem('steps', 2)
      window.location.href = global.ROUTE_CREATECAMPAIGN
    }
  }

  const loadEditTemplate = async () => {
    try {
      const resp = await templateServices.loadTemplatesEdit()
      message(global.LABEL_INPUT_ERROR)
      if (!resp.error) {
        setNameTemplate(resp.data.data.nombre)
        setDescTemplate(resp.data.data.descripcion)
        if (resp.dataDet.data.row.length > 0) {
          setNameRem(resp.dataDet.data.row[0].nombreRemitente)
          setDescRem(resp.dataDet.data.row[0].mailRemitente)
          unlayer.loadDesign(JSON.parse(resp.dataDet.data.row[0].contenidoJson))
        }
      }
    } catch (e) {
    }
  }

  const automaticSave = async () => {
    try {
      setShowLoad(false)
      saveHead()
      saveDesign()
      setShowLoad(true)
    } catch (e) {
    }
  }

  setInterval(automaticSave, 15000000)

  const getImages = async () => {
    const imageData = await templateServices.getImages({})
    setImgData(imageData.data.data.row)
  }

  $(document).ready(function () {
    $('input[type=file]').change(function (e) {
      const fileName = $('#fileUpload').val()
      $('.fileName').html(fileName)
    })
  })

  const uploadImg = async () => {
    const fileName = $('#fileUpload').val()
    if (fileName) {
      setHandle4(handleClose)
      setSpiner(true)
      const userData = await templateServices.saveImages({
        img: selectedFile,
        idPlanitlla: localStorage.getItem('idPlantilla')
      })
    } else {
      global.LABEL_INPUT_ERROR = 'Seleccionar una imagen'
      global.ERROR_LABEL = 'alert'
    }
    setTimeout(function () {
      getImages()
      setSpiner(false)
    }, 2000)
    message()
  }

  const uploadFile = async () => {
    const fileName = $('#fileUploadDoc').val()
    if (fileName) {
      setHandle4(handleClose)
      setSpiner(true)
      console.log(fileName)
      const userData = await templateServices.saveFile({
        file: selectedFileAdd,
        idPlanitlla: localStorage.getItem('idPlantilla')
      })
    } else {
      global.LABEL_INPUT_ERROR = 'Seleccionar una archivo'
      global.ERROR_LABEL = 'alert'
    }
    setTimeout(function () {
      getImages()
      setSpiner(false)
    }, 2000)
    message()
  }
  const handleClose = () => () => {
    setIsOpen(false)
    setIsOpenM(false)
    setIsOpenHtml(false)
    setIsOpenC(false)
  }

  const exportHtml = () => {
    unlayer.exportHtml(function (data) {
      const json = data.design // design json
      const html = data.html // final html
      setIsOpen(true)
      setHeader('Preview')
      setModalBody(<div
        dangerouslySetInnerHTML={{
          __html: html
        }}></div>)
      setColorHeader()
      setModalButton('cerrar')
      setHandle(handleClose)
      // Do something with the json and html
    })
  }

  const exportHtmlCode = () => {
    unlayer.exportHtml(function (data) {
      const json = data.design // design json
      const html = data.html // final html
      setIsOpen(true)
      setHeader('HTML')
      setModalBody(html)
      setColorHeader()
      setModalButton('cerrar')
      setHandle(handleClose)
    })
  }

  const saveHead = () => {
    unlayer.saveDesign(function (design) {
      // Do something with the json and html
      unlayer.exportHtml(function (data) {
        const html = data.html
        saveHeadJson(html, design)
      })
    })
  }

  const saveHeadJson = async (html, design) => {
    if (showLoad) { setSpiner(true) }
    try {
      const userData = await templateServices.saveHeadTemplates({
        nameTemplate,
        descTemplate,
        nameRem,
        descRem,
        html,
        design
      })
      if (showLoad) {
        setSpiner(false)
        if (window.localStorage.getItem('wizard') === '1') {
          window.location.href = global.ROUTE_WELCOME
          window.localStorage.setItem('returnW', 1)
          window.localStorage.removeItem('wizard')
        } else {
          window.localStorage.setItem('steps', 2)
          window.location.href = global.ROUTE_CREATECAMPAIGN
        }
      }
      message()
    } catch (e) {
    }
  }

  const cancelSmsTemplates = () => {
    setIsOpenC(true)
    setColorHeaderC(global.MODAL_HEAD_CANCEL_TEMPLATE)
    setBodyC(global.MODAL_CANCEL_TEMPLATE)
    setModalButtonC('Aceptar')
    setModalButtonCerrarC('Cerrar')
    setHandleC(handleClose)
    setType('H')
  }

  const saveDesign = () => {
    unlayer.saveDesign(function (design) {
      // Do something with the json and html
      unlayer.exportHtml(function (data) {
        const html = data.html
        saveDetail(html, design)
      })
    })
  }

  const saveDetail = async (html, design) => {
    if (showLoad) { setSpiner(true) }
    try {
      const userData = await templateServices.saveDetailTemplates({
        nameRem,
        descRem,
        html,
        design
      })
      if (showLoad) { setSpiner(false) }
      message()
    } catch (e) {
    }
  }

  // código para guardar automaticamente cada vez qye se arrastre un elemento
  unlayer.addEventListener('design:updated', function (updates) {
    // Design is updated by the user

    unlayer.exportHtml(function (data) {
      const json = data.design // design json
      const html = data.html // design html
      // Save the json, or html here
    })
  })

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const changeHandlerFile = (event) => {
    setSelectedFileAdd(event.target.files[0])
  }

  const repoHtml = (event) => {
    setIsOpenHtml(true)
    setHeaderHtml('Seleccionar plantillas HTML')
    setColorHeaderHtml()
    setModalButtonHtml('cerrar')
    setHandle2(handleClose)
  }

  const fileSelectedHandler = (e) => {
    const fileUploadDoc = $('#fileUploadDoc').val()
    setFileData(fileUploadDoc)
    console.log(fileUploadDoc)
  }

  const addFiles = (e) => {
    const idPlantillaSms = localStorage.getItem('idPlantilla')
    if (idPlantillaSms) {
      window.localStorage.setItem('addFiles', global.ROUTE_CREATEMAILTEMPLATES)
      window.location.href = global.ASDD_FILES
    } else {
      global.ERROR_LABEL = 'warning'
      global.LABEL_INPUT_ERROR = 'Para agregar adjuntos primero debe guardar una plantilla'
      mensaje()
    }
  }

  const message = () => {
    $('.' + global.ERROR_LABEL + '-alert').html('')
    $('.' + global.ERROR_LABEL + '-alert').append(global.LABEL_INPUT_ERROR)
    $('.' + global.ERROR_LABEL).removeClass(global.ERROR_LABEL + '-alert-hide')
    $('.' + global.ERROR_LABEL).addClass(global.ERROR_LABEL + '-alert-show')
    setTimeout(function () {
      $('.' + global.ERROR_LABEL + '-alert').html('')
      $('.' + global.ERROR_LABEL).removeClass(global.ERROR_LABEL + '-alert-show')
      $('.' + global.ERROR_LABEL).addClass(global.ERROR_LABEL + '-alert-hide')
    }, 5000)
  }

  return (
    <div className='container-fluid-master'>
      <div id="mediaLibrary" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Mi Librería </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">

                <div className="row col-md-12">
                  <div className="col-md-6">
                    <div className="upload">
                      <input type="button" className="uploadButton" value="Browse" />
                      <input type="file" name="upload" accept="image/*" onChange={changeHandler} id="fileUpload" />
                      <span className="fileName">Seleccionar un archivo</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <a type="button" className="uploadButton" onClick={uploadImg} >Cargar Archivo</a>
                  </div>
                </div>
                <br />
                <div className="col-md-12">
                  <h4>Seleccionar una imagen</h4>
                </div>
                <div className="images col-md-12 row">
                  {imgData.map((val, key) => {
                    return (
                      <React.Fragment key={val.idImagenUsuario}>
                        {
                          <div className="col-md-3">
                            <div className="image">
                              <img className='img-templates' src={val.urlPublica} />
                            </div>
                          </div>
                        }
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalCancelTemplate isOpen={isOpenC} type={type} header={coloHeaderC} body={bodyC} color={coloHeaderC} button={modalButtonC} buttonCerrar={modalButtonCerrarC} handle={handleC} />
      <ModalNewMessages isOpen={isOpenM} header={headerM} body={bodyM} color={coloHeaderM} button={modalButtonM} handle={handle4} />
      <ModalHtmlBasic isOpen={isOpenHtml} header={headerHtml} body={bodyHtml} color={coloHeaderHtml} button={modalButtonHtml} handle={handle2} />
      <ModalSpiner opt={spiner} />
      <ModalHtmlTemplate isOpen={isOpen} header={header} color={coloHeader} button={modalButton} handle={handle} body={body} />
      <div className='col-md-7' type='button' onClick={HandleReturn} id='textReturn'><h4><i className="fa fa-chevron-left" aria-hidden="true"></i>Regresar</h4></div>
      <hr className='hr2' />
      <h3 className='center' id='panelColapse' data-toggle="collapse" data-target="#demo">Creación de plantilla mail <i className='fa fa-arrow-down'></i></h3>
      <hr className='hr2' />
      <div id="demo" className="row collapse col-md-12">
        <div className="d-flex carousel-nav">
          <a href="#" className="col active">Contenido</a>
          <a href="#" className="col">Configuración</a>
        </div>
        <div className="owl-carousel owl-1">
          <div className="media-29101 d-md-flex w-100">
            <div className='container-fluid'>
              <form className='container-fluid row'>
                <div className="form-group col-md-6">
                  <label className="form-label"><b>Nombre de la plantilla</b><span className="text-input name-input"></span> </label>
                  <input placeholder='Nombre de plantilla' value={nameTemplate} type="text" onChange={({ target }) => setNameTemplate(target.value)} minLength="20" maxLength="30" className="form-control item" pattern='Ingrese Nombre' />
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label"><b>Descripción de la plantilla</b><span className="text-input name-input"></span> </label>
                  <input placeholder='Descripción de la plantilla' value={descTemplate} type="text" onChange={({ target }) => setDescTemplate(target.value)} minLength="20" maxLength="30" className="form-control item" pattern='Ingrese Nombre' />
                </div>
              </form>
            </div>
          </div>
          <div className="media-29101 d-md-flex w-100">
            <div className='container-fluid'>
              <form className='container-fluid row'>
                <div className=" col-md-12 row">
                  <div className="col-md-6">
                    <label className="form-label"><b>Nombre del remitente</b><span className="text-input name-input"></span> </label>
                    <input placeholder='Nombre del remitente' value={nameRem} type="text" onChange={({ target }) => setNameRem(target.value)} minLength="20" maxLength="30" className="form-control item" pattern='Ingrese Nombre' />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label"><b>Descripción del remitente</b><span className="text-input name-input"></span> </label>
                    <input placeholder='Descripción del remitente' value={descRem} type="text" onChange={({ target }) => setDescRem(target.value)} minLength="20" maxLength="30" className="form-control item" pattern='Ingrese Nombre' />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='row mb-1 col-md-12'>
          <div className="col-10 align-end-btn">
            <a type="button" onClick={addFiles} className="btn btn-outline-dark"><i className='fa fa-paperclip'></i> Documentos Adjuntos</a>
            {' '} <a type="button" onClick={exportHtmlCode} className="btn btn-outline-dark"><i className='fa fa-code'></i> ver código</a>
            { /* {' '}<a type="button" onClick={exportHtml} className="btn btn-outline-dark"><i className='fa fa-eye'></i> previsualización</a>
            {' '}<a type="button" onClick={repoHtml} className="btn btn-outline-dark"><i className='fa fa-eye'></i> Repo Html</a> */}
          </div>
        </div>
      </div>
      <div className='col-md-12'>
        <div id="editor-container"></div>
      </div>
      <br/>
      <div className="col-md-12 row center">
        <div className="form-group col-md-6">
          <button onClick={cancelSmsTemplates} className='btnAcep' >Cancelar</button>
        </div>
        <div className="form-group col-md-6">
          <button onClick={saveHead} className='btnDelete' >Aceptar</button>
        </div>
      </div>
      <br/><br/>
    </div>
  )
}

export default CreateTemplate

/* eslint-disable no-unused-vars */

import React, { useCallback, useState } from 'react'
import Dropzone from './Dropzone/Dropzone'
import '../../../scss/_dragDrop.scss'

const AddFiles = (props) => {
  return (
    <div className='container-fluid-master'>
      <div className='col-md-7'><a href={localStorage.getItem('addFiles')}><h4><i className="fa fa-chevron-left" aria-hidden="true"></i>Regresar a plantillas</h4></a></div>
      <hr className='hr2' />
      <h3 className='center'>Agregar archivos adjuntos</h3>
      <hr className='hr2' />
      <div className='col-md-12'>
        <Dropzone option={'plantilla'} />
      </div>
    </div>
  )
}

export default AddFiles

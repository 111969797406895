/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-trailing-spaces */


import React from 'react'
import '../../scss/_confetti.scss'
import img from '../../assets/img/gifs-de-calendarios-0.gif'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'


const ButtonCampaign = () => {
  const tooltip = (
    <Tooltip className='redirect-campania'><label style={{ color: 'black', fontSize: '15px', padding: '0px' }} >Programar campaña</label></Tooltip>
  )

  const entering = (e) => {
    e.children[1].style.backgroundColor = 'white'
  }
  const redirectButton = (e) => {
    window.localStorage.removeItem('idPlantilla')
    window.localStorage.removeItem('idDetailPlantilla')
    window.localStorage.removeItem('idPlantillaSms')
    window.localStorage.removeItem('DetallePlantillaCreadaSms')
    window.localStorage.removeItem('idPlantillaWhat')
    window.localStorage.removeItem('DetallePlantillaCreadaWath')
    window.localStorage.removeItem('editTipo')
    window.localStorage.removeItem('newTemplate')
    window.localStorage.setItem(
      'menu', JSON.stringify(5)
    )
    window.location.href = global.ROUTE_CREATECAMPAIGN
  }

  
  return (
    <>
      {
        window.location.pathname !== global.ROUTE_WELCOME &&
        window.location.pathname !== global.ROUTE_CREATECAMPAIGN &&
        <>
          <a onClick={() => redirectButton()} data-for='custom-color-no-arrow' data-tip='Campaña'>
            <OverlayTrigger placement="top" overlay={tooltip} onEntering={entering}>
              <img src={img} id="float-id" className="img-chat" alt="Cinque Terre" width="70" height="105" />
            </OverlayTrigger>
          </a>
        </>
      }
    </>
  )
}

export default ButtonCampaign

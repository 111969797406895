/* eslint-disable no-undef */

import React from 'react'
import $ from 'jquery'
import '../../src/scss/_templates.scss'

const Drag = (props) => {
  $(document).ready(function () {
    unlayer.registerCallback('image', function (file, done) {
      const data = new FormData()
      data.append('file', file.attachments[0])

      fetch('/uploads', {
        method: 'POST',
        headers: {
          Accept: 'application/json'
        },
        body: data
      }).then(response => {
      // Make sure the response was valid
        if (response.status >= 200 && response.status < 300) {
          return response
        } else {
          const error = new Error(response.statusText)
          error.response = response
          throw error
        }
      }).then(response => {
        return response.json()
      }).then(data => {
      // Pass the URL back to Unlayer to mark this upload as completed
        done({ progress: 100, url: data.filelink })
      })
    })
  })
  return (
    <div className='container-fluid'>
    <div id="editor-container"></div>
    </div>
  )
}

export default Drag

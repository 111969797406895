/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import InputEmoji from 'react-input-emoji'
import '../../../scss/_tenmplateSms.scss'
import ModalSpiner from '../../../components/util/ModalSpiner'
import templateServices from '../../../services/templates'
import ModalCancelTemplate from '../../../components/util/ModalCancelTemplatereturn'
import Picker from 'emoji-picker-react'
import $ from 'jquery'

const CreateTemplate = () => {
  const [nameTemplate, setNameTemplate] = useState('')
  const [text, setText] = useState('')
  const [descTemplate, setDescTemplate] = useState('')
  const [textSave, setTextSave] = useState('')
  const [spiner, setSpiner] = useState(false)
  const [isOpenC, setIsOpenC] = useState(false)
  const [headerC, setHeaderC] = useState('')
  const [handleC, setHandleC] = useState()
  const [bodyC, setBodyC] = useState('')
  const [modalButtonC, setModalButtonC] = useState('')
  const [coloHeaderC, setColorHeaderC] = useState('')
  const [modalButtonCerrarC, setModalButtonCerrarC] = useState('')
  const [type, setType] = useState('')

  useEffect(() => {
    const idPlantillaWath = localStorage.getItem('idPlantillaWhat')
    if (idPlantillaWath) {
      loadEditTemplate()
    }
  }, [])

  const loadEditTemplate = async () => {
    try {
      setSpiner(true)
      const resp = await templateServices.loadTemplatesEdit()
      setSpiner(false)
      message(global.LABEL_INPUT_ERROR)
      if (!resp.error) {
        setNameTemplate(resp.data.data.nombre)
        setText(resp.dataDet.data.row[0].contenido)
        setDescTemplate(resp.data.data.descripcion)
      }
    } catch (e) {
    }
  }

  const automaticSave = async () => {
    try {
      saveSmsTemplates()
      saveDetailsSmsTemplates()
    } catch (e) {
    }
  }

  setInterval(automaticSave, 15000000)

  const saveSmsTemplates = () => {
    save()
  }

  const saveDetailsSmsTemplates = () => {
    saveDetail()
  }

  const [chosenEmoji, setChosenEmoji] = useState(null)

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject)
    const sym = emojiObject.unified.split('-')
    const codesArray = []
    sym.forEach(el => codesArray.push('0x' + el))
    const emoji = String.fromCodePoint(...codesArray)
    console.log(emoji)
    const data = text + ' ' + chosenEmoji.emoji
    const dataSave = text + ' ' + emoji
    setText(data)
    setTextSave(dataSave)
  }

  const save = async () => {
    try {
      setSpiner(true)
      const serv = await templateServices.saveHeadTemplatesWhat({
        nameTemplate,
        text,
        descTemplate
      })
      setSpiner(false)
      message(global.LABEL_INPUT_ERROR)
      if (serv.error === 0) {
        if (window.localStorage.getItem('wizard') === '1') {
          window.location.href = global.ROUTE_WELCOME
          window.localStorage.setItem('returnW', 1)
          window.localStorage.removeItem('wizard')
        } else {
          window.localStorage.setItem('steps', 2)
          window.location.href = global.ROUTE_CREATECAMPAIGN
        }
      }
    } catch (e) {
    }
  }

  const saveDetail = async () => {
    try {
      setSpiner(true)
      await templateServices.saveDetailTemplatesWhat({
        // for emoji textSave
        text
      })
      setSpiner(false)
      message(global.LABEL_INPUT_ERROR)
    } catch (e) {
    }
  }

  const handleClose = () => () => {
    setIsOpenC(false)
  }

  const cancelSmsTemplates = () => {
    setIsOpenC(true)
    setColorHeaderC(global.MODAL_HEAD_CANCEL_TEMPLATE)
    setBodyC(global.MODAL_CANCEL_TEMPLATE)
    setModalButtonC('Aceptar')
    setModalButtonCerrarC('Cerrar')
    setHandleC(handleClose)
    setType('E')
  }
  const HandleReturn = () => {
    if (window.localStorage.getItem('wizard') === '1') {
      window.location.href = global.ROUTE_WELCOME
      window.localStorage.removeItem('wizard')
    } else {
      window.localStorage.setItem('steps', 2)
      window.location.href = global.ROUTE_CREATECAMPAIGN
    }
  }

  const message = (message) => {
    $('.' + global.ERROR_LABEL + '-alert').html('')
    $('.' + global.ERROR_LABEL + '-alert').append(message)
    $('.' + global.ERROR_LABEL).removeClass(global.ERROR_LABEL + '-alert-hide')
    $('.' + global.ERROR_LABEL).addClass(global.ERROR_LABEL + '-alert-show')
    setTimeout(function () {
      $('.' + global.ERROR_LABEL + '-alert').html('')
      $('.' + global.ERROR_LABEL).removeClass(global.ERROR_LABEL + '-alert-show')
      $('.' + global.ERROR_LABEL).addClass(global.ERROR_LABEL + '-alert-hide')
    }, 5000)
  }
  return (
    <div className='container-fluid-master'>
      <ModalSpiner opt={spiner} />
      <ModalCancelTemplate isOpen={isOpenC} type={type} header={coloHeaderC} body={bodyC} color={coloHeaderC} button={modalButtonC} buttonCerrar={modalButtonCerrarC} handle={handleC} />

      <div className='col-md-7' type='button' onClick={HandleReturn} id='textReturn'><h4><i className="fa fa-chevron-left" aria-hidden="true"></i>Regresar</h4></div>
      <hr className='hr2' />
      <h3 className='center'><b>Creación de plantilla whatsapp</b></h3>
      <hr className='hr2' />
      <div className="d-flex carousel-nav">
        <a href="#" className="col active">Contenido</a>
        <a href="#" className="col">Configuración</a>
      </div>

      <div className="owl-carousel owl-1">

        <div className="media-29101 d-md-flex w-100">
          <div className='container-fluid'>
            <div className="form-group">
              <label className="form-label"><b>Nombres de la plantilla</b><span className="text-input name-input"></span> </label>
              <input placeholder='Escriba nombre de plantilla' value={nameTemplate} type="text" onChange={({ target }) => setNameTemplate(target.value)} minLength="20" maxLength="400" className="form-control item" pattern='Ingrese Nombre' />
            </div>
            <div className="form-group">
              <label className="form-label"><b>Descripción de la plantilla</b><span className="text-input name-input"></span> </label>
              <input placeholder='Escriba la descripción' value={descTemplate} type="text" onChange={({ target }) => setDescTemplate(target.value)} minLength="20" maxLength="400" className="form-control item" pattern='Ingrese descripción' />
            </div>
            <div className="row col-md-12">
              <div className="col-md-12">

              </div>
              {/* <div className="col-md-6">
                <div className="form-group">
                  <div>
                    <span>Seleccionar Emoji:</span>
                    <Picker onEmojiClick={onEmojiClick} pickerStyle={{ width: '100%', heigth: '10%' }} />
                  </div>
                </div>
  </div> */}
            </div>
          </div>
        </div>

        <div className="media-29101 d-md-flex w-100">
          <div className='container-fluid'>
            <div className="form-group">
              <label className="form-label"><b>Mensaje</b><span className="text-area"></span> </label>
              <textarea rows='10' placeholder='Escriba un texto máximo de 200 caracteres' value={text} type="text" onChange={({ target }) => setText(target.value)} minLength="0" maxLength="40000" className="form-control item" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 row center">
        <div className="form-group col-md-6">
          <button onClick={cancelSmsTemplates} className='btnAcep' >Cancelar</button>
        </div>
        <div className="form-group col-md-6">
          <button onClick={saveSmsTemplates} className='btnDelete' >Aceptar</button>
        </div>
      </div>
      <br />
    </div>
  )
}

export default CreateTemplate

/* eslint-disable operator-linebreak */
/* eslint-disable semi */
/* eslint-disable prefer-const */
/* eslint-disable space-before-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable no-trailing-spaces */
/* eslint-disable keyword-spacing */
/* eslint-disable react/prop-types */
/* eslint-disable indent */
import React, { useState } from 'react'
import ModalConfirmacion from '../../../components/util/ModalConfirmacion'
import gruposServices from '../../../services/contact'
import ModalSpiner from '../../../components/util/ModalSpiner'
import ModalEditarGrupo from '../../../components/util/ModalEditarGrupo'
import TableContactos from './TableContactos';
import ReactTooltip from 'react-tooltip';

const Segmentos = ({ permisosSegmentos, handleModalAgg, helpServices, dataLista, orden, clase, getDataSegmentos, handleSegment, mostrarMensaje, refreshDestinatarios, refreshDataGrupos }) => {
  const [isOpenConf, setIsOpenConf] = useState(false)
  const [spiner, setSpiner] = useState(false)
  const [header, setHeader] = useState('')
  const [labelF, setLabelF] = useState('')
  const [labelS, setLabelS] = useState('')
  const [isAccionConf, setAccionConf] = useState('')
  const [isOpenEditGrupo, setEditGrupo] = useState(false)
  const [bodyConf, setBodyConf] = useState('')
  const [idGrupoSelect, setIdGrupoSelect] = useState('')
  const [idDestinatarioSelect, setIdDestinatarioSelect] = useState('')
  const [grupoEdit, setGrupoEdit] = useState({})
  const [idGrupoDelete, setIdGrupoDelete] = useState('')
  const [permisosSegmentoss, setPermisosSegmentoss] = useState(permisosSegmentos)
     
  const handleDeleteGrupo = async () => {
    let info;
      setSpiner(true)
      // let idGrupo = 58;
      info = await gruposServices.DeleteGrupo(idGrupoSelect)

      let objMessage = helpServices.getMessage(info.data)
      console.log('objMessage', objMessage)
      if(objMessage.code === 200){
        setIsOpenConf(false)
        refreshDataGrupos()
        mostrarMensaje(objMessage.code, objMessage.message)
      }else{
        setIsOpenConf(false)
        mostrarMensaje(objMessage.code, objMessage.message)
      } 

      setSpiner(false)
  }
  const handleDeleteDestinatario = async () => {
    let info;
      setSpiner(true)
      // let idDestinatario = '33c03b84-e2d1-4b72-bfa3-129143de6f45';
      info = await gruposServices.DeleteDestinatario({ idGrupo: idGrupoDelete, idDestinatario: idDestinatarioSelect })

      let objMessage = helpServices.getMessage(info.data)
      console.log('objMessage', objMessage)
      if(objMessage.code === 200){
        setIsOpenConf(false)
        // getDataSegmentos()
        refreshDestinatarios(idGrupoDelete)
        mostrarMensaje(objMessage.code, objMessage.message)
      }else{
        setIsOpenConf(false)
        mostrarMensaje(objMessage.code, objMessage.message)
      } 
      setSpiner(false)
  }
  const handleConvLista = async () => {
    let info;
      setSpiner(true)
      // let idGrupo = 58;
      info = await gruposServices.ConvierteSegmentoLista(idGrupoSelect)

      let objMessage = helpServices.getMessage(info.data)
      console.log('objMessage', objMessage)
      if(objMessage.code === 200){
        setIsOpenConf(false)
        handleSegment(1)
        mostrarMensaje(objMessage.code, objMessage.message)
      }else{
        setIsOpenConf(false)
        mostrarMensaje(objMessage.code, objMessage.message)
      }
      setSpiner(false)
  }
  const handleCloseConf = (op) => {
    if(op){
      isAccionConf === 'DG' ? handleDeleteGrupo() : isAccionConf === 'DD' ? handleDeleteDestinatario() : handleConvLista() 
    }else{
      setIsOpenConf(false)
    }
  }
  const deleteGrupo = (idGrupo) => {
    setAccionConf('DG')
    setIdGrupoSelect(idGrupo)
    setHeader('Eliminar segmento')
    setBodyConf(global.MODAL_ELIM_SGEMENTO)
    setIsOpenConf(true)
  }
  const convierteLista = (idGrupo) => {
    setAccionConf('CL')
    setIdGrupoSelect(idGrupo)
    setHeader('Convertir segmento a lista')
    setBodyConf(global.MODAL_CONV_SEGMENTO)
    setIsOpenConf(true)
  }
  const deleteDestinatario = (idGrupo, idDestinatario) => {
    setAccionConf('DD')
    setIdDestinatarioSelect(idDestinatario)
    setHeader('Eliminar contacto')
    setBodyConf(global.MODAL_ELIM_DESTINATARIO)
    setIsOpenConf(true)
    setIdGrupoDelete(idGrupo)
  }
  const editaGrupo = async (idGrupo) => {
    // setIdGrupoSelect(idGrupo)
    const getGrupoid = await gruposServices.getGrupoId(idGrupo)
    console.log('getGrupoid', getGrupoid);

    let objMessage = helpServices.getMessage(getGrupoid.data)
    console.log('objMessage', objMessage)
    if(objMessage.code === 200){
      console.log(getGrupoid.data.data.row); 
      setGrupoEdit(getGrupoid.data.data.row)
      setHeader('Editar segmento')
      setLabelF('Nombre del segmento')
      setLabelS('Objetivo del segmento')
      setEditGrupo(true)
    }else{
      mostrarMensaje(objMessage.code, objMessage.message)
    }
  }
  const handleCloseEditGrupo = (op) => {
    console.log('opcion', op);
    if(op) {
      setEditGrupo(false)
      refreshDataGrupos()
      console.log('recargar Segmentos');
      mostrarMensaje(200, global.PROCESO_OK)
    }else{
      setEditGrupo(false)
    }
  }
     
  return (
    <div className='justify-content-betweend mt-3 color-table pb-4'>
           <ModalSpiner opt={spiner} />
           {isOpenEditGrupo && <ModalEditarGrupo mostrarMensaje={mostrarMensaje} tipoGrupo={'S'} label1={labelF} label2={labelS} grupoEdit={grupoEdit} isOpen={isOpenEditGrupo} handleCloseEditGrupo={handleCloseEditGrupo} header={header}/>}
           {isOpenConf && <ModalConfirmacion isOpen={isOpenConf} body={bodyConf} handleModalConf={handleCloseConf} header={header}/>}

              <div className={`col-12 row color-reglon ${clase}`}>
                <div className={`col-6 color-rigth ${clase}`}>
                <h5>
                    <b>
                    {dataLista.nombre.length > 25 ?
                        <>
                          {/* <div className='ajust-text' data-tip={`<p class="content-tool"> ${destinatario.correo.toLowerCase()}</p>`} data-html={true}> */}
                          <div className='ajust-text name-grupo' data-tip={dataLista.nombre} data-html={true}>
                              {dataLista.nombre}
                              <ReactTooltip place="bottom" type="dark" effect="solid"/>
                          </div>

                          {/* <div data-tip={`<p></p>`}>
                          
                              {/* <ReactTooltip data-html={true} width={'180px'} className='custom-tooltip' multiline={ true } place="bottom" type="dark" effect="solid"/> }
                              
                          </div> */}
                        </>
                      :
                      <>
                      {dataLista.nombre}
                      </>
                    }
                    </b>
                  </h5>                  </div>
                <div className={`col-2 color-rigth ${clase} pl-2 d-flex`}>
                  <strong>Contactos:</strong>
                  <span id={`cantGrupo_${dataLista.idGrupo}`}>{dataLista.cantidadDestinatarios}</span>
                </div>
                <div className={`col-3 color-rigth ${clase}`}><strong>Fecha creación:</strong> {dataLista.fechaCreacion.split(' ')[0]}</div>
                {permisosSegmentoss && (
                  <div className="dropdown col-1">
                    <a type="text" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fa fa-ellipsis-v fa-color"></i>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <div onClick={() => { editaGrupo(dataLista.idGrupo) }}>
                          <i style={{ fontSize: '13px' }} className="ml-2 fas fa-pen notify-color"></i>
                          <span className='ml-2 detail-drop-down'>Editar</span>
                        </div>
                        <div onClick={() => { convierteLista(dataLista.idGrupo) }}>
                          <i className="ml-2 far fa-list-alt notify-color"></i>
                          <span className='ml-2 detail-drop-down'>Convert. lista principal</span>
                        </div>
                        {/* <div><i className="ml-2 fa fa-ellipsis-h notify-color"></i><i className='ml-2 font-syle'>Exportar Segmento</i></div> */}
                        <div onClick={() => { deleteGrupo(dataLista.idGrupo) }}>
                          <i className='ml-2 far fa-trash-alt notify-color'></i>
                          <span className='ml-2 detail-drop-down'>Eliminar</span>
                        </div>
                        <i></i>
                    </div>
                  </div>

                )}
                
              </div>
              
              <TableContactos permisosSegmentos={permisosSegmentoss} mostrarMensaje={mostrarMensaje} tipoGrupo={dataLista.canal} setSpiner={setSpiner} key={`tableGrupo_${dataLista.idGrupo}`} totalDestinatarios={dataLista.totalDestinatarios} listaDestinatarios={dataLista.listaDestinatarios} idGrupoTable={dataLista.idGrupo} handleModalAgg={handleModalAgg} deleteDestinatario={deleteDestinatario} />
              
            </div>
  )
}

export default Segmentos

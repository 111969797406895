/* eslint-disable semi */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react'
import '../../scss/_welcome.scss'
import ModalSpiner from '../util/ModalSpiner'
import ModalMessages from '../util/ModalMessages'
import Footer from '../login/Footer'
import logoBicolor from '../../assets/img/logoBicolor1.png'
import CreateCampaign from './CreateCampaign'
import ContactLoad from './ContactLoad'
import CreateTemplate from './CreateTemplate'
import WelcomeSuccess from './WelcomeSuccess'
import InactivityComponent from '../Helpers/InactivityComponent'

export default function Welcome () {
  const [spiner, setSpiner] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [header, setHeader] = useState('')
  const [body, setBody] = useState('')
  const [barNext, setbarNext] = useState('sheet')
  const [circleNext, setcircleNext] = useState('sheets')
  const [sheetss, setSheetss] = useState('sheetss')
  useEffect(() => {
    validarPermisos()
    setSpiner(false)
    setIsOpen(false)
    setHeader('prueba header')
    setBody('prueba body')
    setbarNext('sheet')
    setcircleNext('sheets')
    setSheetss('sheetss')
  }, [])


  // Funcion para restringir los permisos 
  const [permisosPagina, setPermisosPagina] = useState([]);
  const [isValidCrearWhassap, setIsValidCrearWhassap] = useState(false);
  const [isValidCrearSms, setIsValidCrearSms] = useState(false);
  const [isValidCrearMail, setIsValidCrearMail] = useState(false);
  const [isValidCrearCampania, setIsValidCrearCampania] = useState(false);
  const [isValidCrearContact, setIsValidCrearContact] = useState(false);

  const validarPermisos = async () => {
    try {
      const response = JSON.parse(localStorage.getItem('permisos'));
      // const response = await loginServices.ValidarToken();
      console.log('response', response);

      const tienePermisoCrearWhassapp = response.some(
        (permiso) => permiso.nombre === 'CREATE_TEMPLATE_WHATSAPP'
      );
      setIsValidCrearWhassap(tienePermisoCrearWhassapp);
      const tienePermisoCrearSms = response.some(
        (permiso) => permiso.nombre === 'CREATE_TEMPLATE_SMS'
      );
      setIsValidCrearSms(tienePermisoCrearSms);
      const tienePermisoCrearMail = response.some(
        (permiso) => permiso.nombre === 'CREATE_TEMPLATE_MAIL'
      );
      setIsValidCrearMail(tienePermisoCrearMail);
      const tienePermisoCreaCampania = response.some(
        (permiso) => permiso.nombre === 'CREATE_CAMPAINS'
      );
      setIsValidCrearCampania(tienePermisoCreaCampania);
      const tienePermisoCrearContact = response.some(
        (permiso) => permiso.nombre === 'CREATE_LISTS'
      );
      setIsValidCrearContact(tienePermisoCrearContact);
      
      console.log(tienePermisoCrearMail, tienePermisoCrearSms, tienePermisoCrearWhassapp, tienePermisoCreaCampania, tienePermisoCrearContact);
      if (!tienePermisoCrearMail && !tienePermisoCrearSms && !tienePermisoCrearWhassapp && !tienePermisoCreaCampania && !tienePermisoCrearContact) {
        // localStorage.setItem('tienePermiso', 'false')
       window.location.href = global.ROUTE_DASHBOARD; 
      } else {
        localStorage.setItem('tienePermiso', 'true')
      }
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <div className="background">
        <InactivityComponent />
        <ModalMessages isOpen={isOpen} header={header} body={body} />
        <ModalSpiner opt={spiner} />
        <div className="container-welcome">
            <div className="logo"><img src={logoBicolor} alt="" /></div>
            <a className="write button-goit" href={global.ROUTE_DASHBOARD}>Saltar proceso</a>
            <div className={barNext} id="barNext">
                <div className={circleNext} id="circleNext"></div>
                <div className="idshe" id={sheetss}></div>
            </div>
            <div className="bienvenida">
                <form>
                    <div className='flex flex-row progreso'>
                        <div className='flex-1 w-50 text-left text-sm m-6 mt-14'>Progreso..</div>
                        <div className='text-right percent' id='percent'>0%</div>
                    </div>
                    <div className="bienvenida" id="form-welcome">
                        <div className='text-center'>
                            <h3 className=''>Bienvenidos a Notifyme</h3>
                            <div className='mt-[10px]'>Puedes realizar tu configuración</div>
                            <div className='mb-3'>mediante el asistente o saltar este paso cuando quieras</div>
                        </div>
                        <div className="row step-panel">

                            {isValidCrearCampania ?
                            <CreateCampaign />
                            : null}
                            {isValidCrearMail || isValidCrearSms || isValidCrearWhassap ?
                            <CreateTemplate />
                            : null}
                            {isValidCrearContact ?
                            <ContactLoad />
                            : null}
                        </div>
                    </div>
                </form>
            </div>
            <div className="success" id="form-success">
                <WelcomeSuccess />
            </div>
            <div className="logo-mob"><img src={logoBicolor} alt="" /></div>
        </div>
        <span className="return_welcome" ><a href={global.ROUTE_WELCOME}><i className='fas fa-arrow-alt-circle-left fas_welcome'></i></a></span>
        <Footer />
    </div>
  )
}

/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import React, { useState } from 'react'
import ModalSpiner from '../util/ModalSpiner'
import Toast from '../../components/util/Toast'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from 'react-bootstrap/Button'
import ReactTooltip from 'react-tooltip'

export default function ModalReporteHistorico (props) {
  console.log(props.eventos.length)
  return (
    <>
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={props.isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={props.color} id="alert-dialog-title">
        <b>{props.header}</b>
        <div className="close-x" onClick={props.handle}>
          x
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <hr />
          <div className='ml-2 mb-2 mt-2'><b>Ticket: </b>{props.idTicket}</div>
          <div className='row justify-content-evenly'>
            <div className='form-group col-md-6'>
              <label className='grey-color col-3 mb-0'>Para:</label>
              <input className="imput qNegrita " disabled value={props.para}></input>
            </div>
            <div className='form-group col-md-6'>
              <label className='grey-color col-3 mb-0'>Asunto:</label>
              <input className="imput qNegrita " disabled value={props.asunto}></input>
            </div>
            <div className='form-group col-md-6'>
              <label className='grey-color col-5 mb-0'>Tipo Envio:</label>
              <input className="imput qNegrita " disabled value={props.tipoMail}></input>
            </div>
            <div className='form-group col-md-6'>
              <label className='grey-color col-3 mb-0'>Estado:</label>
              <input className="imput qNegrita " disabled value={props.estado}></input>
            </div>
            <div className='form-group col-md-6'>
              <label className='grey-color mb-0'>Fecha de Programación:</label>
              <input className="imput qNegrita " disabled value={props.fechaProgramacion}></input>
            </div>
            <div className='form-group col-md-6'>
              <label className='grey-color mb-0'>Fecha de Envio:</label>
              <input className="imput qNegrita " disabled value={props.fechaEnvio}></input>
            </div>
            <div className='form-group col-md-6'>
              <label className='grey-color mb-0'>Con copia:</label>
              <input className="imput qNegrita " disabled value={props.conCopia}></input>
            </div>
            <div className='form-group col-md-6'>
              <label className='grey-color mb-0'>Con copia oculta:</label>
              <input className="imput qNegrita " disabled value={props.conCopiaOculta}></input>
            </div>
            <div className='form-group col-md-6'>
              <label className='grey-color mb-0'>Detalle Error:</label>
              <textarea className="imput qNegrita " disabled value={props.mensajeError}></textarea>
            </div>
          </div>
          <div className='p-0'>
            <span className='campaing-tittle'><b>Eventos</b></span>
          </div>
          <div className='mb-12 panel-group data-report listar-report'>
            <table id='prog-table-report' className='table'>
              <thead>
                  <tr>
                    <th>Fecha del Evento</th>
                    <th>Tipo de Evento</th>
                    <th>Detalle del Evento</th>
                    <th>Cantidad de Eventos</th>
                  </tr>
              </thead>
              <tbody id='tbody'>
                  {props.eventos.length > 0
                    ? props.eventos.map((val, index) => {
                      return (<React.Fragment key={val.index}>
                      <tr>
                        <td>{val.fechaEvento}</td>
                        <td>{val.tipo}</td>
                        <td>{val.detalle}</td>
                        <td>1</td>
                      </tr>
                    </React.Fragment>)
                    }
                    )
                    : <tr>
                    <td colSpan={4}>
                      <span>No hay información</span>
                    </td>
                  </tr>
                }
                </tbody>
            </table>
        </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  </>
  )
}

/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import '../../scss/_campaaign.scss'
import date from '../../services/global'
import $ from 'jquery'
import Toast from '../../components/util/Toast'
import serviceReport from '../../services/report'
import ReactTooltip from 'react-tooltip'
import ModalSpiner from '../../components/util/ModalSpiner'
import helpServices from '../../services/helper'
const ProgramacionReport = () => {
  const [fechaInicio, setFechaInicio] = useState('')
  const [fechaFin, setFechaFin] = useState('')
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  const [spiner, setSpiner] = useState(false)
  const [dataReport, setDataReport] = useState([
  ])
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const d = date.getDate()

  const handleGenerar = () => {
    if ((fechaInicio === '' && fechaFin === '') || ('' + fechaInicio === 'null' && '' + fechaFin === 'null')) {
      global.LABEL_INPUT_ERROR = global.CAMPAIGN_FECHA
      $('#fechaInicio').addClass('error')
      $('#fechaFin').addClass('error')
      mostrarMensaje('400', global.LABEL_INPUT_ERROR)
    } else if (fechaInicio === '' || fechaInicio === 'null') {
      global.LABEL_INPUT_ERROR = global.CAMPAIGN_FECHA + ' inicio'
      $('#fechaInicio').addClass('error')
      mostrarMensaje('400', global.LABEL_INPUT_ERROR)
    } else if (fechaFin === '' || fechaFin === 'null') {
      $('#fechaFin').addClass('error')
      global.LABEL_INPUT_ERROR = global.CAMPAIGN_FECHA + ' finalización'
      mostrarMensaje('400', global.LABEL_INPUT_ERROR)
    } else if (fechaInicio > fechaFin) {
      $('#fechaInicio').addClass('error')
      mostrarMensaje('400', 'Su rango de fecha es inválido')
    } else {
      $('#fechaInicio').removeClass('error')
      $('#fechaFin').removeClass('error')
      setSpiner(true)
      getDataReport()
    }
  }
  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000)
  }
  const getDataReport = async () => {
    console.log('holis')
    setSpiner(true)
    $('#prog-table-report').dataTable().fnDestroy()
    const serviceRe = await serviceReport.getReportGeneral({ fechaInicio, fechaFin })
    console.log('serviceRe', serviceRe)
    let objMessage = helpServices.getMessage(serviceRe.data)
    console.log('objMessage', objMessage)
    setSpiner(false)
    if (serviceRe.data.code === 200) {
      setDataReport(serviceRe.data.data)
      setTotal(serviceRe.data.data.length)
      $('#prog-table-report').find('tr').each(function () {
        $(this).css('text-align', 'center')
      })
      $('#prog-table-report').DataTable({
        initComplete: function (settings, json) {
          $('body').find('.dataTables_scrollBody').addClass('scrollbar')
        },
        scrollY: '250px',
        scrollCollapse: true,
        order: [[2, 'desc']],
        lengthMenu: [5, 10, 20],
        language: {
          decimal: '',
          emptyTable: 'No hay información',
          info: 'Mostrando _START_ a _END_ de un total de _TOTAL_ Registros',
          infoEmpty: 'Mostrando 0 to 0 of 0 Registros',
          infoFiltered: '(Filtrado de _MAX_ total Registros)',
          infoPostFix: '',
          thousands: ',',
          lengthMenu: 'Mostrar _MENU_ Registros',
          loadingRecords: 'Cargando...',
          processing: 'Procesando...',
          search: 'Buscar:',
          zeroRecords: 'Sin resultados encontrados',
          paginate: {
            first: 'Primero',
            last: 'Ultimo',
            next: '>',
            previous: '<'
          }
        }
      })
    } else {
      mostrarMensaje(objMessage.code, objMessage.message)
    }
  }

  const dowlandReport = async (idCampanaReporte) => {
    console.log('idCampana', idCampanaReporte)
    setSpiner(true)
    const localData = JSON.parse(window.localStorage.getItem('loggedUser'))
    const userName = helpServices.toTitleCase(localData.nombres.split(' ')[0]) + ' ' + helpServices.toTitleCase(localData.apellidos.split(' ')[0])
    console.log('userName', userName)
    let params = {
      idCampanaReporte,
      userName
    }
    const serviceRe = await serviceReport.getReporteDownloadPers(params)
    console.log('serviceRe', serviceRe)
    let objMessage = helpServices.getMessage(serviceRe)
    console.log('objMessage', objMessage)
    setSpiner(false)
    if (serviceRe.code === 200) {
      const url = window.URL.createObjectURL(new Blob([serviceRe.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'reporte-personalizado.pdf')
      document.body.appendChild(link)
      link.click()
    } else {
      mostrarMensaje(objMessage.code, objMessage.message)
    }
  }

  return (
        <div className='container-fluid p-0'>
          <ModalSpiner opt={spiner} />
          {isOpenToast && <Toast message={messageToast} codigo={codigoToast} />}

            <div className="mb-12 panel-group pt-3 pb-3 header-report mb-4">
                <div className='d-flex'>
                    <div className='col-5 p-0'>
                        <span className='campaing-tittle'><b>Reporte por Programación</b></span>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='form-group col-md-3'>
                    <span className="text-input name-input every-label" id='error_fecha'></span>
                    <label className='grey-color'>Fecha de inicio</label>
                    <input id='fechaInicio'
                          value={fechaInicio}
                          type="date"
                          max={d}
                          onChange={({ target }) => setFechaInicio(target.value)}
                          className="form-control item"></input>
                </div>
                <div className='form-group col-md-3'>
                    <span className="text-input name-input every-label" id='error_fecha'></span>
                    <label className='grey-color'>Fecha de finalización</label>
                    <input id='fechaFin'
                          value={fechaFin}
                          type="date"
                          max={d}
                          onChange={({ target }) => setFechaFin(target.value)}
                          className="form-control item"></input>
                </div>
                <div className='form-group col-md-6 button-generate'>
                  <a type='button' onClick={() => { handleGenerar() }} className="btn btn-success btn-campania contacts report-btn">
                    <b>
                     Generar
                    </b>
                  </a>
                </div>

            </div>
            <div className="mb-12 panel-group pt-4 pb-3 header-report mb-4">
                <div className='d-flex align-items-center'>
                    <div className='col-5 p-0'>
                        <span className='campaing-tittle'><b>Historial de Reportes</b></span>
                    </div>
                    <div className='col-7 show-cant'>
                        <i className='fa fa-chart-pie'></i> <span>Actualmente tienes { total } reportes </span>
                    </div>
                </div>
            </div>
            <div className="mb-12 panel-group data-report">
            <table id='prog-table-report' className="table">
            <thead>
              <tr>
                <th>Nombre de la campaña</th>
                <th>Canal</th>
                <th>Nombre de plantilla</th>
                <th>Nombre de grupo</th>
                <th>Fecha de creación</th>
                <th>Fecha de programación</th>
                <th>Estado</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody id='tbody'>
            {dataReport.map((val) => {
              return (<React.Fragment key={val.id}>
                                  <tr>
                                    <td className='dataTables_empty'>
                                      {val.nombreCampana.length > 25
                                        ? <div className='ajust-text' data-tip={`<p class="content-tool"> ${val.nombreCampana.toLowerCase()}</p>`} data-html={true}>
                                        {val.nombreCampana.toLowerCase()}
                                        <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                        </div>

                                        : <>
                                        <span>{val.nombreCampana.toLowerCase()}</span>
                                        </>
                                      }
                                    </td>
                                    <td>{val.tipoPlantilla === 'T' ? 'SMS' : val.tipoPlantilla === 'H' ? 'Mail' : val.tipoPlantilla === 'E' ? 'WhatsApp' : ''}</td>
                                    <td className='dataTables_empty'>
                                      {val.nombrePlantilla.length > 25
                                        ? <div className='ajust-text' data-tip={`<p class="content-tool"> ${val.nombrePlantilla.toLowerCase()}</p>`} data-html={true}>
                                        {val.nombrePlantilla.toLowerCase()}
                                        <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                        </div>

                                        : <>
                                        <span>{val.nombrePlantilla.toLowerCase()}</span>
                                        </>
                                      }
                                    </td>
                                    <td className='dataTables_empty'>
                                      {val.nombreGrupo.length > 25
                                        ? <div className='ajust-text' data-tip={`<p class="content-tool"> ${val.nombreGrupo.toLowerCase()}</p>`} data-html={true}>
                                        {val.nombreGrupo.toLowerCase()}
                                        <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                        </div>

                                        : <>
                                        <span>{val.nombreGrupo.toLowerCase()}</span>
                                        </>
                                      }
                                    </td>

                                    <td>{val.fechaCreacion}</td>
                                    <td >{val.fechaProgramacion} </td>
                                    <td >{val.estado === 'D' ? 'Despachado' : val.estado} </td>
                                    <td >
                                    <div className="dropdown">
                                      <a type="text" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <i className="fa fa-ellipsis-v fa-color"></i>
                                      </a>
                                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <div onClick={() => { dowlandReport(val.idCampanaReporte) }} >
                                            <i className='ml-2 fa fa-download notify-color'></i>
                                            <span className='ml-3 detail-drop-down'>Descargar</span>
                                          </div>
                                      </div>
                                    </div>
                                       </td>
                                  </tr>
                                  </React.Fragment>
              )
            }
            )}
            </tbody>
            </table>
            </div>
        </div>
  )
}

export default ProgramacionReport

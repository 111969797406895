/* eslint-disable eol-last */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable semi */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-trailing-spaces */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */

import base64 from 'react-native-base64'
import validationLogin from '../validation/login'
import validationCreateUser from '../validation/createUser'
import validationPassword from '../validation/password'

const login = async credenciales => {
  const resps = validationLogin.validate(credenciales)
  const token = 'Basic ' + base64.encode(credenciales.username + ':' + credenciales.password)
  const headers = { 'Content-Type': 'application/json' }
  headers.Authorization = token
  headers.Canal = global.CANALSECURITY
  const requestOptions = {
    method: 'POST',
    headers
  }
  if (resps === true) {
    const resp = await fetch(global.LOGIN_METHOD, requestOptions)
    const items = await resp.json()
    if (items.code === 200) {
      window.localStorage.setItem('token', items.data.token);
              localStorage.setItem('idUsuario', items.data.idUsuario);
      console.log(items.data.token, items.data.idUsuario);
      const valor = await ValidarToken(items.data.token, items.data.idUsuario);
      console.log(valor);
      if (valor.code === 200) {
        if (valor.data.listPermisos.length > 0) {
          window.localStorage.setItem(
            'loggedUser', JSON.stringify(valor.data)
          )
          const usuario = valor.data.apellidos
          window.localStorage.setItem(
            'name', usuario
          )
          window.localStorage.setItem(
            'empresa', valor.data.nombreEmpresa
          )
          /* window.localStorage.setItem(
            'rol', valor.data.canal.nombre
          ) */
      
          global.HEADER = global.MODAL_HEAD_SUCCESS
          global.BODY = global.MODAL_BODY_SUCCES + usuario
          global.ERROR = 0
          if (global.HAVECAMPAIGN === 0) {
            // ValidarToken();
            global.REDIRECT_URL = global.ROUTE_WELCOME
          } else {
            global.REDIRECT_URL = global.ROUTE_DASHBOARD
          }
        } else {
          global.HEADER = global.MODAL_HEAD_ERROR
          global.BODY = "El usuario no tiene permisos, por favor comunicarse con su administrador."
          global.ERROR = 400
          global.REDIRECT_URL = ''
        }
      } else if (valor.code === 404) {
        global.HEADER = global.MODAL_HEAD_ERROR
        global.BODY = valor.message
        global.ERROR = valor.code
        global.REDIRECT_URL = ''
      }
     /* window.localStorage.setItem(
        'loggedUser', JSON.stringify(items.data)
      )
      const usuario = items.data.apellidos
      window.localStorage.setItem(
        'name', usuario
      )
      window.localStorage.setItem(
        'empresa', items.data.nombreEmpresa
      )
      window.localStorage.setItem(
        'rol', items.data.canal.nombre
      ) 
      window.localStorage.setItem('token', items.data.token);
      localStorage.setItem('data', items.data.idUsuario);
  
     global.HEADER = global.MODAL_HEAD_SUCCESS
      global.BODY = global.MODAL_BODY_SUCCES + usuario
      global.ERROR = 0
      if (global.HAVECAMPAIGN === 0) {
        global.REDIRECT_URL = global.ROUTE_WELCOME
      } else {
        global.REDIRECT_URL = global.ROUTE_DASHBOARD
      } */
    } else
    if (items.code === 500) {
      global.HEADER = global.MODAL_HEAD_ERROR
      global.BODY = global.ERROR_SERVICE_OFF
      global.ERROR = items.code
      global.REDIRECT_URL = ''
    } else if (items.code === 404) {
      global.HEADER = global.MODAL_HEAD_ERROR
      global.BODY = items.message
      global.ERROR = items.code
      global.REDIRECT_URL = ''
    } else if (items.code === 423) {
      global.HEADER = global.MODAL_HEAD_ERROR
      global.BODY = items.message
      global.ERROR = items.code
      global.REDIRECT_URL = global.ROUTE_RECUPERAR
    } else if (items.code === 400) {
      global.HEADER = global.MODAL_HEAD_ERROR
      global.BODY = global.CAMPOS_OBLIGATORIOS
      global.ERROR = items.code
      global.REDIRECT_URL = ''
    } else if (items.code === 401) {
      global.HEADER = global.MODAL_HEAD_ERROR
      global.BODY = items.message
      global.ERROR = items.code
      global.REDIRECT_URL = ''
    }
  } else {
    global.ERROR = 1
  }

  const response = {
    header: global.HEADER,
    body: global.BODY,
    url: global.REDIRECT_URL,
    error: global.ERROR
  }
  console.log(response)
  return response
}
const ValidarToken = async (tokenn, idUsuarios) => {
  const nemonicoCanal = 'NOTIFY';
  try {
    // const idUsuario = localStorage.getItem('idUsuario');
    //  const tokenUsuario = localStorage.getItem('token');
     let idUsuario = idUsuarios;
     let tokenUsuario = tokenn;
    if (tokenn === undefined) {
      tokenUsuario = localStorage.getItem('token');
    } else {
      tokenUsuario = tokenn;
    }
    if (idUsuarios === undefined) {
      idUsuario = localStorage.getItem('idUsuario');
    } else {
      idUsuario = idUsuarios;
    }
    const canales = '5b538d10-fcb3-11ed-be56-0242ac120002';
    const token = `Bearer ${tokenUsuario}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
      Canal: canales
    };
    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        idUsuario,
        nemonicoCanal
      })
    };
    const response = await fetch(global.VALIDAR_LOGIN, requestOptions);
    const data = await response.json();

    console.log('data Validacion Login', data)
    localStorage.setItem('token', data.data.token);
    localStorage.setItem('tokenValidado', data.data.token);
    localStorage.setItem('nombreUsuario', data.data.nombres);
    localStorage.setItem('correoUsuario', data.data.correo);
    localStorage.setItem('idEmpresa', data.data.idEmpresa);
    localStorage.setItem('idEmpresaPadre', data.data.idEmpresaPadre);
    localStorage.setItem('permisos', JSON.stringify(data.data.listPermisos));
    return data;
  } catch (error) {
    console.error(error);
  }
};
const estado = 'A';
const creeateUser = async userData => {
  console.log(userData)
  const validate = validationCreateUser.validate(userData)
  if (!validate) {
    global.ERROR = 1
  } else {
    const headers = { 'Content-Type': 'application/json' }
    headers.Canal = global.CANALSECURITY
    const data = {
      idEmpresa: userData.idEmpresa,
      nombres: userData.name,
      apellidos: userData.lastName,
      correo: userData.email,
      telefonoMovil: userData.movilPhone,
      usuario: userData.user,
      contrasenia: base64.encode(userData.pass1),
      estado: estado,
      tipoIdentificacion: '',
      identificacion: ''
    }
    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    }
    const dataResp = await fetch(global.REGISTRARUSUARIO, requestOptions)
    const resp = await dataResp.json()
    if (resp.code === 200) {
      global.BODY = global.MESSAGES_CREATE_USER
      global.REDIRECT_URL = global.ROUTE_USER_CREATED
      global.HEADER = 'Activar cuenta'
      global.LINK = global.LINK_EMAIL
      global.ERROR = resp.code
      global.USER_ID = resp.data.idUsuario
    } else if (resp.code === 401 || resp.code === 500) {
      global.BODY = global.ERROR_SERVICE_OFF
      global.REDIRECT_URL = ''
      global.HEADER = 'Advertencia'
      global.ERROR = resp.code
      global.LINK = ''
    } else {
      global.BODY = resp.message
      global.REDIRECT_URL = ''
      global.HEADER = 'Advertencia'
      global.ERROR = resp.code
      global.LINK = ''
    }
  }
  const response = {
    header: global.HEADER,
    body: global.BODY,
    url: global.REDIRECT_URL,
    error: global.ERROR,
    link: global.LINK,
    field: validate.field
  }
  return response
}
// servicio crear empresa
const usuarioCreacion = 'SIGNUP'
const CrearEmpresa = async (datosEmpresa) => {
  try {
    const canal = global.CANAL2
    const url = 'http://desa.goitsa.me:8989/goit-security-api/v2/empresa/crearEmpresa'

    const headers = {
      'Content-Type': 'application/json',
      Canal: canal
    }

    const body = {
      nombre: datosEmpresa,
      razonSocial: datosEmpresa,
      estado: estado,
      usuarioCreacion: usuarioCreacion
    }

    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    }

    const response = await fetch(url, requestOptions)
    const data = await response.json()

    console.log(data)
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

// servicio para crear usuario
const CrearUsuario = async (datosUsuario) => {
  const estado = 'A';
  const isValid = validationCreateUser.validate(datosUsuario);
  console.log(global.LABEL_INPUT_ERROR)
  if (isValid === false) {
    // Mostrar mensaje de error en cada campo de entrada
    global.ERROR = 1;
  } else {
    try {
      const usuario = datosUsuario.user;
      const contrasena = base64.encode(datosUsuario.pass1);
      const correo = datosUsuario.email;
      const nombres = datosUsuario.name;
      const apellidos = datosUsuario.lastName;
      const telefonoMovil = datosUsuario.movilPhone;
      const empresaID = await CrearEmpresa(datosUsuario.empresa);
      console.log(empresaID)
      if (empresaID.code === 200) {
        const canales = '5b538d10-fcb3-11ed-be56-0242ac120002';
        const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Canal: canales
        };
        
        const body = {
          usuario: usuario,
          contrasenia: contrasena,
          idEmpresa: empresaID.data.idEmpresa,
          correo: correo,
          nombres: nombres,
          apellidos: apellidos,
          telefonoMovil: telefonoMovil,
          estado: estado,
          idUsuarioAltera: "fca52111-a4e9-4c97-b11e-bb560e987675",
          tipoIdentificacion: '',
          identificacion: ''
        };
        const requestOptions = {
          method: 'POST',
          headers,
          body: JSON.stringify(body)
        };

        console.log(body);
        const response = await fetch('http://desa.goitsa.me:8989/goit-security-api/v2/usuario/crearUsuario', requestOptions);
        const data = await response.json();
       
  
        // Adaptar las respuestas y errores
        if (data.code === 200) {
          global.BODY = global.MESSAGES_CREATE_USER;
          global.REDIRECT_URL = global.ROUTE_USER_CREATED;
          global.HEADER = 'Activar cuenta';
          global.LINK = global.LINK_EMAIL;
          global.ERROR = data.code;
          global.USER_ID = data.data.idUsuario;
        } else if (data.code === 401 || data.code === 500) {
          global.BODY = global.ERROR_SERVICE_OFF;
          global.REDIRECT_URL = '';
          global.HEADER = 'Advertencia';
          global.ERROR = data.code;
          global.LINK = '';
        } else {
          global.BODY = data.message;
          global.REDIRECT_URL = '';
          global.HEADER = 'Advertencia';
          global.ERROR = data.code;
          global.LINK = '';
        }
  
        return {
          header: global.HEADER,
          body: global.BODY,
          url: global.REDIRECT_URL,
          error: global.ERROR,
          link: global.LINK,
          field: isValid.field
        };
      } else {
        return {
          header: 'Advertencia',
          body: "Ocurrio un error, por favor intente más tarde.",
          url: "",
          error: global.ERROR,
          link: "",
          field: ""
        };
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
};




const sendMailRestorePassword = async userData => {
  const headers = { 'Content-Type': 'application/json' }
  headers.Canal = global.CANAL
  const requestOptions = {
    method: 'PUT',
    headers
  }
  const dataResp = await fetch(global.RESTORE_PASS + userData.mail, requestOptions)
  const resp = await dataResp.json()
  if (resp.success) {
    global.BODY = global.MESSAGES_CREATE_USER
    global.REDIRECT_URL = global.ROUTE_USER_CREATED
    global.HEADER = 'Correo enviado'
    global.ERROR = false
  } else {
    global.BODY = resp.message
    global.REDIRECT_URL = ''
    global.HEADER = 'Advertencia'
    global.ERROR = true
  }
  const response = {
    header: global.HEADER,
    body: global.BODY,
    url: global.REDIRECT_URL,
    error: global.ERROR,
    code: resp.code
  }
  return response
}

const sendMail = async userData => {
  const headers = { 'Content-Type': 'application/json' }
  headers.Canal = global.CANAL
  const requestOptions = {
    method: 'PUT',
    headers
  }
  const dataResp = await fetch(global.ROUTE_CREATE_USER_EMAIL + userData.proceso, requestOptions)
  const resp = await dataResp.json()
  if (resp.success) {
    global.BODY = global.MESSAGES_CREATE_USER
    global.REDIRECT_URL = global.ROUTE_USER_CREATED
    global.HEADER = 'Activar cuenta'
    global.ERROR = 0
  } else {
    global.BODY = resp.message
    global.REDIRECT_URL = ''
    global.HEADER = 'Advertencia'
    global.ERROR = 2
  }
  const response = {
    header: global.HEADER,
    body: global.BODY,
    url: global.REDIRECT_URL,
    error: global.ERROR
    // field: validate.field
  }
  return response
}
const sendDesuscripcion = async (data, idTicket) => {
  const headers = { 'Content-Type': 'application/json' }
  headers.Canal = global.CANAL
  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(data)
  }
  const dataResp = await fetch(global.DESUSCRIPCION + '?idTicket=' + idTicket, requestOptions)
  const resp = await dataResp.json()
  if (resp.success) {
    global.BODY = global.MESSAGES_CREATE_USER
    // global.REDIRECT_URL = global.ROUTE_USER_CREATED
    global.HEADER = 'Desuscripción Exitosa'
    // global.ERROR = 0
  } else {
    global.BODY = resp.message
    // global.REDIRECT_URL = ''
    global.HEADER = 'Advertencia'
    // global.ERROR = 2
  }
  const response = {
    header: global.HEADER,
    body: global.BODY,
    code: resp.code
    // field: validate.field
  }
  return response
}

const restorePasswords = async userData => {
  const validate = validationPassword.validate(userData)
  if (!validate) {
    global.ERROR = 3
  } else {
    const headers = { 'Content-Type': 'application/json' }
    headers.Canal = global.CANAL
    const data = {
      contrasenia: base64.encode(userData.password2)
    }
    const requestOptions = {
      method: 'PUT',
      headers,
      body: JSON.stringify(data)
    }
    const dataResp = await fetch(global.RESTORE_NEW_PASSWORD + userData.proceso, requestOptions)
    const resp = await dataResp.json()
    if (resp.success) {
      global.BODY = global.MESSAGES_CREATE_USER
      global.REDIRECT_URL = global.ROUTE_CONGRATULATIONPASSWORD
      global.HEADER = 'Activar cuenta'
      global.ERROR = 0
    } else {
      global.BODY = resp.message
      global.REDIRECT_URL = ''
      global.HEADER = 'Advertencia'
      global.ERROR = 2
    }
  }
  const response = {
    header: global.HEADER,
    body: global.BODY,
    url: global.REDIRECT_URL,
    error: global.ERROR,
    field: validate.field
  }
  return response
}

const getParametrosPorId = async idGrupo => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const resp = await fetch(`${global.GET_PARAMETROS_GENERALES_ID}?IdParametroGeneral=${idGrupo}`, requestOptions)
    const items = await resp.json()
    if (resp.ok === false) {
      global.LABEL_INPUT_ERROR = 'No se pudieron consutlar el destinatario, intente en unos momentos'
      global.ERROR_LABEL = 'error'
    }
    const response = {
      data: items,
      header: global.HEADER,
      body: global.BODY,
      url: global.REDIRECT_URL,
      error: global.ERROR
    }
    return response
  } catch (editor) {
    global.LABEL_INPUT_ERROR = 'No se pudieron consutlar el destinatario, intente en unos momentos'
    global.ERROR_LABEL = 'error'
  }
}

export default {
  login,
  creeateUser,
  sendMailRestorePassword,
  sendMail,
  restorePasswords,
  sendDesuscripcion,
  getParametrosPorId,
  CrearEmpresa,
  CrearUsuario,
  ValidarToken
}

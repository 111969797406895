import React, { useEffect } from 'react'
import CampaignStep1 from './campaignStep1'
import CampaignStep2 from './campaignStep2'
import CampaignStep3 from './campaignStep3'
import CampaignStep4 from './campaignStep4'
import CampaignStep5 from './campaignStep5'
import Finish from './finishSteps'
// import steps from '../../static/stepCampaign.json'
const Campaign1 = () => {
  useEffect(() => {

  }, [])
  return (
    <div className='container-fluid'>
        <div className='col-md-7'><h4>{global.RETORNO}</h4></div>
        <CampaignStep1/>
        <div className='' id='scroll-2'>
        <CampaignStep2/>
        </div>
        <CampaignStep3/>
        <CampaignStep4/>
        <CampaignStep5/>
        <Finish/>
    </div>
  )
}
export default Campaign1

/* eslint-disable prefer-const */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import ModalSpiner from '../../components/util/ModalSpiner'
import Toast from '../../components/util/Toast'
import ModalCampaignConfirmacion from '../../components/util/ModalCampaignConfirmacion'
import date from '../../services/global'
import serviceReport from '../../services/report'
import helpServices from '../../services/helper'
import $, { event } from 'jquery'
import ReactPaginate from 'react-paginate'
import ReactTooltip from 'react-tooltip'
const Unificado = () => {
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const [valor, setValor] = useState('')
    const [isOpenToast, setIsOpenToast] = useState(false)
    const [messageToast, setMessageToast] = useState('')
    const [codigoToast, setCodigoToast] = useState(400)
    const [spiner, setSpiner] = useState(false)
    const [dataReport, setDataReport] = useState([])
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const d = date.getDate()

    const [isOpen, setIsOpen] = useState(false)
    const [header, setHeader] = useState()
    const [classbody, setClassbody] = useState()
    const [color, setColor] = useState()
    const [body, setBody] = useState()
    const [button, setButton] = useState()
    const [buttonCerrar, setButtonCerrar] = useState()
    const [handle, setHandle] = useState()
    const [btnAceptar, setBtnAceptar] = useState()

   const [processData, setProcessData] = useState(false)
   const [initPage, setInitPage] = useState(0)
   const [pageActual, setPageActual] = useState(1)
   const [perPage, setPerPage] = useState(5)
   const [nombreBusqueda, setNombreBusqueda] = useState('')
   const [totalPage, setTotalPage] = useState(0)
   const obSe = {
       filtro: 'fecha',
       nombreBusqueda: '',
       pagina: 1,
       size: 5
    }
const handleGenerar = () => {
    if ((fechaInicio === '' && fechaFin === '' && valor === '') || ('' + fechaInicio === 'null' && '' + fechaFin === 'null' && valor === 'null')) {
      global.LABEL_INPUT_ERROR = global.CAMPAIGN_FECHA
      $('#fechaInicio').addClass('error')
      $('#fechaFin').addClass('error')
      mostrarMensaje('400', global.LABEL_INPUT_ERROR)
    } else if (fechaInicio > fechaFin) {
      $('#fechaInicio').addClass('error')
      mostrarMensaje('400', 'Su rango de fecha es inválido')
    } else {
      $('#fechaInicio').removeClass('error')
      $('#fechaFin').removeClass('error')
      setSpiner(true)
      getDataReport()
    }
  }
  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000)
  }
  const getDataReport = async () => {
    setSpiner(true)
    const serviceRe = await serviceReport.getReporteUnificado({ fechaInicio, fechaFin, obSe, valor })
    // let objMessage = helpServices.getMessage(serviceRe.data)
    setSpiner(false)
    console.log(serviceRe)
    if (serviceRe.data.code === 200) {
      console.log(serviceRe.data.data)
      if (serviceRe.data.data.row.length > 0) {
        $('#menu-search').removeClass()
        $('#menu-search').addClass('success-panel col-12')
        $('.fa-file-excel').addClass('green-color')
        $('.fa-arrow-down').addClass('red-color')
        $('.btnEXCEL').addClass('color-border')
      } else {
        $('#menu-search').removeClass()
        $('#menu-search').addClass('no-success-panel')
        $('.fa-file-excel').removeClass('green-color')
        $('.fa-arrow-down').removeClass('red-color')
        $('.btnEXCEL').removeClass('color-border')
      }
      setProcessData(true)
      setDataReport(serviceRe.data.data.row)
      setTotal(serviceRe.data.data.total)
      setTotalPage(Math.ceil(serviceRe.data.data.total / obSe.size))
    } else {
      mostrarMensaje(serviceRe.data.code, serviceRe.data.message)
    }
  }

  const searchPerPage = (initPageS, value) => {
    setInitPage(initPageS)
    setPageActual(Number(initPageS))
    setProcessData(false)

    obSe.size = Number(value)
    obSe.nombreBusqueda = nombreBusqueda
    obSe.pagina = 1

    getDataReport()
  }
  const handlePageClick = (event) => {
    if (event.nextSelectedPage !== undefined) {
      setInitPage(event.nextSelectedPage)
      setPageActual(event.nextSelectedPage + 1)
      obSe.nombreBusqueda = nombreBusqueda
      obSe.size = perPage
      obSe.pagina = event.nextSelectedPage + 1
      getDataReport()
    }
  }
  const handleExcel = async () => {
    if ($('.fa-file-excel').hasClass('green-color')) {
      setSpiner(true)
      const localData = JSON.parse(window.localStorage.getItem('loggedUser'))
      const serviceUnificado = await serviceReport.getEXCELUnificado({ fechaInicio, fechaFin, valor })
      setSpiner(false)
      if (serviceUnificado.code === 200) {
        const url = window.URL.createObjectURL(new Blob([serviceUnificado.data]))
        const link = document.createElement('a')
        console.log(link)
        link.href = url
        link.setAttribute('download', 'reporteUnificado.xlsx')
        document.body.appendChild(link)
        link.click()
      }
    }
  }

  return (
    <div className='container-fluid p-0'>
        <ModalSpiner opt={spiner} ></ModalSpiner>
        {isOpenToast && <Toast message={messageToast} codigo={codigoToast}/>}
        <ModalCampaignConfirmacion isOpen={isOpen} classbody={classbody} color={color} header={header} body={body} button={button} buttonCerrar={buttonCerrar} handle={handle} btnAceptar={btnAceptar}/>
        <div className='mb-12 panel-group pt-3 pb-3 header-report mb-4'>
        <div className='d-flex'>
            <div className='col-5 p-0'>
                <span className='campaing-tittle'><b>Reporte Unificado</b></span>
            </div>
        </div>
        </div>
        <div className='row'>
            <div className='form-group col-md-2'>
                <span className='text-input name-input every-label' id='error_fecha'></span>
                <label className='grey-color'>Fecha de inicio</label>
                <input id='fechaInicio'
                        value={fechaInicio}
                        type='date'
                        max={d}
                        onChange={({ target }) => setFechaInicio(target.value)}
                        className='form-control item'></input>
            </div>
            <div className='form-group col-md-2'>
                <span className='text-input name-input every-label' id='error_fecha'></span>
                <label className='grey-color'>Fecha de finalización</label>
                <input id='fechaFin'
                        value={fechaFin}
                        type='date'
                        max={d}
                        onChange={({ target }) => setFechaFin(target.value)}
                        className='form-control item'></input>
            </div>
            <div className='form-group col-md-3'>
                    <span className="text-input name-input every-label" id='error_valor'></span>
                    <label className='grey-color'>Usuario/Unidad de Servicio</label>
                    <input id='valor'
                          value={valor}
                          type="text"
                          max={d}
                          onChange={({ target }) => setValor(target.value)}
                          className="form-control item"></input>
            </div>
            <div className='form-group col-1' >
                    <label className='grey-color'>Descarga Excel</label>
                    <div className='row col btnEXCEL ml-3' onClick={handleExcel}><i className="far fa-file-excel"></i> <i className="fa fa-arrow-down ml-1" aria-hidden="true"></i></div>
                </div>
            <div className='form-group col-md-3 button-generate report-listas'>
                <a type='button' onClick={() => { handleGenerar() }} className='btn btn-success btn-campania contacts report-btn'>
                    <b>
                        Generar
                    </b>
                </a>
            </div>
        </div>
        <div className='panel-group pt-2 pb-2 header-report mb-3 data-report'>
            <div className='d-flex align-items-center'>
                <div className='col-5 p-0'>
                    <span className='campaing-tittle'><b>Historial de Reportes Unificado</b></span>
                </div>
                <div className='col-7 show-cant'>
                    <i className='fa fa-chart-pie'></i> <span>Actualmente tienes { total } reportes</span>
                </div>
            </div>
        </div>
        <div className='mb-12 panel-group data-report listar-report'>
          <div className='no-success-panel' id='menu-search'>
            <div className='search-table search-tables row'>
              <div className='col-md-4 search-perPage'>
                <label className='mr-2 font-sizze'> Mostrando: </label>
                <select onChange={({ target }) => { setPerPage(target.value); searchPerPage(0, target.value) }}
                        value={perPage}
                        id='selectPerPage'
                        className='form-control item styled-select mr-2'>
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                        </select> <label className='font-sizze'>Registros </label>
              </div>
            </div>
          </div>
          <table id='prog-table-report' className='table'>
            <thead>
              <tr>
                <th>Usuario</th>
                <th>Area</th>
                <th>Asunto</th>
                <th>Enviados</th>
                <th>Exitosos</th>
                <th>Fallidos</th>
                <th>Rebotados</th>
                <th>Fecha Registro</th>
                <th>Unidad Servicio</th>
              </tr>
            </thead>
            <tbody id='tbody'>
              {dataReport.length > 0
              ? dataReport.map((val, index) => {
                return (<React.Fragment key={val.index}>
                  <tr>
                    <td>{val.usuario}</td>
                    <td>{val.area}</td>
                    <td className='dataTables_empty'>
                      {val.asunto.length > 25
                        ? <div className='ajust-text' data-tip={`<p class="content-tool"> ${val.asunto.toLowerCase()}</p>`} data-html={true}>
                        {val.asunto.toLowerCase()}
                        <ReactTooltip place="bottom" type="dark" effect="solid"/>
                        </div>
                        : <>
                        <span>{val.asunto.toLowerCase()}</span>
                        </>
                      }
                    </td>
                    <td>{val.enviados}</td>
                    <td>{val.exitosos}</td>
                    <td>{val.fallidos}</td>
                    <td>{val.rebotados}</td>
                    <td>{val.fechaRegistro}</td>
                    <td>{val.unidadNegocio}</td>
                  </tr>
                </React.Fragment>)
              }
              )
              : <tr>
                <td colSpan={8}>
                  <span>No hay información</span>
                </td>
              </tr>
            }
            </tbody>
          </table>
          <div className='content-paginate d-flex mb-5'>
            {(processData && totalPage > 0) &&
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onClick={ (event) => { handlePageClick(event) } }
                pageRangeDisplayed={1}
                pageCount={totalPage}
                previousLabel="<"
                initialPage={initPage}
                // forcePage={initPage}
                className="paginate-custom"
                renderOnZeroPageCount={null}
            />
            }
          </div>
        </div>
    </div>
  )
}
export default Unificado

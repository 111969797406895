/* eslint-disable no-undef */

import React from 'react'
import $ from 'jquery'
import '../../src/scss/_templates.scss'

const Pagination = () => {
  $(document).ready(function () {
    $(document).ready(function () {
      $('.page-link').on('click', function () {
        $('.page-link').each(function (index, value) {
          $(value).parent().removeClass('active')
        })

        // Hide all cards
        $('.card').each(function (index, value) {
          $('.card').hide()
        })

        $(this).parent().addClass('active')
        const cardId = '#' + $(this).text()
        $(cardId).show()
      })
    })
  })
  return (
        <>
            <nav>
                <ul className="pagination pg-red">

                    <li className="page-item active"><a className="page-link">1</a></li>
                    <li className="page-item"><a className="page-link">2</a></li>
                    <li className="page-item"><a className="page-link">3</a></li>
                    <li className="page-item"><a className="page-link">4</a></li>

                </ul>
            </nav>

            <hr />
            <h3>Container</h3>
            <hr />
            <div className="container">
                <div id="1" className="card active">

                    <img className="img-fluid" src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20%282%29.jpg" alt="Card image cap" />

                    <div className="card-block">
                        <h4 className="card-title">This is card 1</h4>
                        <p className="card-text">First content</p>
                        <a href="#" className="btn btn-primary">Button</a>
                    </div>

                </div>
                <div id="2" className="card">

                    <img className="img-fluid" src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20%282%29.jpg" alt="Card image cap" />

                    <div className="card-block">
                        <h4 className="card-title">This is card 2</h4>
                        <p className="card-text">Second content</p>
                        <a href="#" className="btn btn-primary">Button</a>
                    </div>
                </div>
                <div id="3" className="card">

                    <img className="img-fluid" src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20%282%29.jpg" alt="Card image cap" />

                    <div className="card-block">
                        <h4 className="card-title">This is card 3</h4>
                        <p className="card-text">Third content</p>
                        <a href="#" className="btn btn-primary">Button</a>
                    </div>
                    <img className="img-fluid" src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20%282%29.jpg" alt="Card image cap" />

                    <div className="card-block">
                        <h4 className="card-title">This is card 3</h4>
                        <p className="card-text">Third content</p>
                        <a href="#" className="btn btn-primary">Button</a>
                    </div>
                    <img className="img-fluid" src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20%282%29.jpg" alt="Card image cap" />

                    <div className="card-block">
                        <h4 className="card-title">This is card 3</h4>
                        <p className="card-text">Third content</p>
                        <a href="#" className="btn btn-primary">Button</a>
                    </div>
                    <img className="img-fluid" src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20%282%29.jpg" alt="Card image cap" />

                    <div className="card-block">
                        <h4 className="card-title">This is card 3</h4>
                        <p className="card-text">Third content</p>
                        <a href="#" className="btn btn-primary">Button</a>
                    </div>
                    <img className="img-fluid" src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20%282%29.jpg" alt="Card image cap" />

                    <div className="card-block">
                        <h4 className="card-title">This is card 3</h4>
                        <p className="card-text">Third content</p>
                        <a href="#" className="btn btn-primary">Button</a>
                    </div>

                </div>
                <div id="4" className="card">
                    <img className="img-fluid" src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20%282%29.jpg" alt="Card image cap" />

                    <div className="card-block">
                        <h4 className="card-title">This is card 4</h4>
                        <p className="card-text">Fourth content</p>
                        <a href="#" className="btn btn-primary">Button</a>
                    </div>
                </div>
            </div>
        </>
  )
}

export default Pagination

import React, { useState, useEffect } from 'react'
import '../../scss/_campaaign.scss'
import serviceReport from '../../services/report'
import $ from 'jquery'
import ReactTooltip from 'react-tooltip'
import ModalSpiner from '../../components/util/ModalSpiner'
import Toast from '../../components/util/Toast'
import ModalCampaignConfirmacion from '../../components/util/ModalCampaignConfirmacion'
import servicePCampana from '../../services/campaign'
// eslint-disable-next-line no-unused-vars
import DataTable from 'datatables.net'
const CampaignReport = () => {
  const [campaign, setCampaigns] = useState('')
  const [data, setData] = useState([])
  const [spiner, setSpiner] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [header, setHeader] = useState()
  const [classbody, setClassbody] = useState()
  const [color, setColor] = useState()
  const [body, setBody] = useState()
  const [button, setButton] = useState()
  const [buttonCerrar, setButtonCerrar] = useState()
  const [handle, setHandle] = useState()
  const [btnAceptar, setBtnAceptar] = useState()

  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  useEffect(() => {
    try {
      $('#campaing-table').dataTable().fnDestroy()
      campaignYable()
      setSpiner(true)
    } catch (e) {
    }
  }, [])
  const campaignYable = async (event) => {
    try {
      const templates = await serviceReport.getReportCamp({})
      if (templates.data.code === 200) {
        setCampaigns(templates.data.data.length)
        setData(templates.data.data)
        setSpiner(false)
        $('#campaing-table').find('tr').each(function () {
          $(this).css('text-align', 'center')
        })
        $('#campaing-table').DataTable({
          initComplete: function (settings, json) {
            $('body').find('.dataTables_scrollBody').addClass('scrollbar')
          },
          scrollY: '250px',
          scrollCollapse: true,
          order: [[2, 'desc']],
          lengthMenu: [5, 10, 20],
          language: {
            decimal: '',
            emptyTable: 'No hay información',
            info: 'Mostrando _START_ a _END_ de un total de _TOTAL_ Registros',
            infoEmpty: 'Mostrando 0 to 0 of 0 Registros',
            infoFiltered: '(Filtrado de _MAX_ total Registros)',
            infoPostFix: '',
            thousands: ',',
            lengthMenu: 'Mostrar _MENU_ Registros',
            loadingRecords: 'Cargando...',
            processing: 'Procesando...',
            search: 'Buscar:',
            zeroRecords: 'Sin resultados encontrados',
            paginate: {
              first: 'Primero',
              last: 'Ultimo',
              next: '>',
              previous: '<'
            }
          }
        })
        $('[data-toggle="tooltip"]').tooltip()
      } else {
        console.log('no')
        // mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
      }
    } catch (e) {
    }
  }

  const deleterCampaingn = (idDetalleProgramacion, estado) => {
    console.log('estado', estado)
    const id = idDetalleProgramacion
    if (estado === 'I') {
      const eliminar = () => async () => {
        setSpiner(true)
        setIsOpen(false)
        console.log(id)
        const data = await servicePCampana.deleterDetalleProgramacion({ id })
        console.log(data.data.code)
        if (data.data.code === 200) {
          console.log('si')
          setSpiner(false)
          $('#campaing-table').dataTable().fnDestroy()
          campaignYable()
          mostrarMensaje('' + data.data.code, '' + global.PROCESO_OK)
        } else {
          setSpiner(false)
          mostrarMensaje('' + data.data.code, 'No se pudo eliminar la programación, intente más tarde')
        }
      }
      setIsOpen(true)
      setColor()
      setHeader()
      setClassbody()
      setBody('¿Está seguro de eliminar la programación?')
      setBtnAceptar(eliminar)
      setButton('Aceptar')
      setHandle(handleClose)
      setButtonCerrar('Cancelar')
    } else if (estado === 'P') {
      mostrarMensaje('400', 'No se puede eliminar la programación con estado en Proceso.')
    }
  }
  const handleClose = () => () => {
    setIsOpen(false)
  }

  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000)
  }

  return (
        <div>
          <ModalSpiner opt={spiner} />
          {isOpenToast && <Toast message={messageToast} codigo={codigoToast} />}
          <ModalCampaignConfirmacion isOpen={isOpen} classbody={classbody} color={color} header={header} body={body} button={button} buttonCerrar={buttonCerrar} handle={handle} btnAceptar={btnAceptar}/>
            <div className="mb-12 panel-group mt-3">
                <div className='row mb-1 mt-2'>
                    <div className='col-5'>
                        <span className='campaing-tittle'><b>Historial de campañas</b></span>
                    </div>
                    <div className='col-7 text-align-end pr-5'>
                        <p className='ml-4'><i className='fa fa-chart-pie mr-2'></i> Actualmente tienes <b> { campaign } reportes</b></p>
                    </div>
                </div>
                <hr/>
            </div>
            <div className='mt-3 pr-4 data-report'>
            <table id='campaing-table' className="table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Canal</th>
                <th>Plantilla</th>
                <th>Lista</th>
                <th>Fecha de creación</th>
                <th>Fecha de programación</th>
                <th>Estado</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody id='tbody'>
            {data.map((val) => {
              return (<React.Fragment key={val.id}>
                              <tr key={val.id} className='odd'>
                                <td className='dataTables_empty'>
                                {val.nombreCampana.length > 30
                                  ? <div className='ajust-text' data-tip={`<p class="content-tool"> ${val.nombreCampana.toLowerCase()}</p>`} data-html={true}>
                                    {val.nombreCampana.toLowerCase()}
                                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                    </div>

                                  : <>
                                  <span>{val.nombreCampana.toLowerCase()}</span>
                                  </>
                                }
                                </td>
                                <td>{val.tipoPlantilla === 'T' ? 'SMS' : val.tipoPlantilla === 'H' ? 'Mail' : val.tipoPlantilla === 'E' ? 'WhatsApp' : ''}</td>
                                <td className='dataTables_empty'>
                                {val.nombrePlantilla.length > 30
                                  ? <div className='ajust-text' data-tip={`<p class="content-tool"> ${val.nombrePlantilla.toLowerCase()}</p>`} data-html={true}>
                                    {val.nombrePlantilla.toLowerCase()}
                                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                    </div>

                                  : <>
                                  <span>{val.nombrePlantilla.toLowerCase()}</span>
                                  </>
                                }
                                </td>
                                <td className='dataTables_empty'>
                                {val.nombreGrupo.length > 30
                                  ? <div className='ajust-text' data-tip={`<p class="content-tool"> ${val.nombreGrupo.toLowerCase()}</p>`} data-html={true}>
                                    {val.nombreGrupo.toLowerCase()}
                                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                    </div>

                                  : <>
                                  <span>{val.nombreGrupo.toLowerCase()}</span>
                                  </>
                                }
                                </td>
                                <td>{val.fechaCreacion}</td>
                                <td>{val.fechaProgramacion}</td>
                                <td>{val.estado === 'I' ? 'Ingresada' : val.estado === 'P' ? 'En proceso' : ''}</td>
                                <td >
                                    <div className="dropdown">
                                      <a type="text" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <i className="fa fa-ellipsis-v fa-color"></i>
                                      </a>
                                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <div onClick={() => { deleterCampaingn(val.idDetalleProgramacion, val.estado) }} >
                                            <i className='ml-2 far fa-trash-alt notify-color'></i>
                                            <span className='ml-3 detail-drop-down'>Eliminar</span>
                                          </div>
                                      </div>
                                    </div>
                                       </td>
                              </tr>
                              </React.Fragment>
              )
            }
            )}
            </tbody>
            </table>
            </div>
        </div>
  )
}

export default CampaignReport

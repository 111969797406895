// the default template that will appear to user on load

// to create custom template here we need to copy paste the object
export const template = {
  counters: {
    u_column: 1,
    u_row: 1,
    u_content_custom_dy_recommendation: 1,
    u_content_divider: 2,
    u_content_text: 1,
    u_content_button: 2,
    u_content_image: 3
  },
  body: {
    rows: [
      {
        cells: [1],
        columns: [
          {
            contents: [
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              _meta: {
                htmlID: 'u_column_1',
                htmlClassNames: 'u_column'
              }
            }
          }
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: '0px',
          hideDesktop: false,
          _meta: {
            htmlID: 'u_row_1',
            htmlClassNames: 'u_row'
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          hideMobile: false,
          noStackMobile: false
        }
      }
    ],
    values: {
      textColor: '#000000',
      backgroundColor: '#e7e7e7',
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: false,
        center: true,
        cover: false
      },
      contentWidth: '500px',
      contentAlign: 'center',
      fontFamily: {
        label: 'Arial',
        value: 'arial,helvetica,sans-serif'
      },
      preheaderText: '',
      linkStyle: {
        body: true,
        linkColor: '#0000ee',
        linkHoverColor: '#0000ee',
        linkUnderline: true,
        linkHoverUnderline: true
      },
      _meta: {
        htmlID: 'u_body',
        htmlClassNames: 'u_body'
      }
    }
  },
  schemaVersion: 6
}

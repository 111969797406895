/* eslint-disable operator-linebreak */
/* eslint-disable space-before-blocks */
/* eslint-disable padded-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prefer-const */
/* eslint-disable indent */
/* eslint-disable semi */
import React, { useState, useEffect } from 'react'
import ModalSegment from '../../components/util/ModalSegmentoCreate'
import gruposServices from '../../services/contact'
import Segmentos from './components/Segmentos'
import ModalContactListAgg from '../../components/util/ModalContactListAgg'
import ModalSpiner from '../../components/util/ModalSpiner'
import helpServices from '../../services/helper'
import $ from 'jquery'
import ReactPaginate from 'react-paginate';
import ReactTooltip from 'react-tooltip';
import loginServices from '../../services/login'

const contactSegment = ({ handleSegment, mostrarMensaje }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [buttonModal, setButtonModal] = useState('')
  const [headerModal, setHeaderModal] = useState('')
  const [dataListas, setListas] = useState([])
  const [dataListasGen, setListasGen] = useState([])
  const [isOpenAg, setIsOpenAg] = useState(false)
  const [headerAg, setHeaderAg] = useState('')
  const [modalButton2Ag, setModalButton2Ag] = useState('')
  const [coloHeader2Ag, setColorHeader2Ag] = useState('')
  const [opcionLista, setOpcionLista] = useState('')
  const [idGrupo, setIdGrupo] = useState('')
  const [destinatarioEdit, setDestinatario] = useState('')
  const [spiner, setSpiner] = useState(false)
  const [tipoGrupo, setTipoGrupo] = useState('')

  const [processData, setProcessData] = useState(true)
  const [initPage, setInitPage] = useState(0)
  const [pageActual, setPageActual] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [perPage, setPerPage] = useState(5)
  const [nombreBusqueda, setNombreBusqueda] = useState('')
  const [totalGrupos, setTotalGrupo] = useState(0)

  const obSe = {
    tipoGrupo: 'E',
    filtro: 'fecha',
    nombreBusqueda: '',
    pagina: 1,
    size: 5
  }
  const handleModal = (op) => {
    setIsOpen(true)
    setButtonModal('Crear')
    setHeaderModal('Crear nuevo segmento')
    localStorage.setItem('list', null)
  }
  const handleclose = (accion) => {
    console.log('ejecutaCierraModal')
    if (accion) {
      getDataSegmentos();
      mostrarMensaje(200, global.PROCESO_OK)
    }
    
    setIsOpen(false)
  }
  useEffect(() => {
    try {
      getDataSegmentos()
    } catch (e) {
    }
    validarPermisos();
  }, [])
  const getDataSegmentos = async (event) => {
   // $('#buscarGrupo').prop('value', '')

    setSpiner(true)
    console.log('objetoSend', obSe);
    let paramsGrupo = helpServices.formatUrlParams(obSe)
    console.log('params', paramsGrupo);
    let templates = await gruposServices.getGruposDestinatarios(paramsGrupo)
    console.log(templates)

    let objMessage = helpServices.getMessage(templates.data)
      console.log('objMessage', objMessage)
      
      if (objMessage.code === 200) {
        console.log('entroo OK')
        if (templates.data.data.rows.length > 0){
          for (let i = 0; i < templates.data.data.rows.length; i++){
            let objetoSend = {
              idGrupo: templates.data.data.rows[i].idGrupo,
              filtro: 'fecha',
              nombreBusqueda: '',
              pagina: 1,
              size: 5
            }
            let params = helpServices.formatUrlParams(objetoSend)
            let det = await gruposServices.getDestinatariosXGrupo(params)
            console.log('det', det);

            let objMessage2 = helpServices.getMessage(det.data)
            console.log('objMessage', objMessage)
            
            if (objMessage2.code === 200){
              console.log('entroo OK')
              templates.data.data.rows[i].listaDestinatarios = det.data.data.rows;
              templates.data.data.rows[i].totalDestinatarios = det.data.data.totalItems;
            } else {
              templates.data.data.rows[i].listaDestinatarios = [];
              templates.data.data.rows[i].totalDestinatarios = 0;
              mostrarMensaje(objMessage.code, objMessage.message)
            }
        
          }
          setListas(templates.data.data.rows)
          setListasGen(templates.data.data.rows)
        } else {
          setListas([])
          setListasGen([])
        }
        
        let totalPageResult = templates.data.data.totalItems / obSe.size;
        console.log(totalPageResult);
        setTotalPage(Math.ceil(totalPageResult))
        setProcessData(true)
        setTotalGrupo(templates.data.data.totalItems)
      } else {
        mostrarMensaje(objMessage.code, objMessage.message)
      }
    
    setSpiner(false)
   }
  const handlecloseAgg = (accion) => {
    console.log('ejecutaCierremodal', accion);
    if (accion) {
      refreshDestinatarios(idGrupo)
     // $(`#refreshDest_${idGrupo}`).click()
      // getDataSegmentos()
      mostrarMensaje(200, global.PROCESO_OK)
    }
    setIsOpenAg(false)
    setDestinatario({})
    setIdGrupo('')
    setHeaderAg('')
    setModalButton2Ag('')
  }
  const handleModalAgg = async (op, idGrupoSend, destinatario, tipoGrupo) => {
    console.log('idGrupo', idGrupoSend);
    console.log('destinatario', destinatario);
    console.log('tipoGrupo', tipoGrupo);
    if (op === 'C') {
      setHeaderAg('Agregar contacto')
      setModalButton2Ag('Crear')
    } else {
      setHeaderAg('Editar contacto')
      setModalButton2Ag('Editar')
      // let idDestP = '33c03b84-e2d1-4b72-bfa3-129143de6f45'
      const getDestId = await gruposServices.getDestinatarioId(destinatario.idDestinatario)
      if (getDestId.data.code === 200){
        setDestinatario(getDestId.data.data.row)
        console.log('getDestId', getDestId.data.data.row);
      } else {
        mostrarMensaje(getDestId.data.code, getDestId.data.message)
      }
    }
    setTipoGrupo(tipoGrupo)
    setOpcionLista(op)
    setIdGrupo(idGrupoSend)
    setIsOpenAg(true)
    setColorHeader2Ag('text-align-left')
  }
  const handlePageClick = (event) => {
    console.log('event.selected', event);
    if (event.nextSelectedPage !== undefined){
      setInitPage(event.nextSelectedPage)
      setPageActual(event.nextSelectedPage + 1)
    
      obSe.nombreBusqueda = nombreBusqueda;
      obSe.size = perPage;
      obSe.pagina = event.nextSelectedPage + 1;
      getDataSegmentos()
    }  
  }
  const searchByName = (event, initPageS) => {
    console.log('event', event);
    let valid = helpServices.validaNombres(event)
    if (!valid) return event.preventDefault();

    if (event.charCode === 13) {
      console.log('value', event.target.value);
      setPageActual(initPageS + 1)
      setInitPage(initPageS)
  
      obSe.nombreBusqueda = event.target.value;
      obSe.size = perPage;
      obSe.pagina = 1;
  
      getDataSegmentos()
      console.log('initPage', initPage);
      setProcessData(false)
    }
  }
  const refreshDestinatarios = (idGrupoRefresh) => {
    $(`#refreshDest_${idGrupoRefresh}`).click()
  }
  const refreshDataGrupos = () => {

    // setInitPage(initPageS)
    // setNombreBusqueda(name)
    // setPerPage(perPageS)
  
    obSe.nombreBusqueda = nombreBusqueda;
    obSe.size = perPage;
    obSe.pagina = initPage + 1;
  
    getDataSegmentos()
  }

   //  Funcion para restringir los permisos 
    const [isValidCrearSegmento, setIsValidCrearSegmento] = useState(false);
   const validarPermisos = async () => {
     try {
      const response = JSON.parse(localStorage.getItem('permisos'));
      // const response = await loginServices.ValidarToken();
       const tienePermisoCrearSegmento = response.find(
         (permiso) => permiso.nombre === "CREATE_SEGMENTS"
       );
       setIsValidCrearSegmento(tienePermisoCrearSegmento);
     } catch (error) {
       console.error(error)
     }
   }
  return (
      <div className='container-fluid' id='panel-segment'>
        <ModalSpiner opt={spiner} />
        {isOpen && <ModalSegment header={headerModal} button={buttonModal} isOpen={isOpen} handle={handleclose} mostrarMensaje={mostrarMensaje} />}
        {isOpenAg && <ModalContactListAgg tipoGrupo={tipoGrupo} opcionLista={opcionLista} idGrupo={idGrupo} destinatarioEdit={destinatarioEdit} handlecloseAgg={handlecloseAgg} isOpenAg={isOpenAg} header={headerAg} color={coloHeader2Ag} button={modalButton2Ag}/>}

        <div className='content-head-grupo row col-12 pt-3 pb-2 justify-content-betweend align-items-center pr-0'>
            <div className='col-0'><i className='fas fa-user-friends friends color-friend'></i></div>
            <div className='col-3 font-small p-0 pl-1'>Actualmente tienes {totalGrupos} segmentos </div>
            <div className='content-paginate d-flex col-4'>
              { processData &&
              <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onClick={ (event) => { handlePageClick(event) } }
                  pageRangeDisplayed={1}
                  pageCount={totalPage}
                  previousLabel="<"
                  initialPage={initPage}
                  // forcePage={initPage}
                  className="paginate-custom"
                  renderOnZeroPageCount={null}
                />
              }
            </div>
            <div className='search-campaign col-3 row ml-3'>
                <div className="row no-gutters align-items-center">
                  <div className="col col-md-12">
                    <input id="buscarGrupo" onChange={({ target }) => setNombreBusqueda(target.value)} onKeyPress={(event) => { searchByName(event, 0) }} className='ml-2 input_buscar col-9' type="text" placeholder={global.TEMPLATE_SEARCH}></input>
                  </div>
                  <div className="col-auto contacts-search">
                    <a className="btn text-dark border-0 rounded-pill disabled-a">
                      <i className="fa fa-search disabled-a grey-color"></i>
                    </a>
                  </div>
                </div>
            </div>
            {isValidCrearSegmento && (
              <div className='col-2 p-0'>
                <a onClick={() => handleModal(1)} type='button' className="btn btn-success btn-campania contacts">
                  <b>
                    <i className='fas fa-user-friends friends'></i> Crear nuevo segmento
                  </b>
                </a>
              </div>
            )}
            
            <div data-tip="Actualizar">
              <i onClick={ (event) => { refreshDataGrupos() } } className='fa fa-sync-alt fa-sync-color'></i>
              <ReactTooltip place="bottom" type="dark" effect="solid"/>
            </div>
        </div>
        <div>
          <div className='scroll container-list scrollbar group-scroll'>
            { dataListas.length > 0 ? 
            dataListas.map((lista, index) => {
              let clase;
              index % 2 === 0 ? clase = '' : clase = 'color-reglon2'
              return <Segmentos permisosSegmentos = {isValidCrearSegmento} helpServices={helpServices} refreshDataGrupos={refreshDataGrupos} refreshDestinatarios={refreshDestinatarios} setSpiner={setSpiner} mostrarMensaje={mostrarMensaje} getDataSegmentos={getDataSegmentos} handleSegment={handleSegment} key={index} handleModalAgg={handleModalAgg} clase={clase} dataLista={lista} orden={index} />
            }) :
            <>
              <div className='none-data'>
                <span> No hay data disponible</span>
              </div>
            </>
          }
          </div>
        </div>
      </div>
  )
}
export default contactSegment

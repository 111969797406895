/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable no-undef */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable space-infix-ops */
/* eslint-disable padded-blocks */
/* eslint-disable indent */
/* eslint-disable quotes */
import React, { useState, useEffect } from 'react'
import '../../src/scss/_contact.scss'
import List from './contacts/contactLists'
import Segment from './contacts/contactSegments'
import Toast from '../components/util/Toast'
import loginServices from '../services/login'
import $ from 'jquery'
const Contact = () => {
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  const [opcion, setOpcion] = useState()
  useEffect(() => {
    validarPermisos();
  }, [])

  const handleSegment = (op) => {
    setOpcion(op)
  }
  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000);
  }

 
  //  Funcion para restringir los permisos 
  
  const [isValidConsultaListas, setIsValidConsultaListas] = useState(false);
  const [isValidConsultaSegmentos, setIsValidConsultaSegmentos] = useState(false);

  const validarPermisos = async () => {
    try {
      const response = JSON.parse(localStorage.getItem('permisos'));
      // const response = await loginServices.ValidarToken();
      
      const tienePermisoverContacto = response.find(
        (permiso) => permiso.nombre === "VIEW_SEGMENTS" || permiso.nombre === "VIEW_LISTS"
      );
      if (!tienePermisoverContacto) {
         window.location.href = global.ROUTE_HOME;
      }
      const tienePermisoConsultaListas = response.find(
        (permiso) => permiso.nombre === "VIEW_LISTS"
      );
      setIsValidConsultaListas(tienePermisoConsultaListas);
      const tienePermisoConsultaSegmentos = response.find(
        (permiso) => permiso.nombre === "VIEW_SEGMENTS"
      );
      setIsValidConsultaSegmentos(tienePermisoConsultaSegmentos);

      if (tienePermisoConsultaListas) {
        localStorage.setItem('tienePermiso', 'true')
        setOpcion(1); // Inicializar el primer componente si tiene permiso para consultar listas
      } else if (tienePermisoConsultaSegmentos) {
        localStorage.setItem('tienePermiso', 'true')
        setOpcion(2); // Inicializar el primer componente si tiene permiso para consultar segmentos
      } else {
        localStorage.setItem('tienePermiso', 'false')
        window.location.href = global.ROUTE_DASHBOARD;
      }
     
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <div className='container-fluid'>
      {isOpenToast && <Toast message={messageToast} codigo={codigoToast}/>}
      <div className='row col-12 align-items-center justify-content-center pb-2 content-tab-grupos '>
        {isValidConsultaListas && (
            <div className='col-6 text-center'>
            <div type='button' onClick={() => handleSegment(1)}>Listas
              <div className= {opcion === 2 ? 'd-none' : 'success-panel'} id='panel-sub'>
                <hr className='sub-color'></hr>
              </div>
            </div>
          </div>
        )}
        {isValidConsultaSegmentos && (
          <div className='col-6 text-center'>
            <div type='button' onClick={() => handleSegment(2)}>Segmentos
              <div className= {opcion === 1 ? 'd-none' : 'success-panel'} id='panel-sub2'>
                <hr className='sub-color sub2'></hr>
              </div>
            </div>
          </div>
          
        )}
        
      </div>
      { opcion === 1 ? <List mostrarMensaje={mostrarMensaje} handleSegment={handleSegment} /> : <Segment mostrarMensaje={mostrarMensaje} handleSegment={handleSegment} />}
    </div>

  )
}
export default Contact

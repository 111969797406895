/* eslint-disable quotes */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import axios from 'axios'

const getReportGeneral = async info => {
  try {
    const headers = { 'Content-Type': 'application/json' }
    const loggedUser = JSON.parse(window.localStorage.getItem('loggedUser'))
    headers.Authorization = 'Bearer ' + window.localStorage.getItem('tokenValidado')
    headers.Canal = global.CANAL
    const requestOptions = {
      method: 'GET',
      headers
    }
    const data = await fetch(global.SERVICEREPORTGENERAL + loggedUser.idUsuario + '?fechaDesde=' + info.fechaInicio + ' 00:00:00' + '&fechaHasta=' + info.fechaFin + ' 23:59:59', requestOptions)
    const items = await data.json()
    if (items.code === 200) {
      global.LABEL_INPUT_ERROR = global.PROCESO_OK
      global.CODEERROR = items.code
    } else {
      global.LABEL_INPUT_ERROR = items.message
      global.CODEERROR = items.code
    }
    const response = {
      data: items,
      header: global.HEADER,
      body: global.BODY,
      url: global.REDIRECT_URL,
      error: global.ERROR
    }
    return response
  } catch {
    global.LABEL_INPUT_ERROR = 'No se pudo consutlar el reporte general, intente en unos momentos'
    global.CODEERROR = global.CODEERRORSET
  }
}
const getReportCamp = async info => {
  try {
    const headers = { 'Content-Type': 'application/json' }
    const loggedUser = JSON.parse(window.localStorage.getItem('loggedUser'))
    headers.Authorization = 'Bearer ' + window.localStorage.getItem('tokenValidado')
    headers.Canal = global.CANAL
    const requestOptions = {
      method: 'GET',
      headers
    }
    const data = await fetch(global.SERVICEREPORTCAMP + loggedUser.idUsuario, requestOptions)
    const items = await data.json()
    if (items.code === 200) {
      global.LABEL_INPUT_ERROR = global.PROCESO_OK
      global.CODEERROR = items.code
    } else {
      global.LABEL_INPUT_ERROR = items.message
      global.CODEERROR = items.code
    }
    const response = {
      data: items,
      header: global.HEADER,
      body: global.BODY,
      url: global.REDIRECT_URL,
      error: global.ERROR
    }
    return response
  } catch {
    global.LABEL_INPUT_ERROR = 'No se pudo consutlar el historial campaña, intente en unos momentos'
    global.CODEERROR = global.CODEERRORSET
  }
}
const getReporteDownloadPers = async (params) => {
  try {
    const headers = { 'Content-Type': 'application/json' }
    const loggedUser = JSON.parse(window.localStorage.getItem('loggedUser'))
    headers.Authorization = 'Bearer ' + window.localStorage.getItem('tokenValidado')
    headers.Canal = global.CANAL
    // const urlPrueba = 'https://d8d913s460fub.cloudfront.net/videoserver/cat-test-video-320x240.mp4'
    const urlSend = `${global.GET_REPORTE_DOWNLOAD}?idCampanaReporte=${params.idCampanaReporte}&userName=${params.userName}`
    let dataSend
   await axios({
      url: urlSend,
      method: 'GET',
      headers,
      responseType: 'blob' // importante
    }).then((response) => {
      dataSend = response.data
      global.LABEL_INPUT_ERROR = global.PROCESO_OK
      global.CODEERROR = 200
   }).catch((error) => {
      global.LABEL_INPUT_ERROR = error.message
      global.CODEERROR = error.code
    })
    const responseSend = {
      data: dataSend,
      code: global.CODEERROR
    }
    return responseSend
  } catch {
    global.LABEL_INPUT_ERROR = 'No se pudo consutlar el historial campaña, intente en unos momentos'
    global.CODEERROR = global.CODEERRORSET
  }
}

const getPDFCampaingn = async info => {
  try {
    const headers = { 'Content-Type': 'application/json' }
    const loggedUser = JSON.parse(window.localStorage.getItem('loggedUser'))
    headers.Authorization = 'Bearer ' + window.localStorage.getItem('tokenValidado')
    headers.Canal = global.CANAL

    const urlPrueba = global.SERVICEPDFGENERAL + '?user=' + loggedUser.idUsuario + '&startDate=' + info.fechaInicio + ' 00:00:00' + '&endDate=' + info.fechaFin + ' 23:59:59' + '&userName=' + info.user

    let dataSend
   await axios({
      url: urlPrueba,
      method: 'GET',
      headers,
      responseType: 'blob' // importante
    }).then((response) => {
      dataSend = response.data
      global.LABEL_INPUT_ERROR = global.PROCESO_OK
      global.CODEERROR = 200
   }).catch(error => {
      console.log(error)
      global.LABEL_INPUT_ERROR = error.message
      global.CODEERROR = error.code
    })

    const responseSend = {
      data: dataSend,
      code: global.CODEERROR
    }
    return responseSend
  } catch {
    global.LABEL_INPUT_ERROR = 'No se pudo consutlar el historial campaña, intente en unos momentos'
    global.CODEERROR = global.CODEERRORSET
  }
}
const getEXCELCampaingn = async info => {
  try {
    const idEmpresa = window.localStorage.getItem('idEmpresa')
    const headers = { 'Content-Type': 'application/json' }
    const loggedUser = JSON.parse(window.localStorage.getItem('loggedUser'))
    headers.Authorization = 'Bearer ' + window.localStorage.getItem('tokenValidado')
    headers.Canal = global.CANAL
    let campana = ''
    if (info.idCampana !== null) {
        campana = "&idCampana=" + info.idCampana
      }
    let mail = ''

    if (info.correo !== null) {
        mail = "&mail=" + info.correo
      }
    const urlPrueba = global.REPORT_LIST_NEGRA + idEmpresa + '/' + loggedUser.idUsuario + '?fechaDesde=' + info.fechaInicio + '&fechaHasta=' + info.fechaFin + mail + campana

    let dataSend
   await axios({
      url: urlPrueba,
      method: 'POST',
      headers,
      responseType: 'blob' // importante
    }).then((response) => {
      dataSend = response.data
      global.LABEL_INPUT_ERROR = global.PROCESO_OK
      global.CODEERROR = 200
   }).catch(error => {
      console.log(error)
      global.LABEL_INPUT_ERROR = error.message
      global.CODEERROR = error.code
    })

    const responseSend = {
      data: dataSend,
      code: global.CODEERROR
    }
    return responseSend
  } catch {
    global.LABEL_INPUT_ERROR = 'No se pudo consutlar el historial campaña, intente en unos momentos'
    global.CODEERROR = global.CODEERRORSET
  }
}
const getEXCELUnificado = async info => {
  try {
    const headers = { 'Content-Type': 'application/json' }
    const loggedUser = JSON.parse(window.localStorage.getItem('loggedUser'))
    headers.Authorization = 'Bearer ' + window.localStorage.getItem('tokenValidado')
    headers.Canal = global.CANAL
    let valor = ''
    if (info.valor !== null) {
        valor = "&valor=" + info.valor
      }
    const urlPrueba = global.REPORT_UNIFICADO + loggedUser.idUsuario + '?fechaDesde=' + info.fechaInicio + '&fechaHasta=' + info.fechaFin + valor
console.log('urlPrueba' + urlPrueba)
    let dataSend
   await axios({
      url: urlPrueba,
      method: 'POST',
      headers,
      responseType: 'blob' // importante
    }).then((response) => {
      dataSend = response.data
      global.LABEL_INPUT_ERROR = global.PROCESO_OK
      global.CODEERROR = 200
   }).catch(error => {
      console.log(error)
      global.LABEL_INPUT_ERROR = error.message
      global.CODEERROR = error.code
    })

    const responseSend = {
      data: dataSend,
      code: global.CODEERROR
    }
    return responseSend
  } catch {
    global.LABEL_INPUT_ERROR = 'No se pudo consutlar el historial campaña, intente en unos momentos'
    global.CODEERROR = global.CODEERRORSET
  }
}
const getListaGris = async info => {
  try {
    const headers = { 'Content-Type': 'application/json' }
    const idEmpresa = window.localStorage.getItem('idEmpresa')
    const loggedUser = JSON.parse(window.localStorage.getItem('loggedUser'))
    headers.Authorization = 'Bearer ' + window.localStorage.getItem('tokenValidado')
    headers.Canal = global.CANAL
    const requestOptions = {
      method: 'GET',
      headers
    }
    if (info.fechaInicio === '' && info.fechaFin === '') {
      info.fechaInicio = ''
      info.fechaFin = ''
    } else {
      info.fechaInicio = info.fechaInicio + ' 00:00:00'
      info.fechaFin = info.fechaFin + ' 23:59:59'
    }
    const data = await fetch(`${global.LISTA_GRIS}${idEmpresa}/${loggedUser.idUsuario}?fechaDesde=${info.fechaInicio}&fechaHasta=${info.fechaFin}&page=${info.obSe.pagina}&size=${info.obSe.size}&mail=${info.correo}`, requestOptions)
    const items = await data.json()
    if (items.code === 200) {
      global.LABEL_INPUT_ERROR = global.PROCESO_OK
      global.CODEERROR = items.code
    } else {
      global.LABEL_INPUT_ERROR = items.message
      global.CODEERROR = items.code
    }
    const response = {
      data: items,
      header: global.HEADER,
      body: global.BODY,
      url: global.REDIRECT_URL,
      error: global.ERROR
    }
    return response
  } catch {
    global.LABEL_INPUT_ERROR = 'No se pudo consutlar el reporte general, intente en unos momentos'
    global.CODEERROR = global.CODEERRORSET
  }
}
const getListaNegra = async info => {
  try {
    const headers = { 'Content-Type': 'application/json' }
    const loggedUser = JSON.parse(window.localStorage.getItem('loggedUser'))
    headers.Authorization = 'Bearer ' + window.localStorage.getItem('tokenValidado')
    headers.Canal = global.CANAL
    const idEmpresa = window.localStorage.getItem('idEmpresa')
    const requestOptions = {
      method: 'GET',
      headers
    }
    if (info.fechaInicio === '' && info.fechaFin === '') {
      info.fechaInicio = ''
      info.fechaFin = ''
    }
    let campana = ''
    if (info.idCampana !== null) {
        campana = "&idCampana=" + info.idCampana
      }
    let mail = ''
    console.log('mail' + mail)
    if (info.correo !== null) {
      if (info.correo !== "") {
        console.log(3)
        mail = "&mail=" + info.correo
      }
    }
    const data = await fetch(global.LISTA_NEGRA + idEmpresa + '/' + loggedUser.idUsuario + '?fechaDesde=' + info.fechaInicio + '&fechaHasta=' + info.fechaFin + '&page=' + info.obSe.pagina + '&size=' + info.obSe.size + mail + campana, requestOptions)
    const items = await data.json()
    console.log(items)
    if (items.code === 200) {
      global.LABEL_INPUT_ERROR = global.PROCESO_OK
      global.CODEERROR = items.code
    } else {
      global.LABEL_INPUT_ERROR = items.message
      global.CODEERROR = items.code
    }
    const response = {
      data: items,
      header: global.HEADER,
      body: global.BODY,
      url: global.REDIRECT_URL,
      error: global.ERROR
    }
    return response
  } catch {
    global.LABEL_INPUT_ERROR = 'No se pudo consutlar el reporte general, intente en unos momentos'
    global.CODEERROR = global.CODEERRORSET
  }
}
const getReporteUnificado = async info => {
  try {
    const headers = { 'Content-Type': 'application/json' }
    const loggedUser = JSON.parse(window.localStorage.getItem('loggedUser'))
    headers.Authorization = 'Bearer ' + window.localStorage.getItem('tokenValidado')
    headers.Canal = global.CANAL
    const requestOptions = {
      method: 'GET',
      headers
    }
    if (info.fechaInicio === '' && info.fechaFin === '') {
      info.fechaInicio = ''
      info.fechaFin = ''
    }
    const data = await fetch(global.REPORTE_UNIFICADO + loggedUser.idUsuario + '?fechaDesde=' + info.fechaInicio + '&fechaHasta=' + info.fechaFin + '&page=' + info.obSe.pagina + '&size=' + info.obSe.size + '&valor=' + info.valor, requestOptions)
    const items = await data.json()
    if (items.code === 200) {
      global.LABEL_INPUT_ERROR = global.PROCESO_OK
      global.CODEERROR = items.code
    } else {
      global.LABEL_INPUT_ERROR = items.message
      global.CODEERROR = items.code
    }
    const response = {
      data: items,
      header: global.HEADER,
      message: global.LABEL_INPUT_ERROR,
      body: global.BODY,
      url: global.REDIRECT_URL,
      error: global.ERROR
    }
    return response
  } catch {
    global.LABEL_INPUT_ERROR = 'No se pudo consutlar el reporte general, intente en unos momentos'
    global.CODEERROR = global.CODEERRORSET
  }
}
const getReporteHistorico = async info => {
  try {
    const headers = { 'Content-Type': 'application/json' }
    const loggedUser = JSON.parse(window.localStorage.getItem('loggedUser'))
    headers.Authorization = 'Bearer ' + window.localStorage.getItem('tokenValidado')
    headers.Canal = global.CANAL
    const requestOptions = {
      method: 'GET',
      headers
    }
    if (info.fechaInicio === '' && info.fechaFin === '') {
      info.fechaInicio = ''
      info.fechaFin = ''
    }
    const data = await fetch(global.REPORTE_HISTORICO + loggedUser.idUsuario + '?valor=' + info.ticket + '&tipo=' + info.tipo + '&fechaDesde=' + info.fechaInicio + '&fechaHasta=' + info.fechaFin, requestOptions)
    const items = await data.json()
    console.log(items)
    if (items.code === 200) {
      global.LABEL_INPUT_ERROR = global.PROCESO_OK
      global.CODEERROR = items.code
    } else {
      global.LABEL_INPUT_ERROR = items.message
      global.CODEERROR = items.code
    }
    const response = {
      data: items,
      header: global.HEADER,
      message: global.LABEL_INPUT_ERROR,
      body: global.BODY,
      url: global.REDIRECT_URL,
      error: global.ERROR
    }
    return response
  } catch {
    global.LABEL_INPUT_ERROR = 'No se pudo consutlar el reporte general, intente en unos momentos'
    global.CODEERROR = global.CODEERRORSET
  }
}
const getReporteDownloadListaNegra = async (params) => {
  try {
    const headers = { 'Content-Type': 'application/json' }
    const loggedUser = JSON.parse(window.localStorage.getItem('loggedUser'))
    headers.Authorization = 'Bearer ' + window.localStorage.getItem('tokenValidado')
    headers.Canal = global.CANAL
    // const urlPrueba = 'https://d8d913s460fub.cloudfront.net/videoserver/cat-test-video-320x240.mp4'
    const urlSend = `${global.GET_REPORTE_DOWNLOAD_LISTA_NEGRA}?userName=${params.userName}&idUsuario=${loggedUser.idUsuario}&fechaDesde=${params.fechaInicio} 00:00:00&fechaHasta=${params.fechaFin} 23:59:59`
    let dataSend
   await axios({
      url: urlSend,
      method: 'GET',
      headers,
      responseType: 'blob' // importante
    }).then((response) => {
      console.log('response', response.data)
      console.log('response', response)
      dataSend = response.data
      global.LABEL_INPUT_ERROR = global.PROCESO_OK
      global.CODEERROR = 200
   }).catch((error) => {
      console.log(error)
      global.LABEL_INPUT_ERROR = error.message
      global.CODEERROR = error.code
    })
    const responseSend = {
      data: dataSend,
      code: global.CODEERROR
    }
    return responseSend
  } catch {
    global.LABEL_INPUT_ERROR = 'No se pudo consutlar el historial campaña, intente en unos momentos'
    global.CODEERROR = global.CODEERRORSET
  }
}
const getReporteDownloadListaGris = async (params) => {
  try {
    const headers = { 'Content-Type': 'application/json' }
    const loggedUser = JSON.parse(window.localStorage.getItem('loggedUser'))
    headers.Authorization = 'Bearer ' + window.localStorage.getItem('tokenValidado')
    headers.Canal = global.CANAL
    // const urlPrueba = 'https://d8d913s460fub.cloudfront.net/videoserver/cat-test-video-320x240.mp4'
    const urlSend = `${global.GET_REPORTE_DOWNLOAD_LISTA_GRIS}?userName=${params.userName}&dsUsuario=${loggedUser.idUsuario}&fechaInicio=${params.fechaInicio} 00:00:00&fechaFin=${params.fechaFin} 23:59:59`
    let dataSend
   await axios({
      url: urlSend,
      method: 'GET',
      headers,
      responseType: 'blob' // importante
    }).then((response) => {
      console.log('response', response.data)
      console.log('response', response)
      dataSend = response.data
      global.LABEL_INPUT_ERROR = global.PROCESO_OK
      global.CODEERROR = 200
   }).catch((error) => {
      console.log(error)
      global.LABEL_INPUT_ERROR = error.message
      global.CODEERROR = error.code
    })
    const responseSend = {
      data: dataSend,
      code: global.CODEERROR
    }
    return responseSend
  } catch {
    global.LABEL_INPUT_ERROR = 'No se pudo consutlar el historial campaña, intente en unos momentos'
    global.CODEERROR = global.CODEERRORSET
  }
}
const deleteListaNegra = async info => {
  try {
    const headers = { 'Content-Type': 'application/json' }
    const loggedUser = JSON.parse(window.localStorage.getItem('loggedUser'))
    headers.Authorization = 'Bearer ' + window.localStorage.getItem('tokenValidado')
    headers.Canal = global.CANAL
    const requestOptions = {
      method: 'DELETE',
      headers
    }
    const data = await fetch(global.DELETE_LISTA_NEGRA + info.id, requestOptions)
    const items = await data.json()
    console.log(items)
    if (items.code === 200) {
      global.LABEL_INPUT_ERROR = global.PROCESO_OK
      global.CODEERROR = items.code
    } else {
      global.LABEL_INPUT_ERROR = items.message
      global.CODEERROR = items.code
    }
    const response = {
      data: items,
      header: global.HEADER,
      body: global.BODY,
      url: global.REDIRECT_URL,
      error: global.ERROR
    }
    return response
  } catch {
    global.LABEL_INPUT_ERROR = 'No se pudo consutlar el reporte general, intente en unos momentos'
    global.CODEERROR = global.CODEERRORSET
  }
}
export default {
  getReportGeneral,
  getReportCamp,
  getPDFCampaingn,
  getEXCELCampaingn,
  getEXCELUnificado,
  getReporteDownloadPers,
  getListaNegra,
  getListaGris,
  getReporteDownloadListaNegra,
  getReporteDownloadListaGris,
  deleteListaNegra,
  getReporteUnificado,
  getReporteHistorico
}

/* eslint-disable indent */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import '../../scss/_campaaign.scss'
import ReactPaginate from 'react-paginate'
import date from '../../services/global'
import $ from 'jquery'
import Toast from '../../components/util/Toast'
import serviceReport from '../../services/report'
import ReactTooltip from 'react-tooltip'
import ModalSpiner from '../../components/util/ModalSpiner'
import helpServices from '../../services/helper'
const ListaGris = () => {
  const [fechaInicio, setFechaInicio] = useState('')
  const [fechaFin, setFechaFin] = useState('')
  const [correo, setCorreo] = useState('')
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  const [spiner, setSpiner] = useState(false)
  const [dataReport, setDataReport] = useState([
  ])
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const d = date.getDate()

  const [processData, setProcessData] = useState(false)
  const [initPage, setInitPage] = useState(0)
  const [pageActual, setPageActual] = useState(1)
  const [perPage, setPerPage] = useState(5)
  const [nombreBusqueda, setNombreBusqueda] = useState('')
  const [totalPage, setTotalPage] = useState(0)
  const obSe = {
    filtro: 'fecha',
    nombreBusqueda: '',
    pagina: 1,
    size: 5
  }

  const handleGenerar = () => {
    if ((fechaInicio === '' && fechaFin === '' && correo === '') || ('' + fechaInicio === 'null' && '' + fechaFin === 'null' && correo === 'null')) {
      global.LABEL_INPUT_ERROR = global.CAMPAIGN_FECHA
      $('#fechaInicio').addClass('error')
      $('#fechaFin').addClass('error')
      $('#correo').addClass('error') 
      mostrarMensaje('400', global.LABEL_INPUT_ERROR + ' o correo')
    } else if (fechaInicio > fechaFin) {
      $('#fechaInicio').addClass('error')
      mostrarMensaje('400', 'Su rango de fecha es inválido')
    } else {
      $('#fechaInicio').removeClass('error')
      $('#fechaFin').removeClass('error')
      $('#correo').removeClass('error') 
      setSpiner(true)
      getDataReport()
    }
  }
  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000)
  }
  const getDataReport = async () => {
    console.log('holis')
    setSpiner(true)
    console.log('correo', correo)
    const serviceRe = await serviceReport.getListaGris({ fechaInicio, fechaFin, obSe, correo })
    console.log('serviceRe', serviceRe)
    let objMessage = helpServices.getMessage(serviceRe.data)
    console.log('objMessage', objMessage)
    setSpiner(false)
    if (serviceRe.data.code === 200) {
      if (serviceRe.data.data.totalItems > 0) { 
        $('#menu-search').removeClass() 
        $('#menu-search').addClass('success-panel col-12') 
      } else { 
        $('#menu-search').removeClass() 
        $('#menu-search').addClass('no-success-panel') 
      } 
      setProcessData(true)
      setDataReport(serviceRe.data.data.rows)
      setTotal(serviceRe.data.data.totalItems)
      setTotalPage(Math.ceil(serviceRe.data.data.totalItems / obSe.size))
    } else {
      mostrarMensaje(objMessage.code, objMessage.message)
    }
  }
  const handlePageClick = (event) => {
    console.log('event.selected', event)
    if (event.nextSelectedPage !== undefined) {
      setInitPage(event.nextSelectedPage)
      setPageActual(event.nextSelectedPage + 1)
      obSe.nombreBusqueda = nombreBusqueda
      obSe.size = perPage
      obSe.pagina = event.nextSelectedPage + 1
      getDataReport()
    }
  }

  const searchPerPage = (initPageS, value) => {
    setInitPage(initPageS)
    setPageActual(Number(initPageS))
    setProcessData(false)

    obSe.size = Number(value)
    obSe.nombreBusqueda = nombreBusqueda
    obSe.pagina = 1

    getDataReport()
    console.log('initPageS', initPageS)
  }

  const searchByName = (event, initPageS) => {
    console.log('event', event)
    let valid = helpServices.validaNombres(event)
    if (!valid) return event.preventDefault()
    if (event.charCode === 13) {
      console.log('value', event.target.value)
      setPageActual(initPageS + 1)
      setInitPage(initPageS)

      obSe.nombreBusqueda = event.target.value
      obSe.size = perPage
      obSe.pagina = 1

      getDataReport()
      console.log('initPage', initPage)
      setProcessData(false)
    }
  }
  return (
        <div className='container-fluid p-0'>
          <ModalSpiner opt={spiner} />
          {isOpenToast && <Toast message={messageToast} codigo={codigoToast} />}

            <div className="mb-12 panel-group pt-3 pb-3 header-report mb-4">
                <div className='d-flex'>
                    <div className='col-5 p-0'>
                        <span className='campaing-tittle'><b>Lista Gris</b></span>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='form-group col-md-3'>
                    <span className="text-input name-input every-label" id='error_fecha'></span>
                    <label className='grey-color'>Fecha de inicio</label>
                    <input id='fechaInicio'
                          value={fechaInicio}
                          type="date"
                          max={d}
                          onChange={({ target }) => setFechaInicio(target.value)}
                          className="form-control item"></input>
                </div>
                <div className='form-group col-md-3'>
                    <span className="text-input name-input every-label" id='error_fecha'></span>
                    <label className='grey-color'>Fecha de finalización</label>
                    <input id='fechaFin'
                          value={fechaFin}
                          type="date"
                          max={d}
                          onChange={({ target }) => setFechaFin(target.value)}
                          className="form-control item"></input>
                </div>
                <div className='form-group col-md-3'>
                    <span className="text-input name-input every-label" id='error_correo'></span>
                    <label className='grey-color'>Correo</label>
                    <input id='correo'
                          value={correo}
                          type="text"
                          placeholder='correo'
                          max={d}
                          onChange={({ target }) => setCorreo(target.value)}
                          className="form-control item"></input>
                </div>
                <div className='form-group col-md-3 button-generate report-listas'>
                  <a type='button' onClick={() => { handleGenerar() }} className="btn btn-success btn-campania contacts report-btn">
                    <b>
                     Generar
                    </b>
                  </a>
                </div>

            </div>
            <div className="panel-group pt-2 pb-2 header-report mb-3 data-report">
                <div className='d-flex align-items-center'>
                    <div className='col-5 p-0'>
                        <span className='campaing-tittle'><b>Historial de Reportes</b></span>
                    </div>
                    <div className='col-7 show-cant'>
                        <i className='fa fa-chart-pie'></i> <span>Actualmente tienes { total } reportes </span>
                    </div>
                </div>
            </div>
            <div className="mb-12 panel-group data-report listas-report">
            <div className='no-success-panel' id='menu-search' > 
                <div className='search-table search-tables row'> 
                  <div className='col-md-4 search-perPage'> 
                    <label className='mr-2 font-sizze'> Mostrando: </label> 
                    <select onChange={({ target }) => { setPerPage(target.value); searchPerPage(0, target.value) } } 
                          value={perPage} 
                          id='selectPerPage' 
                          className="form-control item styled-select mr-2" > 
                        <option value="5" >5</option> 
                        <option value="10" >10</option> 
                        <option value="15" >15</option> 
                    </select> <label className='font-sizze'>Registros </label>
                  </div>
                  <div className='search-name col-md-8'> 
                    <div className="row no-gutters align-items-center"> 
                      <div className="col col-md-12"> 
                        <input onKeyPress={(event) => { searchByName(event, 0) }} onChange={({ target }) => setNombreBusqueda(target.value)} value={nombreBusqueda} type='text' className='input_buscar pl-3 font-sizze' placeholder='Buscar por Nombre'></input> 
                      </div> 
                      <div className="col-auto contacts-search"> 
                        <a className="btn text-dark border-0 rounded-pill disabled-a"> 
                          <i className="fa fa-search disabled-a grey-color"></i> 
                        </a> 
                      </div> 
                    </div>
                </div>
              </div>
            </div>
            <table id='prog-table-report' className="table">
            <thead>
              <tr>
                <th>Correo</th>
                <th>Ticket</th>
                <th>Fecha Registro</th>
                {/* <th>Opciones</th> */}
              </tr>
            </thead>
            <tbody id='tbody'>
            {dataReport.length > 0
            ? dataReport.map((val) => {
              return (<React.Fragment key={val.id}>
                                  <tr>
                                    <td className='dataTables_empty'>
                                      {val.mail.length > 25
                                        ? <div className='ajust-text' data-tip={`<p class="content-tool"> ${val.mail.toLowerCase()}</p>`} data-html={true}>
                                        {val.mail.toLowerCase()}
                                        <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                        </div>

                                        : <>
                                        <span>{val.mail.toLowerCase()}</span>
                                        </>
                                      }
                                    </td>
                                    <td>{val.idTicket}</td>

                                    <td>{val.fechaRegistro}</td>
                                    {/* <td >
                                    <div className="dropdown">
                                      <a type="text" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <i className="fa fa-ellipsis-v fa-color"></i>
                                      </a>
                                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <div onClick={() => { dowlandReport(val.idCampanaReporte) }} >
                                            <i className='ml-2 fa fa-download notify-color'></i>
                                            <span className='ml-3 detail-drop-down'>Descargar</span>
                                          </div>
                                      </div>
                                    </div>
                                       </td> */}
                                  </tr>
                                  </React.Fragment>
              )
            }
            )
            : <tr>
              <td colSpan={3}>
                  <span>No hay información</span>
              </td>
            </tr>
          }
            </tbody>
            </table>
            <div className='content-paginate d-flex mb-5'>
              { (processData && totalPage > 0) &&
              <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onClick={ (event) => { handlePageClick(event) } }
                  pageRangeDisplayed={1}
                  pageCount={totalPage}
                  previousLabel="<"
                  initialPage={initPage}
                  // forcePage={initPage}
                  className="paginate-custom"
                  renderOnZeroPageCount={null}
                />
              }
            </div>
            </div>
        </div>
  )
}

export default ListaGris

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import Word from '../../../../assets/img/word.png'
import Excel from '../../../../assets/img/Excel.png'
import Ppt from '../../../../assets/img/ppt.png'
import Rar from '../../../../assets/img/rar.png'
import ModalSpiner from '../../../../components/util/ModalSpiner'
import templateServices from '../../../../services/templates'
import ModalDeleteAdjunto from '../../../../components/util/ModalDeleteAdjunto'
import Toast from '../../../../components/util/Toast'

import $ from 'jquery'

export default function Dropzone (props) {
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  const [isOpenC, setIsOpenC] = useState(false)
  const [idFile, setIdFile] = useState('')
  const [option, setOption] = useState('')
  const [idRemove, setIdRemove] = useState('')
  const [handleC, setHandleC] = useState()
  const [bodyC, setBodyC] = useState('')
  const [modalButtonC, setModalButtonC] = useState('')
  const [coloHeaderC, setColorHeaderC] = useState('')
  const [modalButtonCerrarC, setModalButtonCerrarC] = useState('')
  const [selectedFiles, setSelectedFiles] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [validFiles, setValidFiles] = useState([])
  const [savedFiles, setSavedFiles] = useState([])
  const [unsupportedFiles, setUnsupportedFiles] = useState([])
  const [cont, setCont] = useState(0)
  const modalImageRef = useRef()
  const modalRef = useRef()
  const fileInputRef = useRef()
  const uploadModalRef = useRef()
  const uploadRef = useRef()
  const progressRef = useRef()
  const [spiner, setSpiner] = useState(false)
  const [useMegas, setUseMgeas] = useState('0')
  let contMgeas = 0

  useEffect(() => {
    const filteredArray = selectedFiles.reduce((file, current) => {
      const x = file.find(item => item.name === current.name)
      if (!x) {
        return file.concat([current])
      } else {
        return file
      }
    }, [])
    setValidFiles([...filteredArray])
    if (localStorage.getItem('idPlantilla')) {
      searchFile()
      localStorage.setItem('validFilesTrue', 'false')
    } else {
      if (localStorage.getItem('validFilesTrue') === 'true') {
        // searchFileTemp()
      }
    }
  }, [selectedFiles])

  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000)
  }

  const searchFileTemp = async () => {
    setSpiner(true)
    const userData = JSON.parse(localStorage.getItem('validFiles'))
    if (!userData) { // si existe error es porque no hay adjuntos guardados, seteamos en cero el contador
      setCont(0)
    } else {
      setSavedFiles(userData)
      setCont(userData.length)
    }
    setSpiner(false)
  }

  const searchFile = async () => {
    setSpiner(true)
    const userData = await templateServices.getFiles({})
    if (userData.error === 200) { // si existe error es porque no hay adjuntos guardados, seteamos en cero el contador
      setSavedFiles(userData.data.data.row)
      setCont(userData.data.data.row.length)
    } else {
      setCont(0)
    }
    setSpiner(false)
  }

  const dragOver = (e) => {
    e.preventDefault()
  }

  const dragEnter = (e) => {
    e.preventDefault()
  }

  const dragLeave = (e) => {
    e.preventDefault()
  }

  const fileDrop = (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files.length) {
      handleFiles(files)
    }
  }

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        const sizeFile = fileSize(files[i].size).split(' ')
        if (sizeFile[1] === 'MB' || sizeFile[1] === 'GB' || sizeFile[1] === 'TB') {
          if (sizeFile[0] > 7) {
            mostrarMensaje('400', 'El archivo seleccionado excede los 7 megas')
            return false
          }
        }
        let cantFiles = 0
        if (cont === 0) {
          cantFiles = validFiles.length + i
        } else {
          cantFiles = cont + i
        }
        setSelectedFiles(prevArray => [...prevArray, files[i]])
        const reader = new FileReader()
        reader.readAsDataURL(files[i])
        reader.onload = function (e) {
          if (fileTypeExtImg(files[i])) {
            $('#img-' + (cantFiles)).append('<img class="img-fluid-resp"  src="' + e.target.result + '" />')
          } else if (fileTypeExtOffice(files[i])) {
            const split = files[i].name.split('.')
            if (split[1] === 'docx' || split[1] === 'doc') $('#img-' + (cantFiles)).append('<img class="img-fluid-resp"  src="' + Word + '" />')
            if (split[1] === 'xls' || split[1] === 'xlsx') $('#img-' + (cantFiles)).append('<img class="img-fluid-resp"  src="' + Excel + '" />')
            if (split[1] === 'csv' || split[1] === 'CSV') $('#img-' + (cantFiles)).append('<img class="img-fluid-resp"  src="' + Excel + '" />')
            if (split[1] === 'ppt' || split[1] === 'pptx') $('#img-' + (cantFiles)).append('<img class="img-fluid-resp"  src="' + Ppt + '" />')
            if (split[1] === 'rar' || split[1] === 'zip') $('#img-' + (cantFiles)).append('<img class="img-fluid-resp"  src="' + Rar + '" />')
          } else {
            $('#img-' + (cantFiles)).append('<iframe class="img-fluid-resp-fr" src="' + e.target.result + '"></iframe>')
          }
        }
      } else {
        mostrarMensaje('400', 'Tipo de archivo no permitido')
      }
    }
    setUseMgeas(Math.round(contMgeas).toString())
  }

  const fileSize = (size) => {
    // 7 megas por adjuntos
    if (size === 0) return '0 Bytes'
    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    const i = Math.floor(Math.log(size) / Math.log(k))
    const sizeFile = parseFloat((size / Math.pow(k, i)).toFixed(2))
    if (sizes[i] === 'KB') {
      contMgeas = (contMgeas + (sizeFile / k))
    } else if (sizes[i] === 'MB') {
      contMgeas = contMgeas + sizeFile
    }
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
  }

  const fileType = (fileName) => {
    return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName
  }

  const validateFile = (file) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/plain', 'text/csv', 'application/msword', 'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-powerpoint', 'application/x-zip-compressed']
    if (validTypes.indexOf(file.type) === -1) {
      return false
    }
    return true
  }

  const fileTypeExtImg = (file) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon']
    if (validTypes.indexOf(file.type) === -1) {
      return false
    }
    return true
  }

  const fileTypeExtOffice = (file) => {
    const validTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword', 'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-powerpoint', 'application/x-zip-compressed', 'text/csv']
    if (validTypes.indexOf(file.type) === -1) {
      return false
    }
    return true
  }

  const removeFile = (name) => {
    const validFileIndex = validFiles.findIndex(e => e.name === name)
    validFiles.splice(validFileIndex, 1)
    // update validFiles array
    setValidFiles([...validFiles])
    const selectedFileIndex = selectedFiles.findIndex(e => e.name === name)
    selectedFiles.splice(selectedFileIndex, 1)
    // update selectedFiles array
    setSelectedFiles([...selectedFiles])
    const unsupportedFileIndex = unsupportedFiles.findIndex(e => e.name === name)
    if (unsupportedFileIndex !== -1) {
      unsupportedFiles.splice(unsupportedFileIndex, 1)
      // update unsupportedFiles array
      setUnsupportedFiles([...unsupportedFiles])
    }
  }
  const openImageModal = (file) => {
    $('.modal-doc').html('')
    const reader = new FileReader()
    modalRef.current.style.display = 'block'
    reader.readAsDataURL(file)
    reader.onload = function (e) {
      if (fileTypeExtImg(file)) {
        modalImageRef.current.style.backgroundImage = `url(${e.target.result})`
      } else if (fileTypeExtOffice(file)) {
        const split = file.name.split('.')
        if (split[1] === 'docx' || split[1] === 'doc') $('.modal-doc').append('<iframe class="img-fluid-resp-modal" src="' + Word + '"></iframe>')
        if (split[1] === 'xls' || split[1] === 'xlsx') $('.modal-doc').append('<iframe class="img-fluid-resp-modal" src="' + Excel + '"></iframe>')
        if (split[1] === 'CSV' || split[1] === 'csv') $('.modal-doc').append('<iframe class="img-fluid-resp-modal" src="' + Excel + '"></iframe>')
        if (split[1] === 'ppt' || split[1] === 'pptx') $('.modal-doc').append('<iframe class="img-fluid-resp-modal" src="' + Ppt + '"></iframe>')
        if (split[1] === 'rar' || split[1] === 'zip') $('.modal-doc').append('<iframe class="img-fluid-resp-modal" src="' + Rar + '"></iframe>')
      } else {
        $('.modal-doc').append('<iframe class="img-fluid-resp-modal" src="' + e.target.result + '"></iframe>')
      }
    }
  }

  const openImageModalSaved = (url, typo) => {
    $('.modal-doc').html('')
    modalRef.current.style.display = 'block'
    if (typo === 'png' || typo === 'jpg' || typo === 'PNG') {
      $('.modal-doc').append('<img class="img-fluid-resp"  src="' + url + '" />')
      // modalImageRef.current.style.backgroundImage = `url(${url})`
    } else {
      if (typo === 'docx' || typo === 'doc') $('.modal-doc').append('<iframe class="img-fluid-resp-modal" src="' + Word + '"></iframe>')
      if (typo === 'xls' || typo === 'xlsx') $('.modal-doc').append('<iframe class="img-fluid-resp-modal" src="' + Excel + '"></iframe>')
      if (typo === 'ppt' || typo === 'pptx') $('.modal-doc').append('<iframe class="img-fluid-resp-modal" src="' + Ppt + '"></iframe>')
      if (typo === 'rar' || typo === 'zip') $('.modal-doc').append('<iframe class="img-fluid-resp-modal" src="' + Rar + '"></iframe>')
      if (typo === 'CSV' || typo === 'csv') $('.modal-doc').append('<iframe class="img-fluid-resp-modal" src="' + Excel + '"></iframe>')
      if (typo === 'pdf' || typo === 'PDF' || typo === 'TXT' || typo === 'txt') $('.modal-doc').append('<iframe class="img-fluid-resp-modal" src="' + url + '"></iframe>')
    }
  }
  const closeModal = () => {
    modalRef.current.style.display = 'none'
    modalImageRef.current.style.backgroundImage = 'none'
  }
  const uploadFiles = async () => {
    console.log('validFiles', validFiles)
    for (let i = 0; i < validFiles.length; i++) {
      let userData
      setSpiner(true)
      if (props.option === 'plantilla') { userData = await templateServices.saveFile({ file: validFiles[i] }) }
      if (props.option === 'william') { alert('aquí va la función') }
      if (props.option === 'lady') { alert('aquí va la función') }
      setSpiner(false)
      if (userData.error === 200) {
        mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
      } else {
        mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
      }
      setSpiner(false)
    }
    window.location.href = global.ASDD_FILES
  }
  const uploadFilesOne = async (index) => {
    setSpiner(true)
    const userData = await templateServices.saveFile({ file: validFiles[index] })
    if (userData.error === 200) {
      mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
    } else {
      mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
    }
    setSpiner(false)
  }
  const fileInputClicked = () => {
    fileInputRef.current.click()
  }
  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files)
      const Filelist = {
        name: 'j',
        size: '',
        type: 'image/jpeg'
      }
    }
  }
  const closeUploadModal = () => {
    uploadModalRef.current.style.display = 'none'
  }
  const message = (tipo, message) => {
    $('.' + tipo + '-alert').html('')
    $('.' + tipo + '-alert').append(message)
    $('.' + tipo).removeClass(tipo + '-alert-hide')
    $('.' + tipo).addClass(tipo + '-alert-show')
    setTimeout(function () {
      $('.' + tipo + '-alert').html('')
      $('.' + tipo).removeClass(tipo + '-alert-show')
      $('.' + tipo).addClass(tipo + '-alert-hide')
    }, 5000)
  }
  const handleClose = () => () => {
    setIsOpenC(false)
  }
  const removeFileSaved = (id, idDelete) => {
    setIsOpenC(true)
    setOption(props.option)
    setColorHeaderC(global.MODAL_HEAD_DELETE_FILE)
    setBodyC(global.MODAL_DELETE_FILE)
    setModalButtonC('Aceptar')
    setModalButtonCerrarC('Cerrar')
    setHandleC(handleClose)
    setIdFile(idDelete)
    setIdRemove(id)
  }
  const removeFileMemori = (name) => {
    // $('#' + id).remove()
    const setFiles = validFiles.filter((data) => {
      return data.name !== name
    })
    setValidFiles(setFiles)

    console.log('setFiles', setFiles)
    console.log('validFiles', validFiles)
  }

  function getBase64Image (img) {
    const canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height

    const ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0)

    const dataURL = canvas.toDataURL('image/png')

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
  }

  const uploadFilesTemp = async () => {
    const bannerImage = validFiles
    const imgData = getBase64Image(bannerImage)
    localStorage.setItem('imgData', imgData)

    /* // encodeFiles
    console.log(validFiles[0])
    const decodedStringBtoA = validFiles
    const encodedStringBtoA = btoa(decodedStringBtoA)

    console.log(encodedStringBtoA)

    // Define the string
    const encodedStringAtoB = encodedStringBtoA

    // Decode the String
    const decodedStringAtoB = atob(encodedStringAtoB)

    console.log(decodedStringAtoB[0])
    setSpiner(true)
    localStorage.setItem('validFilesTrue', 'true')
    setSpiner(false)
    message('success', 'Adjuntos temporales guardados.')
    window.localStorage.setItem('validFiles', encodedStringBtoA)
    /* setTimeout(function () {
      window.location.href = localStorage.getItem('addFiles')
    }, 2000) */
    console.log(localStorage.getItem('imgData'))
  }
  function getBase64 (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  return (
    <>
      {isOpenToast && <Toast message={messageToast} codigo={codigoToast} />}
      <ModalSpiner opt={spiner} />
      <ModalDeleteAdjunto option={option} isOpen={isOpenC} idFile={idFile} idRemove={idRemove} header={coloHeaderC} body={bodyC} color={coloHeaderC} button={modalButtonC} buttonCerrar={modalButtonCerrarC} handle={handleC} />
      <div className="col-12">
        {localStorage.getItem('idPlantilla')
          ? unsupportedFiles.length === 0 && validFiles.length
            ? <a type="button" onClick={uploadFiles} ><i className='fa fa-save'></i> Guardar</a>
            : ''
          : <><h2>Los archivos cargados quedaran guardados en memoria hasta que guarde toda la plantilla</h2></>
        }
      </div>
      <div className="col-2">
        {!localStorage.getItem('idPlantilla') &&
          <a onClick={() => uploadFilesTemp()} type="button" className="btn btn btn-success btn_campania"><i className='fa fa-save'></i> Guardar Temporal</a>
        }
      </div>
      <br />
      <div className="">
        {unsupportedFiles.length ? <p>Por favor remover todos los archivos no soportados.</p> : ''}
        <div className="drop-container"
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          onClick={fileInputClicked}
        >
          <div className="drop-message">
            <div className="upload-icon"><i className='fa fa-upload notify-color'></i></div>
            {global.DROP}
            <br />
            <h4>{global.WEIGTH}</h4>
          </div>
          <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            multiple
            onChange={filesSelected}
          />
        </div>
        <div className="upload-modal" ref={uploadModalRef}>
          <div className="overlay-files"></div>
          <div className="close" onClick={(() => closeUploadModal())}>X</div>
          <div className="progress-container">
            <span ref={uploadRef}></span>
            <div className="progress">
              <div className="progress-bar" ref={progressRef}></div>
            </div>
          </div>
        </div>
        <div className="modal" ref={modalRef}>
          <div className="overlay-files"></div>
          <span className="close" onClick={(() => closeModal())}>X</span>
          <div className="modal-image" ref={modalImageRef}></div>
          <div className="modal-doc"></div>
        </div>
        <div className="file-display-container">
          <div className='col-md-12 row'>
            {
              cont > 0 &&
              savedFiles &&
              savedFiles.map((data, i) =>
                <>
                  <div id={'delete-' + data.idAdjuntoPlantilla} className='col-md-3 form-group'>
                    <div className="file-status-bar" key={i} >
                      <div className="card">
                        <div className='align-center' id={'img-' + i} >
                          {
                            data.extension === 'PNG' && <img className="img-fluid-resp" src={data.urlPublica} />
                          }
                          {
                            data.extension === 'png' && <img className="img-fluid-resp" src={data.urlPublica} />
                          }
                          {
                            data.extension === 'jpg' && <img className="img-fluid-resp" src={data.urlPublica} />
                          }
                          {
                            data.extension === 'xls' && <img className="img-fluid-resp" src={Excel} />
                          }
                          {
                            data.extension === 'xlsx' && <img className="img-fluid-resp" src={Excel} />
                          }
                          {
                            data.extension === 'CSV' && <img className="img-fluid-resp" src={Excel} />
                          }
                          {
                            data.extension === 'csv' && <img className="img-fluid-resp" src={Excel} />
                          }
                          {
                            data.extension === 'ppt' && <img className="img-fluid-resp" src={Ppt} />
                          }
                          {
                            data.extension === 'pptx' && <img className="img-fluid-resp" src={Ppt} />
                          }
                          {
                            data.extension === 'docx' && <img className="img-fluid-resp" src={Word} />
                          }
                          {
                            data.extension === 'doc' && <img className="img-fluid-resp" src={Word} />
                          }
                          {
                            data.extension === 'zip' && <img className="img-fluid-resp" src={Rar} />
                          }
                          {
                            data.extension === 'rar' && <img className="img-fluid-resp" src={Rar} />
                          }
                          {
                            data.extension === 'txt' && <iframe className="img-fluid-resp-fr" src={data.urlPublica}></iframe>
                          }
                          {
                            data.extension === 'pdf' && <iframe className="img-fluid-resp-fr" src={data.urlPublica}></iframe>
                          }
                        </div>
                        <div className="card-body">
                          <div>
                            <h4 className="card-title"><div className="file-type-logo"></div></h4>
                            <p className="card-text">
                              <div className="file-type">{fileType(data.nombreArchivo)}</div>
                              <span className='cortar file-name'>{data.nombreArchivo}</span>
                              <span className="file-size">{fileType(data.nombreArchivo)}</span>
                            </p>
                            {/* {unsupportedFiles.length ? '' : <a href="#" className="btn btn-primary" ><i className='fa fa-download'></i></a>} */}
                            {unsupportedFiles.length ? '' : <a href="#" className="btn btn-primary" onClick={!data.invalid ? () => openImageModalSaved(data.urlPublica, data.extension) : () => removeFile('hooa')}><i className='fa fa-eye'></i></a>}
                            <a href="#" className="btn btn-primary" onClick={() => removeFileSaved('delete-' + data.idAdjuntoPlantilla, data.idAdjuntoPlantilla)}><i className='fa fa-trash'></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            }
            {
              validFiles.map((data, i) =>
                <>
                  <div id={'delete-memori' + i} className='col-md-3 form-group'>
                    <div className="file-status-bar" key={i} >
                      <div className="card">
                        <div className='align-center' id={'img-' + (i + cont)}></div>
                        <div className="card-body">
                          <div>
                            <h4 className="card-title"><div className="file-type-logo"></div></h4>
                            <p className="card-text">
                              <div className="file-type">{fileType(data.name)}</div>
                              <span className={`cortar file-name ${data.invalid ? 'file-error' : ''}`}>{data.name}</span>
                              <span className="file-size">({fileSize(data.size)})</span> {data.invalid && <span className='file-error-message'>({errorMessage})</span>}
                            </p>
                            {unsupportedFiles.length ? '' : localStorage.getItem('idPlantilla') && <a href="#" className="btn btn-primary" onClick={() => uploadFilesOne(i)} ><i className='fa fa-upload'></i></a>}
                            {/* {unsupportedFiles.length ? '' : <a href="#" className="btn btn-primary" ><i className='fa fa-download'></i></a>} */}
                            {unsupportedFiles.length ? '' : <a href="#" className="btn btn-primary" onClick={!data.invalid ? () => openImageModal(data) : () => removeFile(data.name)}><i className='fa fa-eye'></i></a>}
                            <a href="#" className="btn btn-primary" onClick={() => removeFileMemori(data.name)}><i className='fa fa-trash'></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>
      <br /><br />
      <br />    </>
  )
}

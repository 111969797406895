/* eslint-disable indent */
/* eslint-disable semi */
import React from 'react'
import { AppSidebar, AppHeader, AppContent, AppFooter } from './index'
import '../../scss/_master.scss'
import InactivityComponent from '../Helpers/InactivityComponent'
const DefaultLayout = () => {
  return (
    <>
    <InactivityComponent />
    <AppHeader />
    <AppSidebar />
    <AppContent />
    <AppFooter />
    </>
  )
}

export default DefaultLayout

/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable quotes */
/* eslint-disable indent */
import React from 'react'
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

const TooltipComponent = ({ errorC, total, parcial, exito, idGrupo, orden }) => {
    const COLORS = ['#D0185C', '#DD168B', '#A54094', '#442B89', '#5835B7', '#1A32B7', '#00BFD8', '#009988', '#ECE99B', '#EFA8B8', '#E9ACCA', '#BA97C6', '#39C8F2']

    let dataTool = [
        { pv: Math.round((errorC / total) * 100), value: errorC, name: "Error" },
        { pv: Math.round((exito / total) * 100), value: exito, name: "Exito" },
        { pv: Math.round((parcial / exito) * 100), value: parcial, name: "Parcial" },
        { pv: Math.round((total / total) * 100), value: total, name: "Total" }
    ]
  return (
    <div key={`tooltipC_${idGrupo}`} className="toolTip result-contacts">
        <div key={`tooltipCD_${idGrupo}`} className='row margin-div-donut'>
            {
            dataTool
                ? dataTool.length > 0
                ? <>
                    <div key={`dataPie_${idGrupo}`} className='col-md-8'>
                    <ResponsiveContainer key={`content_${idGrupo}`} width="100%" height={400}>
                        <PieChart key={`pie_${idGrupo}`} width={700} height={700}>
                            <Pie
                                key={`value_${idGrupo}`}
                                data={dataTool}
                                cx="49%"
                                cy="45%"
                                labelLine={true}
                                // label={renderCustomizedLabel}
                                outerRadius={100}
                                fill="#8884d8"
                            >
                                {dataTool.map((entry, index) => (
                                <Cell key={`cell-${index}-${idGrupo}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                    </div>
                    <div className='col-md-4'>
                    <fieldset className="form-group">
                        <div key={`data_${orden}_${idGrupo}`} className="row">
                        <div className="col-sm-10 margin-label-donut global-donut contacts">
                            {dataTool.map((entry, index) => (
                            <>
                                <div key={`form-${index}-${idGrupo}`} className="form-check">
                                    <div className={'colorDonut' + index}></div>
                                    <div className="form-group">
                                        <label className="form-check-label" htmlFor="gridRadios1">
                                        {entry.name}
                                        </label>
                                        <small id="emailHelp" className="form-text text-porcent">{entry.pv}%</small>
                                    </div>
                                </div>
                            </>
                            ))}
                        </div>
                        </div>
                    </fieldset>
                    </div>
                </>
                : <div className='col-md-6 center'>No se ha encontrado información</div>
                : <div className='col-md-6 center'>No se ha encontrado información</div>
            }
        </div>
    </div>
  )
}

export default TooltipComponent

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import serviceContact from '../../services/contact'
import Toast from '../../components/util/Toast'
import DataTable from 'datatables.net'
import NewModal from '../../components/util/ModalGeneral'
import ReactTooltip from 'react-tooltip'
import ModalSpiner from '../../components/util/ModalSpiner'
const CampaignSelection3 = () => {
  const [data, setData] = useState([])
  const [contact, setContact] = useState('')
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  const x = JSON.parse(window.localStorage.getItem('idGrups'))
  const [isOpen, setIsOpen] = useState(false)
  const [header, setHeader] = useState('')
  const [body, setBody] = useState('')
  const [coloHeader, setColorHeader] = useState('')
  const [modalButton, setModalButton] = useState('')
  const [handle, setHandle] = useState()
  const [closes, setCloses] = useState()
  const [hclose, setHclose] = useState()
  const [spiner, setSpiner] = useState(false)

  useEffect(() => {
    $('.form-control-sm').removeAttr('placeholder')
    if (window.localStorage.getItem('steps') === '3') {
      if (window.localStorage.getItem('canal') !== ' ') {
        contacTable()
      }
    }
    if (window.localStorage.getItem('steps') === '4' || window.localStorage.getItem('steps') === '5') {
      if (window.localStorage.getItem('canal') !== ' ') {
        contacTable()
      }
    }
  }, [])
  $(document).ready(function () {
    $('#b-siguiente').unbind().click(function () {
      $('#contact-table').dataTable().fnDestroy()
      contacTable()
      setSpiner(true)
    })
  })
  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000)
  }
  const handlebtn = (op) => () => {
    try {
      // eslint-disable-next-line no-var
      var array = []
      $('input:checkbox[class=checkbox-grupo]:checked').each(function () {
        array.push({ id: $(this).val() })
      })
      const table = $('#contact-table').dataTable()
      table
        .$('input:checkbox[class=checkbox-grupo]:checked')
        .each(function () {
          if (!$.contains(document, this)) {
            if (this.checked) {
              array.push({ id: $(this).val() })
            }
          }
        })
      if (array.length === 0) {
        global.LABEL_INPUT_ERROR = 'Por favor, debe seleccionar al menos un contacto'
        mostrarMensaje('400', global.LABEL_INPUT_ERROR)
      } else {
        window.localStorage.setItem('steps', 4)
        $('#circle4').addClass('circle-color')
        $('#chevron4').addClass('chevron-color')
        $('#circle4').addClass('circle-color')
        $('#panel-section2').removeClass()
        $('#panel-section2').addClass('no-success-panel')
        $('#panel-campa2').removeClass()
        $('#panel-campa2').addClass('success-panel')
        $('#underlined-position').removeClass()
        $('#underlined-position').addClass('underlined-ajus4')
      }
    } catch (error) {

    }
  }
  const handleClose = (op) => () => {
    setIsOpen(false)
  }
  const hevent = (permitido, id) => () => {
    if (permitido === 'false') {
      $('input[name=grupo_' + id + ']').prop('checked', false)
      setIsOpen(true)
      setColorHeader('text-center')
      setBody('La base de datos seleccionada esta siendo procesada.')
      setModalButton('Aceptar')
      setHandle(handleClose)
      setHclose(handleClose)
      setCloses('X')
      $('.MuiDialogContentText-root').addClass('text-center')
    } else {
      const i = []
      $('input[class=checkbox-grupo]:checked').each(function () {
        i.push({ i: $(this).val() })
      })
      const table = $('#contact-table').dataTable()
      table
        .$('input:checkbox[class=checkbox-grupo]:checked')
        .each(function () {
          if (!$.contains(document, this)) {
            if (this.checked) {
              i.push({ i: $(this).val() })
            }
          }
        })
      window.localStorage.setItem('idGrups', JSON.stringify(i))
    }
  }
  const handlebtnanterior = (op) => () => {
    try {
      window.localStorage.setItem('steps', 2)
      $('#contact-table').dataTable().fnDestroy()
      $('#circle3').removeClass()
      $('#circle3').addClass('circle-camp2')
      $('#chevron3').removeClass()
      $('#chevron3').addClass('fas fa-chevron-right fas-camp ml-2')
      $('#panel-section2').removeClass()
      $('#panel-section2').addClass('no-success-panel')
      $('#panel-section1').removeClass()
      $('#panel-section1').addClass('success-panel')
      $('#panel-contenido').removeClass()
      $('#panel-contenido').addClass('success-panel')
      $('#underlined-position').removeClass()
      $('#underlined-position').addClass('underlined-ajus2')
      $('#scroll-2').addClass('scroll scroll-h-camp scrollbar')
      window.localStorage.removeItem('canalGroups')
      if (window.localStorage.getItem('señal') === '0') {
        $('#contact-table').dataTable().fnDestroy()
        if (window.localStorage.getItem('campignTemplate') === '1') {
          $('#rectangu1').click()
        }
        if (window.localStorage.getItem('campignTemplate') === '2') {
          $('#rectangu2').click()
        }
        if (window.localStorage.getItem('campignTemplate') === '3') {
          $('#rectangu3').click()
        }
        if (window.localStorage.getItem('idPlanSteps2') !== '') {
          $('input[name=id_' + window.localStorage.getItem('idPlanSteps2') + ']').prop('checked', true)
        }
      }
    } catch (error) {

    }
  }
  const contacTable = async (event) => {
    try {
      let tipo
      if (window.localStorage.getItem('canal') !== '') {
        tipo = window.localStorage.getItem('canal')
      } else {
        tipo = $('#canal').val()
      }
      const contact = await serviceContact.getGroups({ tipo })
      if (contact.data.code === 200) {
        setSpiner(false)
        if (window.localStorage.getItem('steps') === '3') {
          mostrarMensaje('200', global.LABEL_INPUT_ERROR)
        }
        setContact(contact.data.data.length)
        setData(contact.data.data.row)
        $('#contact-table').DataTable({
          initComplete: function (settings, json) {
            $(this).css('text-align', 'center')
            $('body').find('.dataTables_scrollBody').addClass('scrollbar')
            if (x.length > 0 && window.localStorage.getItem('señal' === '0')) {
              const table = $('#contact-table').dataTable()
              $.each(x, function (i, val) {
                table
                  .$('input[class=checkbox-grupo]')
                  .each(function () {
                    if (!$.contains(document, this)) {
                      $('input[name=grupo_' + val.i + ']').prop('checked', true)
                      if ($(this).val() === val.i) {
                        $(this).prop('checked', true)
                      }
                    }
                  })
              })
            }
          },
          scrollY: '270px',
          scrollCollapse: true,
          order: [[2, 'desc']],
          lengthMenu: [5, 10, 20],
          language: {
            decimal: '',
            emptyTable: 'No hay información',
            info: 'Mostrando _START_ a _END_ de un total de _TOTAL_ Registros',
            infoEmpty: 'Mostrando 0 to 0 of 0 Registros',
            infoFiltered: '(Filtrado de _MAX_ total Registros)',
            infoPostFix: '',
            thousands: ',',
            lengthMenu: 'Mostrar _MENU_ Registros',
            loadingRecords: 'Cargando...',
            processing: 'Procesando...',
            search: 'Buscar:',
            zeroRecords: 'Sin resultados encontrados',
            paginate: {
              first: 'Primero',
              last: 'Ultimo',
              next: '>',
              previous: '<'
            }
          }
        })
      }
    } catch (e) {
      if (window.localStorage.getItem('steps') === '3') {
        setSpiner(false)
        global.LABEL_INPUT_ERROR = 'No se pudo consultar los contactos, intente de nuevo mas tarde'
        mostrarMensaje(global.CODEERRORSET, global.LABEL_INPUT_ERROR)
      }
    }
  }
  const eventhandle = () => {}
  return (
    <div className='no-success-panel' id='panel-section2'>
      <ModalSpiner opt={spiner} />
      {isOpen && <NewModal isOpen={isOpen} header={header} body={body} color={coloHeader} button={modalButton} handle={handle} close={closes} hclose={hclose}/>}
      {isOpenToast && <Toast message={messageToast} codigo={codigoToast} />}
                    <input type="hidden" id="typeCanal" />
        <div className="form-group mb-4 container-fluid">
            <label className="form-label mt-2 mb-2"><h6><b>Seleccione la base de contactos</b></h6><span className="seleccion"></span> </label>
            <hr/>
              <div className="mb-12 panel-group panel_campania table-responsive">
                <table id='contact-table' className="table">
                <thead>
                  <tr>
                    <th>Acción</th>
                    <th>Nombre de la lista</th>
                    <th>Fecha de creación</th>
                    <th>Tipo canal</th>
                    <th>Contactos</th>
                  </tr>
                </thead>
                <tbody>
                {data.map((val) => {
                  <input type='hidden' value={val.nombre} className={'names_' + val.idGrupo}></input>
                  return (<React.Fragment key={val.id}>
                                  <tr key={val.id} className='odd'>
                                    <td className='dataTables_empty'><div onClick={eventhandle(val.permitido)}><input className='checkbox-grupo' id='grupo-che' name={'grupo_' + val.idGrupo} onClick={hevent(val.permitido, val.idGrupo)} value={val.idGrupo} type='checkbox'></input></div></td>
                                    <td>
                                    {val.nombre.length > 20
                                      ? <div className='ajust-text' data-tip={`<p class="content-tool" id=${'names_' + val.idGrupo}> ${val.nombre.toLowerCase()}</p>`} data-html={true}>
                                    <span id={'names_' + val.idGrupo}>{val.nombre.toLowerCase()}</span>
                                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                    </div>

                                      : <>
                                  <span id={'names_' + val.idGrupo}>{val.nombre.toLowerCase()}</span>
                                  </>
                                }
                                    </td>
                                    <td>{val.fechaCreacion}</td>
                                    <td>{val.canal === 'T' ? 'Mailing y mensajeria instantanea' : val.canal === 'C' ? 'Mailing' : val.canal === 'M' ? 'Mensajeria instantanea' : ''}</td>
                                    <td id= {'destinatarios_' + val.idGrupo}>{val.cantidadParcial === null ? 0 : val.cantidadParcial} </td>
                                  </tr>
                                  </React.Fragment>
                  )
                }
                )}
                </tbody>
                </table>
              </div>
            </div>
        <div className='btn-posicionatras mb-7 p-atras'><button className='btnatras' onClick={handlebtnanterior(1)} >Anterior</button></div>
        <div className='btn-posicionsgt mb-7 p-sgt'><button className='btnsgt' id='b-step3' onClick={handlebtn(1)} >Siguiente</button></div>
      </div>
  )
}
export default CampaignSelection3

/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable semi */
import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Form } from "react-bootstrap";
import service from "../../services/campaign";
import serviceDate from '../../services/global'
import serviceConct from '../../services/contact'
import Toast from "../../components/util/Toast";
import ModalCampaignConfirmacion from "../../components/util/ModalCampaignConfirmacion";
import ModalSpiner from "../../components/util/ModalSpiner";
const CampaignStep5 = () => {
  const [isOpen, setIsOpen] = useState()
  const [color, setColor] = useState()
  const [classbody, setClassbody] = useState()
  const [header, setHeader] = useState()
  const [body, setBody] = useState()
  const [button, setButton] = useState()
  const [buttonCerrar, setButtonCerrar] = useState()
  const [handle, setHandle] = useState()
  const [btnAceptar, setBtnAceptar] = useState()
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [pDate, setDate] = useState("");
  const [pTime, setTime] = useState("");
  const [idP, setIdp] = useState();
  const [t, setT] = useState();
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  const [spiner, setSpiner] = useState(false)
  let total = 0;
  const dat = window.localStorage.getItem("data");
  const [ind, setInd] = useState()
  const [da, setDa] = useState()
  const [ti, setTi] = useState()
  const time2 = serviceDate.getTime()
  const date2 = serviceDate.getDate()
  const [dataContac, setDataContac] = useState([])
  const time = () => {
    $('#time-' + ind).removeClass('colorRed')
    $('#time-' + ind).addClass('colorinput')
  }
  const hDate = () => {
    $('#date-' + ind).removeClass('colorRed')
    $('#date-' + ind).addClass('colorinput')
  }
  const contact = async (event) => {
    let tipo
    if (window.localStorage.getItem('canal') !== '') {
      tipo = window.localStorage.getItem('canal')
    } else {
      tipo = event
    }
    const dataCont = await serviceConct.getGroups({ tipo })
    setDataContac(dataCont.data.data.row)
  }
  $(document).ready(function () {
    $("#b-step3")
      .unbind()
      .click(function () {
        const canal = $('#canal').val()
        contact(canal)
        $('input[name=id_' + window.localStorage.getItem('idPlanSteps2') + ']').prop('checked', true)
      })
  });
  useEffect(() => {
    if (window.localStorage.getItem('x') === '5') {
      const datos = JSON.parse(window.localStorage.getItem('ar'))
      setData(datos)
      setT(window.localStorage.getItem('t'))
    }
  }, [])
  $(document).ready(function () {
    $("#b-step4")
      .unbind()
      .click(function () {
        setDa($("#r-date").val())
        setTi($("#r-time").val())
        setDate($("#r-date").val())
        setTime($("#r-time").val())
        const array = [];
        const arrayList = [];
        let cant = 0;

        $("input:checkbox[class=checkbox-grupo]:checked").each(function () {
          array.push({
            id: $(this).val(),
            nombre: $("#names_" + $(this).val()).html(),
            destinatarios: $("#destinatarios_" + $(this).val()).html(),
          });
          setList(array);
        });
        $.each(dataContac, function (i, val) {
          const table = $("#contact-table").dataTable();
          table
            .$("input:checkbox[class=checkbox-grupo]:checked")
            .each(function () {
              if (!$.contains(document, this)) {
                if (this.checked) {
                  if ('' + $(this).val() === '' + val.idGrupo) {
                    array.push({
                      id: val.idGrupo,
                      nombre: val.nombre,
                      destinatarios: val.cantidadDestinatarios,
                    });
                    setList(array);
                  }
                }
              }
            });
        });
        $.each(array, function (i, val) {
          cant += parseInt(val.destinatarios);
        });
        // $('.total-user').html('<i className="far fa-user"></i> Total<b> destinatarios </b>' + cantidadDestinatarios)
        if (window.localStorage.getItem('idPlanSteps2') !== '') {
          setIdp(window.localStorage.getItem('idPlanSteps2'))
          arrayList.push({
            idPlantilla: window.localStorage.getItem('idPlanSteps2'),
            plantilla: window.localStorage.getItem('nombrePlanSteps2'),
            list: array,
            cantidad: cant,
            date: $("#r-date").val(),
            time: $("#r-time").val(),
          })
          setData(arrayList)
        }
        if (dat === null) {
          setData(arrayList);
        } else if (dat !== null || dat !== "") {
          $.each(JSON.parse(dat), function (index, val) {
            arrayList.push({
              idPlantilla: val.idPlantilla,
              plantilla: val.plantilla,
              list: val.list,
              cantidad: val.cantidad,
              date: val.date,
              time: val.time,
            });
          });
          setData(arrayList);
        }
        $.each(arrayList, function (index, val) {
          total += parseInt(val.cantidad);
          setT(total);
        });
        window.localStorage.setItem('ar', JSON.stringify(arrayList))
        window.localStorage.setItem('t', total)
        window.localStorage.setItem('x', 5)
      });
  });
  const handlecreation = async () => {
    const a = [];
    let idCAMP;
    if (data !== '') {
      idCAMP = window.localStorage.getItem("idcamp");
      $.each(data, function (index, val) {
        $.each(val.list, function (i, v) {
          a.push({
            idGrupo: v.id,
            idPlantilla: val.idPlantilla,
            fechaProgramacion: val.date + " " + val.time,
          });
        });
      });
    } else
    if (dat === null) {
      if (window.localStorage.getItem("cp") === "op") {
        idCAMP = window.localStorage.getItem("idcamp");
        $.each(list, function (index, val) {
          a.push({
            idGrupo: val.id,
            idPlantilla: idP,
            fechaProgramacion: $("#r-date").val() + " " + $("#r-time").val(),
          });
        });
      } else {
        $("input:checkbox[id=radio]:checked").each(function () {
          idCAMP = $(this).val();
          $.each(list, function (index, val) {
            a.push({
              idGrupo: val.id,
              idPlantilla: idP,
              fechaProgramacion: pDate + " " + pTime
            });
          });
        });
      }
    }
    if (pDate === date2 && pTime <= time2) {
      $('#time-' + ind).addClass('colorRed')
      global.LABEL_INPUT_ERROR = global.CAMPAIGN_TIME_ERROR
      mostrarMensaje('400', global.LABEL_INPUT_ERROR)
    } else {
      const handleAceptar = () => async () => {
        setIsOpen(false)
        setSpiner(true)
        const datt = { idCampana: idCAMP, rows: a };
        // alert(JSON.stringify(datt))
        const ser = await service.programacion({ datt });
        if (ser.error === 0) {
          $("#finish").removeClass();
          $("#finish").addClass("success-panel");
          $("#panel-campa3").removeClass();
          $("#panel-campa3").addClass("no-success-panel");
          $("#panel-info").removeClass();
          $("#panel-info").addClass("no-success-panel");
          window.localStorage.removeItem("ID");
          window.localStorage.removeItem("descamp");
          window.localStorage.removeItem("canal");
          window.localStorage.removeItem("idcamp");
          window.localStorage.removeItem("idGrups");
          window.localStorage.removeItem("camp");
          window.localStorage.removeItem("steps");
          window.localStorage.removeItem("data");
          window.localStorage.removeItem("cp");
          window.localStorage.removeItem("dia");
          window.localStorage.removeItem("hora");
          window.localStorage.removeItem("idPlanSteps2");
          window.localStorage.removeItem("nombrePlanSteps2");
          window.localStorage.removeItem("idGrups");
          window.localStorage.removeItem("campignTemplate");
          window.localStorage.removeItem("t");
          window.localStorage.removeItem("x");
          window.localStorage.removeItem("ar");
          window.localStorage.removeItem("l");
          window.localStorage.removeItem("señal");
          window.localStorage.removeItem("set");
          setSpiner(false)
        } else {
          setSpiner(false)
          global.LABEL_INPUT_ERROR = 'Error, intentelo nuevamente'
          mostrarMensaje(global.CODEERRORSET, global.LABEL_INPUT_ERROR)
          window.localStorage.removeItem("ID");
          window.localStorage.removeItem("descamp");
          window.localStorage.removeItem("canal");
          window.localStorage.removeItem("idcamp");
          window.localStorage.removeItem("idGrups");
          window.localStorage.removeItem("camp");
          window.localStorage.removeItem("steps");
          window.localStorage.removeItem("data");
          window.localStorage.removeItem("cp");
          window.localStorage.removeItem("dia");
          window.localStorage.removeItem("hora");
          window.localStorage.removeItem("idPlanSteps2");
          window.localStorage.removeItem("nombrePlanSteps2");
          window.localStorage.removeItem("idGrups");
          window.localStorage.removeItem("campignTemplate");
          window.localStorage.removeItem("t");
          window.localStorage.removeItem("x");
          window.localStorage.removeItem("ar");
          window.localStorage.removeItem("l");
          window.localStorage.removeItem("señal");
          window.localStorage.removeItem("set")
          setIsOpen(false)
          window.location.href = global.ROUTE_CREATECAMPAIGN
        }
      }
      setIsOpen(true)
      setHeader('Finalizar programación')
      setBody('¿Está seguro de finalizar la programación?')
      setColor('text-center notify-color')
      setClassbody('')
      setButtonCerrar('Cancelar')
      setHandle(handleClose)
      setButton('Aceptar')
      setBtnAceptar(handleAceptar)
    }
  };
  const handleClose = () => () => {
    setIsOpen(false)
  }

  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000)
  }

  const handlereturn = () => {
    window.localStorage.setItem("data", JSON.stringify(data));
    window.localStorage.removeItem("dia");
    window.localStorage.removeItem("hora");
    window.localStorage.removeItem("idPlanSteps2");
    window.localStorage.removeItem("nombrePlanSteps2");
    window.localStorage.removeItem("idGrups");
    window.localStorage.removeItem("campignTemplate");
    window.localStorage.removeItem("t");
    window.localStorage.removeItem("x");
    window.localStorage.removeItem("ar");
    window.localStorage.setItem("steps", 2);
    window.location.href = global.ROUTE_CREATECAMPAIGN;
    window.localStorage.removeItem("l");
    window.localStorage.removeItem("señal");
    window.localStorage.removeItem("set")
  };
  const handlebtnanterior = (op) => () => {
    window.localStorage.setItem("steps", 4);
    $("#circle5").removeClass();
    $("#circle5").addClass("circle-camp2");
    $("#panel-campa3").removeClass();
    $("#panel-campa3").addClass("no-success-panel");
    $("#panel-campa2").removeClass();
    $("#panel-campa2").addClass("success-panel");
    $("#panel-info").removeClass();
    $("#panel-info").addClass("success-panel");
    $("#underlined-position").removeClass();
    $("#underlined-position").addClass("underlined-ajus4");
    $("#panel-info1").removeClass();
    $("#panel-info1").addClass("no-success-panel");
  };
  const eliminar = (index) => () => {
    const e = data.filter((v, i) => i !== index);
    setData(e);
    let total = 0
    $.each(e, function (i, val) {
      total = total + val.cantidad
    })
    setT(total)
  };
  const handleDate = (index) => () => {
    setInd(index)
  }
  if (pDate !== da && pDate !== '') {
    setData($.each(data, function (i, val) {
      if (i === ind) {
        val.date = pDate
      }
    }))
    setDa(pDate)
  }
  if (pTime !== ti && pTime !== '') {
    setData($.each(data, function (i, val) {
      if (i === ind) {
        val.time = pTime
      }
    }))
    setTi(pTime)
  }
  const hscrollNext = (id) => () => {
    const currentScroll = $('#scrol-list' + id).get(0).scrollLeft;
    const scrollWidth = $('#scrol-list' + id).get(0).scrollWidth;
    if ((200 + currentScroll) >= scrollWidth) {
      return ('')
    } else {
      $('#scrol-list' + id).animate({
        scrollLeft: '+=200px'
      }, "slow");
    }
  }
  const hscrollPrev = (id) => () => {
    const currentScroll = $('#scrol-list' + id).get(0).scrollLeft;
    if ((currentScroll) <= 0) {
      return ('')
    } else {
      $('#scrol-list' + id).animate({
        scrollLeft: '-=200px'
      }, "slow");
    }
  }
  return (
    <div className="no-success-panel" id="panel-campa3">
      <ModalSpiner opt={spiner}/>
      {isOpen && <ModalCampaignConfirmacion isOpen={isOpen} classbody={classbody} color={color} header={header} body={body} button={button} buttonCerrar={buttonCerrar} handle={handle} btnAceptar={btnAceptar}/>}
      {isOpenToast && <Toast message={messageToast} codigo={codigoToast} />}
      <label className="total-user">
        <i className="far fa-user"></i> Total<b> {t} destinatarios </b>
      </label>
      <div className="panel-group justify-content-between col-lg">
        <div className="row mt-2 col-md-12">
          <h5 className="notify-color ml-3">
            Revisar que todos los campos sean correctos
          </h5>
          <div className="col-md-3 create-campaign right2 w-auto">
            <button
              type="button"
              className="btn btn btn-success btn-campania"
              onClick={handlereturn}
            >
              <b>
                <i className="fa fa-bullhorn bullhorn"></i> Continuar
                programación{" "}
              </b>
            </button>
          </div>
        </div>
        <div className="scroll mt-4 container-scroll scrollbar">
          {data.map((val, key) => {
            return (
              <React.Fragment key={val.id}>
                <div className="col-12">
                  <span
                    className="col12 float-right"
                    id="elimina"
                    type="button"
                    onClick={eliminar(key)}
                  >
                    <i className="far fa-trash-alt notify-color"></i>
                  </span>
                  <label className="col-11" id="plantilla">
                    <b>Plantilla: </b>
                    {val.plantilla}
                  </label>
                  <div className="row justify-content-between">
                  <div className="btnScroll ml-4 mr-2" onClick={hscrollPrev(key)}><i className='fas fa-angle-left size-fas'></i></div>
                  <div className="scroll scroll-sept1 ml-3 scroll-writh" id={"scrol-list" + key}>
                    {val.list.map((vals, key) => {
                      return (
                        <React.Fragment key={vals.id}>
                          <label className="col-3 mr-5" id="s-lista">
                            <b>Lista {key + 1}:</b> {vals.nombre}
                          </label>
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <div className="btnScroll mr-2" onClick={hscrollNext(key)}><i className='fas fa-angle-right size-fas'></i></div>
                  </div>
                  <label className="col-12 mt-2" id="can">
                    <b>Cant. destinatarios</b> {val.cantidad}
                  </label>
                  <div className="container-fluid mt-2">
                    <label>
                      <b>Cronograma de la campaña: </b>
                    </label>
                    <div className="row">
                      <div
                        className="col-md-3 " onClick={hDate}
                      >
                        <Form.Group
                          controlId="dob"
                          className="form-control container-datepicker" id= {"date-" + key}
                        >
                          <div>
                            <label className="w-100">Fecha de envío</label>
                          </div>
                          <div>
                            <input
                              className="r-date  date-input"
                              value={val.date}
                              onChange={({ target }) => setDate(target.value)}
                              type="date"
                              name="dob"
                              onClick={handleDate(key)} min={date2}
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-3" onClick={time}>
                        <Form.Group
                          controlId="dob"
                          className="form-control container-datepicker" id= {"time-" + key}
                        >
                          <div>
                            <label>Hora de envío</label>
                          </div>
                          <div>
                            <input
                              className="r-time date-input"
                              value={val.time}
                              onChange={({ target }) => setTime(target.value)}
                              type="time"
                              name="dob"
                              onClick={handleDate(key)}
                            />
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                    <hr className="mt-3" />
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
        <div className="btn-posicionatras p-atras">
          <button className="btnatras" onClick={handlebtnanterior(1)}>
            Anterior
          </button>
        </div>
        <div className="btn-posicionsgt p-sgt p-programacion">
          <button
            className="btnsgt btn-create-campania btn-sgt-w"
            onClick={handlecreation}
          >
            Finalizar programación
          </button>
        </div>
      </div>
    </div>
  );
};
export default CampaignStep5;

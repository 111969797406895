import React from 'react'
import Confetti from '../../components/util/Confetti'
const finish = () => {
  return (
        <>
            <div className='no-success-panel' id='finish'>
            <div className='text-center absolute inset-1/4'>
                <div className='conteiner-info-success'>
                  <h3 className='success-title mt-5'>En hora buena</h3>
                  <div className='mt-[10px]'>Haz completado todos los pasos</div>
                  <div className=''>de tu programacion </div>
                  <div className=''>para tu campaña</div>
                  <a id='btn' href={ global.ROUTE_DASHBOARD } className="btn btn-block btn-primary login0 rounded-pill">Ir al dashboard</a>
                </div>
            </div>
            <Confetti />
            <div className="row step-panel">
            </div>
            </div>
        </>
  )
}
export default finish

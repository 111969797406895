/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable quotes */
/* eslint-disable no-tabs */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-expressions */
/* eslint-disable semi */
/* eslint-disable prefer-const */
/* eslint-disable space-before-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable no-trailing-spaces */
/* eslint-disable keyword-spacing */
/* eslint-disable react/prop-types */
/* eslint-disable indent */
/* eslint-disable no-multiple-empty-lines */
import React, { useState } from 'react'
import ModalConfirmacion from '../../../components/util/ModalConfirmacion'
import ModalCamposGrupo from '../../../components/util/ModalCamposGrupo'
import gruposServices from '../../../services/contact'
import ModalEditarGrupo from '../../../components/util/ModalEditarGrupo'
import TableContactos from './TableContactos';
import TooltipComponent from './TooltipComponent';
import helper from '../../../services/helper'
import ReactTooltip from 'react-tooltip';

const Listas = ({ permisosListas, handleModalAgg, helpServices, dataLista, orden, clase, handleModal, getDataListas, mostrarMensaje, setSpiner, refreshDestinatarios, refreshDataGrupos }) => {
    const [isOpenConf, setIsOpenConf] = useState(false)
    const [isOpenCampos, setIsOpenCampos] = useState(false)
    const [header, setHeader] = useState('')
    const [labelF, setLabelF] = useState('')
    const [labelS, setLabelS] = useState('')
    const [isAccionConf, setAccionConf] = useState('')
    const [isOpenEditGrupo, setEditGrupo] = useState(false)
    const [bodyConf, setBodyConf] = useState('')
    const [idGrupoSelect, setIdGrupoSelect] = useState('')
    const [grupoEdit, setGrupoEdit] = useState({})
    const [idDestinatarioSelect, setIdDestinatarioSelect] = useState('')
    const [idGrupoDelete, setIdGrupoDelete] = useState('')
    const [dataCampos, setDataCampos] = useState([])
    const [permisoListass, setPermisoListas] = useState(permisosListas)
    
    const options = {
			theme: "dark2",
			animationEnabled: true,
			exportFileName: "New Year Resolutions",
			exportEnabled: true,
			title: {
				text: "Top Categories of New Year's Resolution"
			},
			data: [{
				type: "pie",
				showInLegend: true,
				legendText: "{label}",
				toolTipContent: "{label}: <strong>{y}%</strong>",
				indexLabel: "{y}%",
				indexLabelPlacement: "inside",
				dataPoints: [
					{ y: 32, label: "Health" },
					{ y: 22, label: "Finance" },
					{ y: 15, label: "Education" },
					{ y: 19, label: "Career" },
					{ y: 5, label: "Family" },
					{ y: 7, label: "Real Estate" }
				]
			}]
		}
    
   // console.log('dataLista', dataLista); 
   
    const handleDeleteGrupo = async () => {
      let info;
        setSpiner(true)
        // let idGrupo = 58;
        info = await gruposServices.DeleteGrupo(idGrupoSelect)

        let objMessage = helpServices.getMessage(info.data)
        console.log('objMessage', objMessage)
        
        if(objMessage.code === 200){
          console.log('entroo OK')
          setIsOpenConf(false)
          refreshDataGrupos()
          mostrarMensaje(objMessage.code, objMessage.message)
        }else{
          setIsOpenConf(false)
          mostrarMensaje(objMessage.code, objMessage.message)
        }
        setSpiner(false)
    }
    const handleClonarLista = async () => {
      let info;
        setSpiner(true)
        let idGrupo = 58;
        info = await gruposServices.ClonarLista(idGrupoSelect)
        console.log('info', info);
        let objMessage = helpServices.getMessage(info.data)
        console.log('objMessage', objMessage)
        if(objMessage.code === 200){
          console.log('entroo OK')
          setIsOpenConf(false)
          refreshDataGrupos()
          mostrarMensaje(objMessage.code, objMessage.message)
        }else{
          setIsOpenConf(false)
          mostrarMensaje(objMessage.code, objMessage.message)
        }
        setSpiner(false)
    }
    const handleDeleteDestinatario = async () => {
      let info;
        setSpiner(true)
        // let idDestinatario = '33c03b84-e2d1-4b72-bfa3-129143de6f45';
        info = await gruposServices.DeleteDestinatario({ idGrupo: idGrupoDelete, idDestinatario: idDestinatarioSelect })
        let objMessage = helpServices.getMessage(info.data)
        console.log('objMessage', objMessage)
        if(objMessage.code === 200){
          console.log('entroo OK')
          setIsOpenConf(false)
          refreshDestinatarios(idGrupoDelete)
          mostrarMensaje(objMessage.code, objMessage.message)
        }else{
          setIsOpenConf(false)
          mostrarMensaje(objMessage.code, objMessage.message)
        }
        setSpiner(false)
    }
    const handleModalConf = (op) => {
      if(op){
        isAccionConf === 'DG' ? handleDeleteGrupo() : isAccionConf === 'DD' ? handleDeleteDestinatario() : handleClonarLista() 
      }else{
        setIsOpenConf(false)
      }
    }
    const handleCloseEditGrupo = (op) => {
      console.log('opcion', op);
      if(op) {
        setEditGrupo(false)
        refreshDataGrupos()
        mostrarMensaje(200, global.PROCESO_OK)
        console.log('recargar Listas');
      }else{
        setEditGrupo(false)
      }
    }
    const deleteGrupo = (idGrupo) => {
      setAccionConf('DG')
      setIdGrupoSelect(idGrupo)
      setHeader('Eliminar lista')
      setBodyConf(global.MODAL_ELIM_LISTA)
      setIsOpenConf(true)
    }
    const getDataError = async (idGrupo) => {
        setIdGrupoSelect(idGrupo)
        setSpiner(true)
        const info = await gruposServices.GetDataErrorGrupo(idGrupo)
        setSpiner(true)

        let objMessage = helpServices.getMessage(info.data)
        console.log('objMessage', objMessage)
        if(objMessage.code === 200){
         if(info.data.data.row){
          info.data.data.row.forEach(element => {
            if(element.camposPersonalizados){
              element.camposPersonalizados.forEach((item) => {
                element[item.campo.toLowerCase()] = item.valor
              })
            }
            delete (element.camposPersonalizados)
          });

          console.log('info.data.data.row', info.data.data.row)
          helper.dowloadCsv(info.data.data.row, "contactos-error")
          }else{
            mostrarMensaje(400, 'Los datos ya fueron cargados')
          }
        }else{
          mostrarMensaje(objMessage.code, objMessage.message)
        }

        setSpiner(false);
    }
    const clonarLista = (idGrupo) => {
      setAccionConf('CL')
      setIdGrupoSelect(idGrupo)
      setHeader('Clonar lista')
      setBodyConf(global.MODAL_CLONAR_LISTA)
      setIsOpenConf(true)
    }
    const editaGrupo = async (idGrupo) => {
      setIdGrupoSelect(idGrupo)
      const getGrupoid = await gruposServices.getGrupoId(idGrupo)

      let objMessage = helpServices.getMessage(getGrupoid.data)
      console.log('objMessage', objMessage)
      if(objMessage.code === 200){
        console.log(getGrupoid.data.data.row); 
        setGrupoEdit(getGrupoid.data.data.row)
        setHeader('Editar lista')
        setLabelF('Nombre de lista')
        setLabelS('Descripción de los miembros de la lista')
        setEditGrupo(true)
      }else{
        mostrarMensaje(objMessage.code, objMessage.message)
      }

      console.log('getGrupoid', getGrupoid);
    }
    const deleteDestinatario = (idGrupo, idDestinatario) => {
      setAccionConf('DD')
      setIdDestinatarioSelect(idDestinatario)
      setIdGrupoDelete(idGrupo)
      setHeader('Eliminar contacto')
      setBodyConf(global.MODAL_ELIM_DESTINATARIO)
      setIsOpenConf(true)
    }
    const getParametros = async (idGrupo) => {
      setSpiner(true)
      const getGrupo = await gruposServices.getParametrosGrupo(idGrupo)
      setSpiner(false)
      let objMessage = helpServices.getMessage(getGrupo.data)
      console.log('objMessage', objMessage)
      if(objMessage.code === 200){
        setIsOpenCampos(true)
        setDataCampos(getGrupo.data.data)
      }else{
        mostrarMensaje(objMessage.code, objMessage.message)
      }
      console.log('getGrupo', getGrupo)
    }
  return (
    <div className='justify-content-betweend mt-3 color-table pb-4'>
      
           {isOpenEditGrupo && <ModalEditarGrupo mostrarMensaje={mostrarMensaje} tipoGrupo={'L'} label1={labelF} label2={labelS} grupoEdit={grupoEdit} isOpen={isOpenEditGrupo} handleCloseEditGrupo={handleCloseEditGrupo} header={header}/>}
           {isOpenConf && <ModalConfirmacion isOpen={isOpenConf} body={bodyConf} handleModalConf={handleModalConf} header={header}/>}
           {isOpenCampos && <ModalCamposGrupo isOpen={isOpenCampos} dataCampos={dataCampos} setIsOpenCampos={setIsOpenCampos}/>}

              <div className={`col-12 row color-reglon ${clase}`}>
                <div className={`col-6 color-rigth ${clase}`}>
                  <h5>
                    <b>
                    {dataLista.nombre.length > 25 ?
                        <>
                          {/* <div className='ajust-text' data-tip={`<p class="content-tool"> ${destinatario.correo.toLowerCase()}</p>`} data-html={true}> */}
                          <div className='ajust-text name-grupo' data-tip={dataLista.nombre} data-html={true}>
                              {dataLista.nombre}
                              <ReactTooltip place="bottom" type="dark" effect="solid"/>
                          </div>

                          {/* <div data-tip={`<p></p>`}>
                          
                              {/* <ReactTooltip data-html={true} width={'180px'} className='custom-tooltip' multiline={ true } place="bottom" type="dark" effect="solid"/> }
                              
                          </div> */}
                        </>
                      :
                      <>
                      {dataLista.nombre}
                      </>
                    }
                    </b>
                  </h5>
                </div>
                <div className={`col-2 color-rigth ${clase} pl-2 d-flex`}>
                  <strong>Contactos:</strong> 
                  <span id={`cantGrupo_${dataLista.idGrupo}`}>{dataLista.cantidadParcial}</span>
                  {(dataLista.cantidadDestinatarios > 0 && dataLista.cantidadTotal > 0) && 
                        <div className="dropdown contacts">
                          <a type="text" id="dropdownMenuContact" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className={`fa fa-info-circle icon-info ${clase} ml-2`} aria-hidden="true"></i>
                          </a>
                          <div className="dropdown-menu" aria-labelledby="dropdownMenuContact">
                              <TooltipComponent key={`tool_${orden}_${dataLista.idGrupo}`} orden={orden} idGrupo={dataLista.idGrupo} total={dataLista.cantidadTotal} parcial={dataLista.cantidadParcial} exito={dataLista.cantidadDestinatarios} errorC={dataLista.cantidadfallidos} />
                          </div>
                        </div>
                  }                  
                </div>
                <div className={`col-3 color-rigth ${clase}`}><strong>Fecha creación:</strong> {dataLista.fechaCreacion.split(' ')[0]}</div>
                {permisoListass && (
                <div className="dropdown col-1">
                  
                    <a type="text" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fa fa-ellipsis-v fa-color"></i>
                </a>

                  
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <div onClick={() => { handleModalAgg('C', dataLista.idGrupo, '', dataLista.canal) }}>
                        <i className='ml-2 far fa-user notify-color'></i>
                        <span className='ml-2 detail-drop-down'>Agregar</span>
                      </div>
                      {/* <div><i className="ml-2 fa fa-ellipsis-h notify-color"></i><i className='ml-2 font-syle'>Exportar lista</i></div> */}
                      <div onClick={() => { clonarLista(dataLista.idGrupo) }}>
                        <i className="ml-2 far fa-clone notify-color"></i>
                        <span className='ml-2 detail-drop-down'>Clonar</span>
                      </div>
                      <div onClick={() => { editaGrupo(dataLista.idGrupo) }}>
                        <i className="ml-2 fas fa-pen notify-color" style={{ fontSize: '13px' }} ></i>
                        <span className='ml-2 detail-drop-down'>Editar</span>
                      </div>
                      <div onClick={() => { deleteGrupo(dataLista.idGrupo) }}>
                        <i className='ml-2 far fa-trash-alt notify-color'></i>
                        <span className='ml-2 detail-drop-down'>Eliminar</span>
                      </div>
                      {dataLista.cantidadfallidos > 0 && 
                      <>
                        <div onClick={() => { getDataError(dataLista.idGrupo) }}>
                          <i className='ml-2 fa fa-download notify-color'></i>
                          <span className='ml-2 detail-drop-down'>Fallidos</span>
                        </div>
                      </>}
                      <div onClick={() => { getParametros(dataLista.idGrupo) }}>
                        <i className='ml-2 fa fa-file-text notify-color'></i>
                        <span className='ml-2 detail-drop-down'>Parametros</span>
                      </div>
                      <i></i>
                  </div>
                </div>
                )}
              </div>
              
                <TableContactos permisosListas = {permisoListass} mostrarMensaje={mostrarMensaje} tipoGrupo={dataLista.canal} setSpiner={setSpiner} key={`tableGrupo_${dataLista.idGrupo}`} totalDestinatarios={dataLista.totalDestinatarios} listaDestinatarios={dataLista.listaDestinatarios} idGrupoTable={dataLista.idGrupo} handleModalAgg={handleModalAgg} deleteDestinatario={deleteDestinatario} />
            </div>
  )
}

export default Listas

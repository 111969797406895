/* eslint-disable object-shorthand */
/* eslint-disable padded-blocks */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-expressions */
/* eslint-disable semi */
/* eslint-disable prefer-const */
/* eslint-disable space-before-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable no-trailing-spaces */
/* eslint-disable keyword-spacing */
/* eslint-disable react/prop-types */
/* eslint-disable indent */
/* eslint-disable multiline-ternary */
import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate';
import ReactTooltip from 'react-tooltip';
import helpServices from '../../../services/helper'
import gruposServices from '../../../services/contact'
import $ from 'jquery'
const TableContactos = ({ permisosListas, permisosSegmentos, listaDestinatarios, idGrupoTable, handleModalAgg, deleteDestinatario, totalDestinatarios, setSpiner, tipoGrupo, mostrarMensaje }) => {
    const [initPage, setInitPage] = useState(0)
    const [pageActual, setPageActual] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [perPage, setPerPage] = useState(5)
    const [nombreBusqueda, setNombreBusqueda] = useState('')
    const [destinatariosData, setDestinatarios] = useState([])
    const [processData, setProcessData] = useState(false)
    const [permisosListass, setPermisosListass] = useState(permisosListas)
    const [permisosSegmentoss, setPermisosSegmentoss] = useState(permisosSegmentos)
    
    useEffect(() => {
      let totalPageResult = totalDestinatarios / perPage;
      console.log('setTotalInit', totalPageResult);
      setTotalPage(Math.ceil(totalPageResult))
      setDestinatarios(listaDestinatarios)
      setProcessData(true)
    }, [listaDestinatarios])
    const obSe = {
          idGrupo: idGrupoTable,
          filtro: 'fecha',
          nombreBusqueda: '',
          pagina: 1,
          size: 5
    }
      
    const searchPerPage = (initPageS, value) => {
      
      setInitPage(initPageS)
      setPageActual(Number(initPageS) + 1)
      setProcessData(false)

      obSe.size = Number(value);
      obSe.nombreBusqueda = nombreBusqueda;
      obSe.pagina = 1;

      getDataDestinatarios()
      console.log('initPageS', initPageS);
    }
    const searchByName = (event, initPageS) => {
      console.log('event', event);
      let valid = helpServices.validaNombres(event)
      if (!valid) return event.preventDefault();
      
      if (event.charCode === 13) {
        console.log('value', event.target.value);
        setPageActual(initPageS + 1)
        setInitPage(initPageS)

        obSe.nombreBusqueda = event.target.value;
        obSe.size = perPage;
        obSe.pagina = 1;

        getDataDestinatarios()
        console.log('initPage', initPage);
        setProcessData(false)
      }
    }
    const handlePageClick = (event) => {
      console.log('event.selected', event);
      if(event.nextSelectedPage !== undefined){
        setInitPage(event.nextSelectedPage)
        setPageActual(event.nextSelectedPage + 1)
        obSe.nombreBusqueda = nombreBusqueda;
        obSe.size = perPage;
        obSe.pagina = event.nextSelectedPage + 1;
        getDataDestinatarios()
      }
    }
    const refreshData = (initPageS, name, perPageS) => {

      setInitPage(initPageS)
      setNombreBusqueda(name)
      setPerPage(perPageS)

      obSe.nombreBusqueda = '';
      obSe.size = 5;
      obSe.pagina = 1;

      getDataDestinatarios()
    }
    const getDataDestinatarios = async () => {
      
      console.log('objetoSend', obSe);
      let params = helpServices.formatUrlParams(obSe)
      setSpiner(true)
      let det = await gruposServices.getDestinatariosXGrupo(params)

      let objMessage = helpServices.getMessage(det.data)
      console.log('objMessage', objMessage)

      if(objMessage.code === 200){
        let totalPageResult = det.data.data.totalItems / obSe.size;
        console.log(totalPageResult);
        setTotalPage(Math.ceil(totalPageResult))
        if(det.data.data.rows){
          setDestinatarios(det.data.data.rows)
        }else{
          setDestinatarios([])
        }
        $(`#cantGrupo_${idGrupoTable}`).html(det.data.data.totalItems)
      }else{
        mostrarMensaje(objMessage.code, objMessage.message)
      }
      
      setProcessData(true)
      setSpiner(false)
      console.log($(`#cantGrupo_${idGrupoTable}`))
      console.log('destinatariosData', destinatariosData);
      // let totalPageResult = listaDestinatarios.length / perPage;
      
    }
  return (
    <div className='content-table'>
    <div className='search-table row' >
      <div className='col-md-4 search-perPage'>
        <label className='mr-2'> Mostrando: </label>
      <select onChange={({ target }) => { setPerPage(target.value); searchPerPage(0, target.value); } }                                   
              value={perPage} 
              id='selectPerPage' 
              className="form-control item styled-select mr-2" >
            <option value="5" >5</option>
            <option value="10" >10</option>
            <option value="15" >15</option>
        </select> <label>Registros </label> 
      </div>
      <div className='search-name col-md-8'>
                <div className="row no-gutters align-items-center">
                  <div className="col col-md-12">
                    <input onKeyPress={(event) => { searchByName(event, 0) }} onChange={({ target }) => setNombreBusqueda(target.value)} value={nombreBusqueda} type='text' className='input_buscar pl-3' placeholder='Buscar por Nombre'></input>
                  </div>
                  <div className="col-auto contacts-search">
                    <a className="btn text-dark border-0 rounded-pill disabled-a">
                      <i className="fa fa-search disabled-a grey-color"></i>
                    </a>
                  </div>
                </div>
          
          <i id={`refreshDest_${idGrupoTable}`} onClick={() => { refreshData(0, '', 5) }} className='fa fa-sync-alt fa-sync-color ml-2 d-none'></i>
      </div>
    </div>
    <table id='grupos-table' className="table">
      <thead>
        <tr>
          <th>Mail</th>
          <th>Teléfono</th>
          <th>Nombre</th>
          <th>Apellido</th>
          <th>Fecha de Nacimiento</th>
          <th>Fecha de Creación</th>
          {permisosListass || permisosSegmentoss ? (
              
          <th>Opciones</th>
          ) : null }
        </tr>
      </thead> 
      <tbody>
      { destinatariosData.length > 0 ? 
        destinatariosData.map((destinatario, index) => {
          return(
            <React.Fragment key={`grupo_${index}`}>
              <tr>
                <td>
                    {destinatario.correo.length > 25 ?
                        <>
                          {/* <div className='ajust-text' data-tip={`<p class="content-tool"> ${destinatario.correo.toLowerCase()}</p>`} data-html={true}> */}
                          <div className='ajust-text' data-tip={destinatario.correo.toLowerCase()} data-html={true}>
                              {destinatario.correo.toLowerCase()}
                              <ReactTooltip place="bottom" type="dark" effect="solid"/>
                          </div>

                          {/* <div data-tip={`<p></p>`}>
                          
                              {/* <ReactTooltip data-html={true} width={'180px'} className='custom-tooltip' multiline={ true } place="bottom" type="dark" effect="solid"/> }
                              
                          </div> */}
                        </>
                      :
                      <>
                      <span>{destinatario.correo.toLowerCase()}</span>
                      </>
                    }
                </td>
                <td >{destinatario.telefonoMovil}  </td>
                <td >
                  {destinatario.primerNombre.length > 25 ?
                    <>
                      
                      <div className='ajust-text' data-tip={destinatario.primerNombre} >
                        <ReactTooltip place="bottom" type="dark" effect="solid"/>
                        {destinatario.primerNombre}
                      </div>
                    </>
                  :
                  <>
                  <span>{helpServices.toTitleCase(destinatario.primerNombre)}</span>
                  </>
                  }
                  
                </td>
                <td>
                  {destinatario.primerApellido.length > 25 ?
                      <>
                        <div className='ajust-text' data-tip={helpServices.toTitleCase(destinatario.primerApellido)} >
                          <ReactTooltip place="bottom" type="dark" effect="solid"/>
                          {helpServices.toTitleCase(destinatario.primerApellido)}
                        </div>
                      </>
                    :
                    <>
                    <span>{helpServices.toTitleCase(destinatario.primerApellido)}</span>
                    </>
                  }
                </td>
                <td >{destinatario.fechaNacimiento}  </td>
                <td >
                    {destinatario.fechaCreacion.split(' ')[0]} <br/>
                    {destinatario.fechaCreacion.split(' ')[1]}
                </td>
                {permisosListass || permisosSegmentos ? (
                  <td >
                    <div className="dropdown">
                      <a type="text" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i className="fa fa-ellipsis-v fa-color"></i>
                      </a>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <div onClick={() => { handleModalAgg('E', idGrupoTable, destinatario, tipoGrupo) }} >
                            <i style={{ fontSize: '15px' }} className="ml-2 fas fa-pen notify-color"></i>
                            <span className='ml-3 detail-drop-down'>Editar</span>
                          </div>
                          <div onClick={() => { deleteDestinatario(idGrupoTable, destinatario.idDestinatario) }}>
                            <i className='ml-2 far fa-trash-alt notify-color'></i>
                            <span className='ml-3 detail-drop-down'>Eliminar</span>
                          </div>
                          <i></i>
                      </div>
                    </div>
                  </td>
                ) : null}
                
              </tr>
            </React.Fragment>
          )
        })
        : 
        <tr>
            <td colSpan={7}>
                <span>No hay contactos agregados</span>
            </td>
        </tr>
      } 
      </tbody> 
    </table>
    <div className='content-paginate d-flex'>
      { (processData && totalPage > 0) &&
       <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onClick={ (event) => { handlePageClick(event) } }
          pageRangeDisplayed={1}
          pageCount={totalPage}
          previousLabel="<"
          initialPage={initPage}
          // forcePage={initPage}
          className="paginate-custom"
          renderOnZeroPageCount={null}
        />
      }
       
    </div>
  </div>
  )
}

export default TableContactos

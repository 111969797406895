/* eslint-disable space-before-function-paren */
/* eslint-disable quotes */
/* eslint-disable no-trailing-spaces */
/* eslint-disable semi */
/* eslint-disable prefer-const */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import date from '../../services/global'
import ModalCampaignConfirmacion from '../../components/util/ModalCampaignConfirmacion'
import Toast from '../../components/util/Toast'
import serviceReport from '../../services/report'
import helpServices from '../../services/helper'
import $, { event } from 'jquery'
import ModalSpiner from '../../components/util/ModalSpiner'
import ModalReporteHistorico from '../../components/util/ModalReporteHistorico'
import ReactTooltip from 'react-tooltip'
import loginServices from '../../services/login'
import { DateTime, Duration } from 'luxon'

const Historico = () => {
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const [isOpenToast, setIsOpenToast] = useState(false)
    const [messageToast, setMessageToast] = useState('')
    const [codigoToast, setCodigoToast] = useState(400)
    const [spiner, setSpiner] = useState(false)
    const [dataReport, setDataReport] = useState([])
    const [dataReportT, setDataReportT] = useState([])
    const [dataReportEvento, setDataReportEvento] = useState([])
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [tipo, setTipo] = useState('')
    const [ticket, setTicket] = useState('')
    const d = date.getDate()
    const [isOpen, setIsOpen] = useState(false)
    const [header, setHeader] = useState()
    const [classbody, setClassbody] = useState()
    const [color, setColor] = useState()
    const [body, setBody] = useState()
    const [button, setButton] = useState()
    const [buttonCerrar, setButtonCerrar] = useState()
    const [handle, setHandle] = useState()
    const [btnAceptar, setBtnAceptar] = useState()
    const [processData, setProcessData] = useState(false)
    const [initPage, setInitPage] = useState(0)
    const [pageActual, setPageActual] = useState(1)
    const [perPage, setPerPage] = useState(5)
    const [nombreBusqueda, setNombreBusqueda] = useState('')
    const [totalPage, setTotalPage] = useState(0)
    const [valor, setValor] = useState('select')
    const [isOpen2, setIsOpen2] = useState()
    const [color2, setcolor2] = useState()
    const [header2, setHeader2] = useState()
    const [handle2, setHandle2] = useState()
    const [idTicket, setIdTicket] = useState()
    const [asunto, setAsunto] = useState()
    const [para, setPara] = useState()
    const [tipoMail, setTipoMail] = useState()
    const [estado, setEstado] = useState()
    const [fechaProgramacion, setFechaProgramacion] = useState()
    const [fechaEnvio, setFechaEnvio] = useState()
    const [eventos, setEventos] = useState([])
    const [conCopia, setConCopia] = useState()
    const [conCopiaOculta, setConCopiaOculta] = useState()
    const [mensajeError, setMensajeError] = useState()
    const [formatFecha, setFormatFecha] = useState(false)
    const [horasDiff, setHorasDiff] = useState(0)
   const obSe = {
       filtro: 'fecha',
       nombreBusqueda: '',
       pagina: 1,
       size: 5
    }
    useEffect(() => {
      $('#ticket').prop('disabled', true)
      $('#fechaInicio').prop('disabled', true)
      $('#fechaFin').prop('disabled', true)
      $('#correo').prop('disabled', true)
      $('#generar').prop('disabled', true)

      servicioParameter(37)
      setDataReport([])
    }, [])

    const servicioParameter = async (idGrupo) => {
      const parametro = await loginServices.getParametrosPorId(idGrupo)
      if ('' + parametro.data.data.row.valor === 'S') {
        console.log(parametro)
        setFormatFecha(true)
        
        const horaEc = obtenerFechaHoraActual('America/Guayaquil');
        const horaServ = obtenerFechaHoraActual(parametro.data.data.row.nombre);
        const horasDif = diferenciaEnHoras(horaServ, horaEc)
        setHorasDiff(horasDif)

        console.log(horasDif)
        console.log(restarHoras('2024-01-11 11:40:00', horasDif))
        console.log('parametro:', parametro.data.data.row) 
      }
    }

    function restarHoras (fechaStr, horas) {
        // Dividir la cadena de fecha en partes
        let partesFecha = fechaStr.split(" ");
        let fechaPartes = partesFecha[0].split("-");
        let horaPartes = partesFecha[1].split(":");
    
        // Construir el objeto Date con las partes de la fecha
        let fechaProgramacion = new Date(
            parseInt(fechaPartes[0], 10),
            parseInt(fechaPartes[1], 10) - 1,
            parseInt(fechaPartes[2], 10),
            parseInt(horaPartes[0], 10),
            parseInt(horaPartes[1], 10),
            parseInt(horaPartes[2], 10)
        );
    
        // Verificar si la construcción fue exitosa
        if (isNaN(fechaProgramacion.getTime())) {
            throw new Error('Formato de fecha no válido');
        }
    
        // Restar las horas
        fechaProgramacion.setHours(fechaProgramacion.getHours() - horas);
    
        // Formatear la nueva fecha
        let nuevaFechaFormateada =
            fechaProgramacion.getFullYear() +
            '-' +
            ('0' + (fechaProgramacion.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + fechaProgramacion.getDate()).slice(-2) +
            ' ' +
            ('0' + fechaProgramacion.getHours()).slice(-2) +
            ':' +
            ('0' + fechaProgramacion.getMinutes()).slice(-2) +
            ':' +
            ('0' + fechaProgramacion.getSeconds()).slice(-2);
    
        return nuevaFechaFormateada;
    }

    const obtenerFechaHoraActual = (zonaHoraria) => {
      // Obtener la fecha y hora actual en UTC
      const fechaHoraActualUTC = DateTime.utc();

      // Convertir a la zona horaria deseada
      const fechaHoraZonaHoraria = fechaHoraActualUTC.setZone(zonaHoraria);

      // Formatear la fecha y hora
      const formatoFechaHora = 'yyyy-MM-dd HH:mm:ss';
      const fechaHoraFormateada = fechaHoraZonaHoraria.toFormat(formatoFechaHora);

      return fechaHoraFormateada;
    };

    function diferenciaEnHoras(fechaStr1, fechaStr2) {
        // Dividir las cadenas de fecha en partes
        let partesFecha1 = fechaStr1.split(/[- :]/);
        let partesFecha2 = fechaStr2.split(/[- :]/);

        // Construir objetos Date con las partes de la fecha
        let fecha1 = new Date(
            partesFecha1[0],
            partesFecha1[1] - 1,
            partesFecha1[2],
            partesFecha1[3],
            partesFecha1[4],
            partesFecha1[5]
        );

        let fecha2 = new Date(
            partesFecha2[0],
            partesFecha2[1] - 1,
            partesFecha2[2],
            partesFecha2[3],
            partesFecha2[4],
            partesFecha2[5]
        );

        // Calcular la diferencia en milisegundos
        let diferenciaEnMs = fecha2 - fecha1;

        // Calcular la diferencia en horas
        let diferenciaEnHoras = diferenciaEnMs / (1000 * 60 * 60);

        return Math.abs(diferenciaEnHoras); // Devolver el valor absoluto de la diferencia en horas
    }
  
    const handleGenerar = () => {
      if (valor === 'ticket') {
        if (ticket === '') {
          $('#ticket').addClass('error')
        mostrarMensaje('400', 'Campo ticket obligatorio')
        } else {
          setSpiner(true)
          getDataReport()
        }
      } else if (valor === 'correo') {
        if ((fechaInicio === '' && fechaFin === '') || ('' + fechaInicio === 'null' && '' + fechaFin === 'null')) {
          global.LABEL_INPUT_ERROR = global.CAMPAIGN_FECHA
          $('#fechaInicio').addClass('error')
          $('#fechaFin').addClass('error')
          $('#correo').addClass('error')
          mostrarMensaje('400', global.LABEL_INPUT_ERROR)
        } else if (fechaInicio > fechaFin) {
          $('#fechaInicio').addClass('error')
          mostrarMensaje('400', 'Su rango de fecha es inválido')
        } else {
          $('#fechaInicio').removeClass('error')
          $('#fechaFin').removeClass('error')
          $('#correo').removeClass('error')
          setSpiner(true)
          getDataReport()
        }
      } else if (valor === 'select') {
        mostrarMensaje('400', 'Debe selecionar una opcion')
      }
      }
      const mostrarMensaje = (codigo, mensaje) => {
        setCodigoToast(codigo)
        setMessageToast(mensaje)
        setIsOpenToast(true)
        setTimeout(() => {
          setIsOpenToast(false)
        }, 1000)
      }
      const getDataReport = async () => {
        setSpiner(true)
        const serviceRe = await serviceReport.getReporteHistorico({ ticket, tipo, fechaInicio, fechaFin, obSe })
        let objMessage = helpServices.getMessage(serviceRe.data)
        setSpiner(false)
        if (serviceRe.data.code === 200) {
          console.log(serviceRe)
           if (serviceRe.data.data.length > 0) {
                setProcessData(true)
                if (serviceRe.data.data.length > 0) {
                  if (formatFecha) {
                    serviceRe.data.data.forEach(element => {
                      element.fechaEnvio = restarHoras(element.fechaEnvio, horasDiff)
                      if (element.eventos.length > 0) {
                        element.eventos.forEach(evento => {
                          evento.fechaEvento = restarHoras(evento.fechaEvento, horasDiff)
                        });
                      }
                    });
                  }
                }
                
                setDataReport(serviceRe.data.data)
                if (tipo === 'T') {
                  setDataReportT(serviceRe.data.data[0])
                  setDataReportEvento(serviceRe.data.data[0].eventos)
                }
                // console.log(serviceRe.data.data.e)
                setTotal(serviceRe.data.data.total)
                setTotalPage(Math.ceil(serviceRe.data.data.total / obSe.size))
          } else {
            setDataReport([])
            mostrarMensaje(serviceRe.code, 'No hay registros.')
          }
        } else {
          mostrarMensaje(serviceRe.code, serviceRe.data.message)
        }
      }
      console.log(dataReport)
      const select = (value, index) => {
        setValor(value)
        if (value === 'ticket') {
          $('#fechaInicio').removeClass('error')
          $('#fechaFin').removeClass('error')
          $('#ticket').prop('disabled', false)
          $('#fechaInicio').prop('disabled', true)
          $('#fechaFin').prop('disabled', true)
          setFechaInicio('')
          setFechaFin('')
          setTicket('')
          setDataReport([])
          setDataReportT([])
          setTipo('T')
          $('#ticketO').removeClass('ocultar')
          $('#correoO').addClass('ocultar')
        } else if (value === 'correo') {
          $('#ticket').prop('disabled', false)
          $('#fechaInicio').prop('disabled', false)
          $('#fechaFin').prop('disabled', false)
          setTipo('C')
          setFechaInicio('')
          setFechaFin('')
          setTicket('')
          setDataReportT([])
          setDataReport([])
          $('#correoO').removeClass('ocultar')
          $('#ticketO').addClass('ocultar')
        } else if (value === 'select') {
          $('#fechaInicio').prop('disabled', true)
          $('#fechaFin').prop('disabled', true)
          $('#ticket').prop('disabled', true)
          $('#correoO').addClass('ocultar')
          $('#ticketO').addClass('ocultar')
          setFechaInicio('')
          setFechaFin('')
          setTicket('')
          setDataReport([])
          setDataReportT([])
        }
      }
      const report = (index) => {
        console.log('entroooo')
        console.log(index)
        if (dataReport !== []) {
          setIsOpen2(true)
          setHandle2(cerrar)
          setIdTicket(dataReport[index].idTicket)
          setAsunto(dataReport[index].asunto)
          setPara(dataReport[index].para)
          if (dataReport[index].tipoMail === 'M') {
            setTipoMail('Mailing')
          }
          if (dataReport[index].estado === 'E') {
            setEstado('Exitoso')
          } else if (dataReport[index].estado === 'R') {
            setEstado('Rebotado')
          } else if (dataReport[index].estado === 'F') {
            setEstado('Fallido')
          }
          setEventos(dataReport[index].eventos)
          setFechaProgramacion(dataReport[index].fechaProgramacion)
          setFechaEnvio(dataReport[index].fechaEnvio)
          setConCopia(dataReport[index].conCopia)
          setConCopiaOculta(dataReport[index].conCopiaOculta)
          setMensajeError(dataReport[index].mensajeError)
          setHeader2('Detalle de Ticket')
          setcolor2('notify-color')
        }
      }
      const cerrar = () => () => {
        setIsOpen2(false)
      }
    return (
        <><><div className="container-fluid p-0">
        <ModalSpiner opt={spiner}></ModalSpiner>
        <ModalReporteHistorico isOpen={isOpen2} color = {color2} header = {header2} handle = {handle2} idTicket = {idTicket} asunto = {asunto} para = {para} tipoMail = {tipoMail} estado = {estado}
        fechaProgramacion = {fechaProgramacion} fechaEnvio = {fechaEnvio} eventos = {eventos} mensajeError = {mensajeError} conCopia = {conCopia} conCopiaOculta = {conCopiaOculta} ></ModalReporteHistorico>
        {isOpenToast && <Toast message={messageToast} codigo={codigoToast} />}
        <ModalCampaignConfirmacion isOpen={isOpen} classbody={classbody} color={color} header={header} body={body} button={button} buttonCerrar={buttonCerrar} handle={handle} btnAceptar={btnAceptar} />
        <div className='mb-12 panel-group pt-3 pb-3 header-report mb-3'>
          <div className='d-flex'>
            <div className='col-5 p-0'>
              <span className='campaing-tittle'><b>Reporte Historico</b></span>
            </div>
          </div>
        </div>
        <select onChange={({ target }) => select(target.value)} className='form-control ml-2 pt-lg-1' style={{ width: '200px' }}>
          <option value='select'>Seleccione una opcion</option>
          <option value='ticket'>Ticket</option>
          <option value='correo'>Correo</option>
        </select>
        <div className='row pt-2'>
          <div className='form-group col-md-3'>
            <span className="text-input name-input every-label" id='error_ticket'></span>
            <label className='grey-color'>Ticket o Correo</label>
            <input id='ticket'
              value={ticket}
              type="text"
              max={d}
              onChange={({ target }) => setTicket(target.value)}
              className="form-control item"></input>
          </div>
          <div className='form-group col-md-3'>
            <span className='text-input name-input every-label' id='error_fecha'></span>
            <label className='grey-color'>Fecha de inicio</label>
            <input id='fechaInicio'
              value={fechaInicio}
              type='date'
              max={d}
              onChange={({ target }) => setFechaInicio(target.value)}
              className='form-control item'></input>
          </div>
          <div className='form-group col-md-3'>
            <span className='text-input name-input every-label' id='error_fecha'></span>
            <label className='grey-color'>Fecha de finalización</label>
            <input id='fechaFin'
              value={fechaFin}
              type='date'
              max={d}
              onChange={({ target }) => setFechaFin(target.value)}
              className='form-control item'></input>
          </div>
          <div className='form-group col-md-3 button-generate report-listas'>
            <a type='button' id='generar' onClick={() => { handleGenerar() } } className='btn btn-success btn-campania contacts report-btn'>
              <b>
                Generar
              </b>
            </a>
          </div>
        </div>
      </div>
      <div className='panel pt-2 pb-2 header-report mb-3 data-report'>
          <div className='d-flex align-items-center'>
            <div className='col-5 p-0'>
              <span className='campaing-tittle'><b>Historial de Reportes Historico</b></span>
            </div>
          </div>
        </div></>
        <div className='mb-12 panel-group listar'>
        <div className="table-container">
      <div className="table-header-column row ocultar" id='ticketO'>
          <div className='ml-4 mb-2'><b>Ticket: </b>{dataReportT.idTicket} </div>
        <div className='row ml-1'>
          <div className='form-group col-md-6'>
            <label className='grey-color col-3 mb-0'>Para:</label>
            <input className="imput qNegrita " disabled value={dataReportT.para}></input>
          </div>
          <div className='form-group col-md-6'>
            <label className='grey-color col-3 mb-0'>Asunto:</label>
            <input className="imput qNegrita " disabled value={dataReportT.asunto}></input>
          </div>
          <div className='form-group col-md-6'>
            <label className='grey-color col-4 mb-0'>Tipo Envio:</label>
            <input className="imput qNegrita " disabled value={dataReportT.tipoMail === 'M' ? 'Mailing' : dataReportT.tipoMail === 'F' ? 'Fallido' : dataReportT.tipoMail === 'R' ? 'Rebotado' : ''}></input>
          </div>
          <div className='form-group col-md-6'>
            <label className='grey-color col-3 mb-0'>Estado:</label>
            <input className="imput qNegrita " disabled value={dataReportT.estado === 'E' ? 'Enviado' : dataReportT.estado === 'F' ? 'Fallido' : dataReportT.estado === 'R' ? 'Rebotado' : ''}></input>
          </div>
          <div className='form-group col-md-6'>
            <label className='grey-color'>Fecha de Programación:</label>
            <input className="imput qNegrita " disabled value={dataReportT.fechaProgramacion}></input>
          </div>
          <div className='form-group col-md-6'>
            <label className='grey-color'>Fecha de Envio:</label>
            <input className="imput qNegrita " disabled value={dataReportT.fechaEnvio}></input>
          </div>
          <div className='form-group col-md-6'>
            <label className='grey-color col-3 mb-0'>Con copia:</label>
            <input className="imput qNegrita " disabled value={dataReportT.conCopia}></input>
          </div>
          <div className='form-group col-md-6'>
            <label className='grey-color'>Con copia oculta:</label>
            <input className="imput qNegrita " disabled value={dataReportT.conCopiaOculta}></input>
          </div>
          <div className='form-group col-md-6 mb-5'>
            <label className='grey-color col-3 mb-0'>Detalle Error:</label>
            <input className="imput qNegrita " disabled value={dataReportT.mensajeError}></input>
          </div>
        </div>
        <div className='p-0'>
          <span className='campaing-tittle'><b>Eventos</b></span>
        </div>
        <div className='mb-12 panel-group data-report listar-report'>
        <table id='prog-table-report' className='table'>
          <thead>
              <tr>
                <th>Fecha del Evento</th>
                <th>Tipo de Evento</th>
                <th>Detalle del Evento</th>
                <th>Cantidad de Eventos</th>
              </tr>
          </thead>
          <tbody id='tbody'>
              {dataReportEvento.length >= 0
              ? dataReportEvento.map((val, index) => {
                return (<React.Fragment key={val.index}>
                  <tr>
                    <td>{val.fechaEvento}</td>
                    <td>{val.tipo}</td>
                    <td>{val.detalle}</td>
                    <td>1</td>
                  </tr>
                </React.Fragment>)
              }
              )
              : <tr>
                <td colSpan={4}>
                  <span>No hay información</span>
                </td>
              </tr>
            }
            </tbody>
        </table>
        </div>
      </div>
      <div className='mb-12 panel-group data-report listar-report ocultar' id='correoO'>
          <table id='prog-table-report' className='table'>
            <thead>
              <tr>
                <th>Ticket</th>
                <th>Asunto</th>
                <th>Para</th>
                <th>Tipo Envio</th>
                <th>Estado</th>
                <th>Fecha de Programación</th>
                <th>Fecha de Envio</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody id='tbody'>
              {dataReport.length > 0
              ? dataReport.map((val, index) => {
                return (<React.Fragment key={val.index}>
                  <tr>
                    <td>{val.idTicket}</td>
                    <td>{val.asunto}</td>
                    <td>{val.para}</td>
                    <td>{val.tipoMail === 'T' ? 'Mailing y mensajeria instantanea' : val.tipoMail === 'M' ? 'Mailing' : val.tipoMail === 'E' ? 'Whatsapp' : ''}</td>
                    <td>{val.estado === 'E' ? 'Enviado' : val.estado === 'F' ? 'Fallido' : val.estado === 'R' ? 'Rebotado' : ''}</td>
                    <td>{val.fechaProgramacion}</td>
                    <td>{val.fechaEnvio}</td>
                    <td >
                                    <div className="dropdown">
                                      <a type="text" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <i className="fa fa-ellipsis-v fa-color"></i>
                                      </a>
                                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          { <div onClick={() => { report(index) }} >
                                            <i className='ml-2 far fa-eye notify-color'></i>
                                            <span className='ml-3 detail-drop-down'>Ver</span>
                                          </div> }
                                      </div>
                                    </div>
                                       </td>
                  </tr>
                </React.Fragment>)
              }
              )
              : <tr>
                <td colSpan={8}>
                  <span>No hay información</span>
                </td>
              </tr>
            }
            </tbody>
          </table>
        </div>
    </div>
        </div></>
    )
}
export default Historico

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import '../../scss/_templates.scss'
import TemplastesDatatTest from '../../static/templatesTest.json'
import TemplatesHistory from './TemplatesHistory'

const Templates = () => {
  const [templatesTest, setTemplastesTest] = useState([])
  const [active, setActive] = useState(1)
  useEffect(() => {
    const dataTest = TemplastesDatatTest
    setTemplastesTest(dataTest)
    setActive(1)
    if (!localStorage.getItem('menu')) {
      window.localStorage.setItem(
        'menu', JSON.stringify(1)
      )
    }
    window.localStorage.setItem('menu', 3)
  }, [])
  return (
        <div className='container-fluid-master'>
            <TemplatesHistory />
        </div>
  )
}

export default Templates

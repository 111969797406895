/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable semi */
import React, { useEffect, useState } from "react";
import ModalNewMessages from "../../components/util/ModalCampaignCreate";
import $ from "jquery";
import "../../../src/scss/_campaaign-old.scss";
// import SweetPagination from "sweetpagination";
import campaignServices from "../../services/campaign";
import Toast from "../../components/util/Toast";
import ReactPaginate from "react-paginate";
import ReactTooltip from "react-tooltip";
import helpServices from "../../services/helper";
import ModalSpiner from "../../components/util/ModalSpiner";
const CampaignStep2 = () => {
  const [listCamp, setListaCamp] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [header, setHeader] = useState("");
  const [label1, setLabel1] = useState("");
  const [label2, setLabel2] = useState("");
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [coloHeader, setColorHeader] = useState("");
  const [modalButton, setModalButton] = useState("");
  const [handle, setHandle] = useState();
  const [search, setSearch] = useState("");
  const [totalPage, setTotalPage] = useState(1)
  const [initPage, setInitPage] = useState(0)
  const [processData, setProcessData] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [perPage, setPerPage] = useState(9)
  // eslint-disable-next-line no-unused-vars
  const [pageActual, setPageActual] = useState(1)
  // eslint-disable-next-line no-unused-vars
  const [contDet, setContDet] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [contCol, setContCol] = useState(1);
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  const [on, setOn] = useState()
  const [sechData, setSechData] = useState()
  const [spiner, setSpiner] = useState(false);
 // const [currentPageData, setCurrentPageData] = useState(new Array(2).fill());
  window.localStorage.setItem('menu', 5)
  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000)
  }
  useEffect(() => {
    const s = window.localStorage.getItem("steps");
    if (s === "2") {
      window.localStorage.setItem("señal", 0)
      $("#labelCampana").html(
        "<b>Campaña: </b>" + window.localStorage.getItem("camp")
      );
      $("#panel-info1").html(
        "<b>Objetivo: </b>" + window.localStorage.getItem("descamp")
      );
      $('#scroll-2').addClass('scroll scroll-h-camp scrollbar')
      $("#circle2").addClass("circle-color");
      $("#chevron2").addClass("chevron-color");
      $("#panel-1").removeClass();
      $("#panel-1").addClass("no-success-panel");
      $("#panel-2").removeClass();
      $("#panel-2").addClass("no-success-panel");
      $("#panel-info").removeClass();
      $("#panel-info").addClass("success-panel");
      $("#panel-contenido").removeClass();
      $("#panel-contenido").addClass("success-panel");
      $("#underlined-position").removeClass();
      $("#underlined-position").addClass("underlined-ajus2");
      $("#panel-section2").removeClass();
      $("#panel-section2").addClass("no-success-panel");
      if (window.localStorage.getItem('campignTemplate') === '1') {
        $('#rectangu1').click()
      }
      if (window.localStorage.getItem('campignTemplate') === '2') {
        $('#rectangu2').click()
      }
      if (window.localStorage.getItem('campignTemplate') === '3') {
        $('#rectangu3').click()
      }
    } else if (s === "3") {
      window.localStorage.setItem("señal", 0)
      $("#labelCampana").html(
        "<b>Campaña: </b>" + window.localStorage.getItem("camp")
      );
      $("#panel-info1").html(
        "<b>Objetivo: </b>" + window.localStorage.getItem("descamp")
      );
      $("#panel-1").removeClass();
      $("#panel-1").addClass("no-success-panel");
      $("#circle2").addClass("circle-color");
      $("#chevron2").addClass("chevron-color");
      $("#panel-info").removeClass();
      $("#panel-info").addClass("success-panel");
      $("#circle3").addClass("circle-color");
      $("#chevron3").addClass("chevron-color");
      $("#panel-section1").removeClass();
      $("#panel-section1").addClass("no-success-panel");
      $("#panel-contenido").removeClass();
      $("#panel-contenido").addClass("no-success-panel");
      $("#panel-section2").removeClass();
      $("#panel-section2").addClass("success-panel");
      $("#underlined-position").removeClass();
      $("#underlined-position").addClass("underlined-ajus3");
      $("#cargaTabla").val(1)
    } else if (window.localStorage.getItem("steps") === '4') {
      window.localStorage.setItem("señal", 0)
      window.localStorage.setItem("l", 0)
      window.localStorage.setItem("set", 4)
      $('input[name=id_' + window.localStorage.getItem('idPlanSteps2') + ']').prop('checked', true)
      $("#labelCampana").html(
        "<b>Campaña: </b>" + window.localStorage.getItem("camp")
      );
      $("#panel-info1").html(
        "<b>Objetivo: </b>" + window.localStorage.getItem("descamp")
      );
      $("#panel-info").removeClass();
      $("#panel-info").addClass("success-panel");
      $("#circle2").addClass("circle-color");
      $("#chevron2").addClass("chevron-color");
      $('#circle3').addClass('circle-color')
      $('#chevron3').addClass('chevron-color')
      $("#panel-1").removeClass();
      $("#panel-1").addClass("no-success-panel");
      $('#circle4').addClass('circle-color')
      $('#chevron4').addClass('chevron-color')
      $('#circle4').addClass('circle-color')
      $('#panel-section2').removeClass()
      $('#panel-section2').addClass('no-success-panel')
      $('#panel-campa2').removeClass()
      $('#panel-campa2').addClass('success-panel')
      $('#underlined-position').removeClass()
      $('#underlined-position').addClass('underlined-ajus4')
    } else if (window.localStorage.getItem("steps") === '5') {
      $("#labelCampana").html(
        "<b>Campaña: </b>" + window.localStorage.getItem("camp")
      );
      $("#panel-info1").html(
        "<b>Objetivo: </b>" + window.localStorage.getItem("descamp")
      );

      $('#circle5').addClass('circle-color')
      $("#panel-1").removeClass();
      $("#panel-1").addClass("no-success-panel");
      $('#panel-campa2').removeClass()
      $('#panel-campa2').addClass('no-success-panel')
      $('#panel-info').removeClass()
      $('#panel-info').addClass('success-panel')
      $('#panel-info1').removeClass()
      $('#panel-info1').addClass('success-panel')
      $('#panel-campa3').removeClass()
      $('#panel-campa3').addClass('success-panel')
      $('#underlined-position').removeClass()
      $('#underlined-position').addClass('underlined-ajus5')

      $("#panel-info").removeClass();
      $("#panel-info").addClass("success-panel");
      $("#circle2").addClass("circle-color");
      $("#chevron2").addClass("chevron-color");
      $('#circle3').addClass('circle-color')
      $('#chevron3').addClass('chevron-color')
      $("#panel-1").removeClass();
      $("#panel-1").addClass("no-success-panel");
      $('#circle4').addClass('circle-color')
      $('#chevron4').addClass('chevron-color')
      $('#circle4').addClass('circle-color')
    }
    setSpiner(true)
    listsCamp();
    $("#circle1").addClass("circle-color");
    $("#chevron1").addClass("chevron-color");
  }, []);
  const obSe = {
    pagina: 1,
    size: 9
  }
  const listsCamp = async (event) => {
    try {
      const listCamp = await campaignServices.getCampaignTable({});
      if (listCamp.error === 200) {
        setSpiner(false)
        const chunk = []
        const x = listCamp.data.data
        const size = 9
        const totalPageResult = listCamp.data.data.length / obSe.size
        setTotalPage(Math.ceil(totalPageResult))
        setProcessData(true)
        $.each(x, function (i, val) {
          x[i][0] = { id: i + 1 }
        })
        for (let i = 0; i <= x.length; i += size) { // loop que recorre el array
          chunk.push(x.slice(i, i + size));
        }
        if (window.localStorage.getItem('idcamp') !== '') {
          $("input[name=ids_" + window.localStorage.getItem('idcamp') + "]").prop("checked", true);
        }
        setListaCamp(chunk[0])
        $('[data-toggle="tooltip"]').tooltip()
      } else {
        setSpiner(false)
        mostrarMensaje('500', global.LABEL_INPUT_ERROR)
      }
    } catch (e) { }
  }
  const listsCamps = async (event) => {
    try {
      const listCamp = await campaignServices.getCampaignTable({});
      if (listCamp.error === 200) {
        setSpiner(false)
        const chunk = []
        const x = listCamp.data.data
        const size = 9
        const totalPageResult = listCamp.data.data.length / obSe.size
        setTotalPage(Math.ceil(totalPageResult))
        setProcessData(true)
        $.each(x, function (i, val) {
          x[i][0] = { id: i + 1 }
        })
        for (let i = 0; i <= x.length; i += size) { // loop que recorre el array
          chunk.push(x.slice(i, i + size));
        }
        setListaCamp(chunk[event])
        for (let i = 0; i < listCamp.data.data.length; i++) {
          if (chunk[event][i].idCampana === window.localStorage.getItem('idcamp')) {
            $("input[name=ids_" + window.localStorage.getItem('idcamp') + "]").prop("checked", true);
          } else {
            $("input[name=ids_" + chunk[event][i].idCampana + "]").prop("checked", false);
          }
        }
        $('[data-toggle="tooltip"]').tooltip()
      } else {
        setSpiner(false)
        mostrarMensaje('500', global.LABEL_INPUT_ERROR)
      }
    } catch (e) { }
  }
  const clickSearch = async (event) => {
    if (event.keyCode === 13) {
      setSpiner(true)
      setOn(1)
      const chunk = []
      const size = 9
      const list = await campaignServices.getCampaignSeach({
        search,
      });
      if (list.error === 200) {
        setSpiner(false)
        mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
        const x = list.data.data
        const dataList = list.data.data.length / obSe.size
        setTotalPage(Math.ceil(dataList))
        setProcessData(true)
        $.each(x, function (i, val) {
          x[i][0] = { id: i + 1 }
        })
        for (let i = 0; i <= x.length; i += size) {
          chunk.push(x.slice(i, i + size));
        }
        setSechData(chunk)
        setListaCamp(chunk[0])
        if (window.localStorage.getItem('idcamp') !== '') {
          $("input[name=ids_" + window.localStorage.getItem('idcamp') + "]").prop("checked", true);
        }
      } else {
        listsCamps(0)
        mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
      }
    }
  };
  const handlebtn = (op) => () => {
    try {
      if ($("#radio:checked").length === 1) {
        setIsOpen(false);
        window.localStorage.setItem("steps", 2);
        $('#scroll-2').addClass('scroll scroll-h-camp scrollbar')
        $("#circle2").addClass("circle-color");
        $("#chevron2").addClass("chevron-color");
        $("#panel-1").removeClass();
        $("#panel-1").addClass("no-success-panel");
        $("#panel-2").removeClass();
        $("#panel-2").addClass("no-success-panel");
        $("#panel-info").removeClass();
        $("#panel-info").addClass("success-panel");
        $("#panel-contenido").removeClass();
        $("#panel-contenido").addClass("success-panel");
        $("#underlined-position").removeClass();
        $("#underlined-position").addClass("underlined-ajus2");
        let idCampana;
        let campana;
        let description;
        $("input:checkbox[id=radio]:checked").each(function () {
          idCampana = $(this).val();
          campana = $("#camp_" + $(this).val()).html();
          description = $("#descrip" + $(this).val()).html();
        });
        $("#labelCampana").html("<b>Campaña: </b>" + campana);
        window.localStorage.setItem("camp", campana);
        $("#panel-info1").html("<b>Objetivo: </b>" + description);
        window.localStorage.setItem("descamp", description);
        window.localStorage.setItem("idcamp", idCampana);
      } else {
        $("#radio:checked").prop("checked", false);
        global.LABEL_INPUT_ERROR = global.CAVEAT_CAMPAIGN;
        mostrarMensaje('400', global.LABEL_INPUT_ERROR)
      }
    } catch (error) { }
  };
  const handleModal = (op) => () => {
    setIsOpen(true);
    setHeader("Crear nueva campaña");
    setLabel1("Nombre de la campaña");
    setInput1();
    setLabel2("Objetivo de la campaña");
    setInput2();
    setColorHeader();
    setModalButton("Crear");
    setHandle(handleClose);
  };

  const handleClose = () => () => {
    $('#nameCamp').val('')
    $('#textCamp').val('')
    setIsOpen(false)
  };
  const handleComprobacion = (idCampana) => () => {
    $.each(listCamp, function (i, val) {
      if (idCampana === val.idCampana) {
        $("input[name=ids_" + idCampana + "]").prop("checked", true)
        window.localStorage.setItem("idcamp", idCampana);
      } else {
        $("input[name=ids_" + val.idCampana + "]").prop("checked", false)
      }
    });
  }
  const handlePageClick = (event) => {
    if (event.nextSelectedPage !== undefined && on !== 1) {
      setInitPage(event.nextSelectedPage + 1)
      setPageActual(event.nextSelectedPage + 1)
      obSe.size = perPage;
      obSe.pagina = event.nextSelectedPage + 1;
      listsCamps(event.nextSelectedPage)
    } else if (event.nextSelectedPage !== undefined && on === 1) {
      setInitPage(event.nextSelectedPage + 1)
      setPageActual(event.nextSelectedPage + 1)
      obSe.size = perPage;
      obSe.pagina = event.nextSelectedPage + 1;
      setListaCamp(sechData[event.nextSelectedPage])
    }
  }
  const handleKeyPress = (event, valMax) => {
    const valid = helpServices.validaTextoLetras(event)
    const v = helpServices.validaSoloNumeros(event)
    if (!v && !valid) return event.preventDefault()
  }
  const handleOnchange = event => {
    const vali2 = helpServices.verificaSoloTextoNumero(event.target.value)
    if (vali2 === false) {
      mostrarMensaje('400', 'No se permite caracteres especiales')
    } else {
      setSearch(event.target.value)
    }
  }
  return (
    <div className="panel panel-default container-fluid" id="panel-general">
      <ModalSpiner opt={spiner} />
      {isOpenToast && <Toast message={messageToast} codigo={codigoToast} />}
      <ModalNewMessages
        isOpen={isOpen}
        header={header}
        label1={label1}
        input1={input1}
        label2={label2}
        input2={input2}
        color={coloHeader}
        button={modalButton}
        handle={handle}
      />
      <hr />
      <input type="hidden" value="0" id="cargaTabla"></input>
      <div className="row col-md-12">
        <div className="circle-posicion">
          <div className="circle-camp2 col4" id="circle1">
            <b>1</b>
          </div>
          <h6 className="title-steps mr-auto">
            <b>Crear campaña</b>
          </h6>
          <span>
            <i className="fas fa-chevron-right fas-camp" id="chevron1"></i>
          </span>
        </div>

        <div className="circle-posicion ml-auto">
          <div className="circle-camp2 col4" id="circle2">
            <b>2</b>
          </div>
          <h6 className="title-steps mr-auto">
            <b>Canal y plantilla</b>
          </h6>
          <span>
            <i className="fas fa-chevron-right fas-camp" id="chevron2"></i>
          </span>
        </div>
        <div className="circle-posicion ml-auto">
          <div className="circle-camp2 col4 " id="circle3">
            <b>3</b>
          </div>
          <h6 className="title-steps mr-auto">
            <b>Base de contactos</b>
          </h6>
          <span>
            <i className="fas fa-chevron-right fas-camp" id="chevron3"></i>
          </span>
        </div>

        <div className="circle-posicion ml-auto">
          <div className="circle-camp2 col4 " id="circle4">
            <b>4</b>
          </div>
          <h6 className="title-steps mr-auto">
            <b>Programación</b>
          </h6>
          <span>
            <i className="fas fa-chevron-right fas-camp" id="chevron4"></i>
          </span>
        </div>
        <div className="circle-posicion ml-auto">
          <div className="circle-camp2 col4 " id="circle5">
            <b>5</b>
          </div>
          <h6 className="title-steps">
            <b>Revisión</b>
          </h6>
        </div>
      </div>
      <hr className="position-hr" />
      <div className="underlined" id="underlined-position"></div>
      <div className="success-panel" id="panel-1">
        <div className="col-md-3 create-campaign right2 p-create-camp">
          <a
            type="button"
            onClick={handleModal(1)}
            className="btn btn btn-success btn-campania btn-struct"
          >
            <b>
              <i className="fa fa-bullhorn bullhorn"></i> Crear campaña
            </b>
          </a>
        </div>
        <div className="search-position row col-12">
          <div className="col-5 text-left"><b>Seleccione la campaña</b></div>
          <div className="search-campaign text-right col-7">
            <b>Buscar:</b>{" "}
            <input
              className="input_buscar pl-3 pr-3"
              onChange={handleOnchange}
              value={search}
              onKeyDown={(e) => clickSearch(e)}
              onKeyPress={(event) => { handleKeyPress(event, 45) }}
              type="text"
            ></input>
          </div>
        </div>
        <div className="col-12 mt-5">
        <div className="row">
        {listCamp.map((val, key) => {
                return (
                  <React.Fragment key={key}>
                    { (
                      <div className="col-sm-4 mt-2">
                        <div className="card card-step1">
                          <div className="card-body">
                            <div className="card-title row ml-4">
                              <input
                                type="checkbox"
                                className="form-check-input w-check mr-1"
                                id="radio"
                                value={val.idCampana}
                                name={"ids_" + val.idCampana}
                                onClick={handleComprobacion(val.idCampana)}
                              ></input>
                              <div className="ml-2">
                              {val.campana.length > 10
                                  ? <div className='ajust-text' data-tip={`<p class="content-tool"> ${val.campana.toLowerCase()}</p>`} data-html={true}>
                                    <h6>{val[0].id}. {val.campana.substring(0, 11) + '...'}</h6>
                                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                    </div>

                                  : <>
                                  <div><h6>{val[0].id}. {val.campana.toLowerCase()}</h6></div>
                                  </>
                                }
                              </div>
                            </div>
                            <hr></hr>
                            <div
                              className="no-success-panel"
                              id={"camp_" + val.idCampana}
                            >
                              {val.campana}
                            </div>
                            <div
                              className="no-success-panel"
                              id={"descrip" + val.idCampana} data-toggle="tooltip" title={val.descripcion}
                            >
                              {val.descripcion}
                            </div>
                            <div className=" notify-color">
                              Objetivo de la campaña
                            </div>
                            <div>
                              {val.descripcion.length > 30
                                  ? <div className='ajust-text ml-0' data-tip={`<p class="content-tool"> ${val.descripcion.toLowerCase()}</p>`} data-html={true}>
                                    {val.descripcion.toLowerCase()}
                                    <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                    </div>

                                  : val.descripcion.length <= 0
                                  ? <div>
                                    sin datos...
                                  </div>
                                  : <>
                                  <span>{val.descripcion.toLowerCase()}</span>
                                  </>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>

            { processData &&
              <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onClick={ (event) => { handlePageClick(event) } }
                  pageRangeDisplayed={1}
                  pageCount={totalPage}
                  previousLabel="<"
                  initialPage={initPage}
                  // forcePage={initPage}
                  className="paginate-custom mt-2 mb-0"
                  renderOnZeroPageCount={null}
                ></ReactPaginate>
              }

          <div className="btn-posicionsgt btn-sgt-step3 step3-w mt-0">
            <button className="btnsgt mb-5" onClick={handlebtn(1)}>
              Siguiente
            </button>
          </div>
        </div>
      </div>

    </div>
  );
};
export default CampaignStep2;

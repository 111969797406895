/* eslint-disable operator-linebreak */
/* eslint-disable no-trailing-spaces */
/* eslint-disable space-before-blocks */
/* eslint-disable keyword-spacing */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable prefer-const */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable indent */
/* eslint-disable padded-blocks */
import React, { useState, useEffect } from 'react'
import ModalNewMessages from '../../components/util/ModalContactList'
import ModalContactListAgg from '../../components/util/ModalContactListAgg'
import ModalInformacion from '../../components/util/ModalInformacion'
import ModalSpiner from '../../components/util/ModalSpiner'
import Listas from './components/Listas'
import gruposServices from '../../services/contact'
import $ from 'jquery'
import helpServices from '../../services/helper'
import ReactPaginate from 'react-paginate';
import ReactTooltip from 'react-tooltip';
import loginServices from '../../services/login'

const contactLists = ({ mostrarMensaje }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenInfo, setIsOpenInfo] = useState(false)
  const [header, setHeader] = useState('')
  const [label1, setLabel1] = useState('')
  const [modalButton, setModalButton] = useState('')
  const [handle, setHandle] = useState()
  const [dataListas, setListas] = useState([])
  const [dataListasGen, setListasGen] = useState([])
  const [coloHeader, setColorHeader] = useState('')
  const [coloHeader2Ag, setColorHeader2Ag] = useState('')
  const [isOpenAg, setIsOpenAg] = useState(false)
  const [headerAg, setHeaderAg] = useState('')
  const [opcionLista, setOpcionLista] = useState('')
  const [idGrupo, setIdGrupo] = useState('')
  const [destinatarioEdit, setDestinatario] = useState({})
  const [modalButton2Ag, setModalButton2Ag] = useState('')
  const [spiner, setSpiner] = useState(false)
  const [tipoGrupo, setTipoGrupo] = useState('')
  
  const [processData, setProcessData] = useState(true)
  const [initPage, setInitPage] = useState(0)
  const [pageActual, setPageActual] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [perPage, setPerPage] = useState(5)
  const [nombreBusqueda, setNombreBusqueda] = useState('')
  const [totalGrupos, setTotalGrupo] = useState(0)

  const obSe = {
    tipoGrupo: 'G',
    filtro: 'fecha',
    nombreBusqueda: '',
    pagina: 1,
    size: 5
  }

  const handleModalAgg = async (op, idGrupoSend, destinatario, tipoGrupo) => {

    console.log('idGrupo',idGrupoSend);
    console.log('destinatario',destinatario);
    console.log('tipoGrupo',tipoGrupo);
    if (op === 'C') {
      setHeaderAg('Agregar contacto')
      setModalButton2Ag('Crear')
    } else {
      setHeaderAg('Editar contacto')
      setModalButton2Ag('Editar')
      // let idDestP = '33c03b84-e2d1-4b72-bfa3-129143de6f45'
      const getDestId = await gruposServices.getDestinatarioId(destinatario.idDestinatario)

      let objMessage = helpServices.getMessage(getDestId.data)
      console.log('objMessage', objMessage)

      if(objMessage.code === 200){
        console.log('entroo 200')
        setDestinatario(getDestId.data.data.row)
        console.log('getDestId', getDestId.data.data.row);
      }else{
        mostrarMensaje(objMessage.code, objMessage.message)
      } 
      console.log('getDestId', getDestId);
    }
    setTipoGrupo(tipoGrupo)
    setOpcionLista(op)
    setIdGrupo(idGrupoSend)
    setIsOpenAg(true)
    setColorHeader2Ag('text-align-left')

  }
  const handleModal = (op) => {
    setHeader('Crear nueva lista')
    setModalButton('Crear')
    setIsOpen(true)
    setLabel1('Nombre de la lista')
    setColorHeader('text-align-left')
  }
  const handleclose = (accion, detail) => {
    console.log('ejecutaCierremodal',accion);
    if(accion){
      getDataListas()

      if(detail === 'OK'){
        setIsOpenInfo(true);
        mostrarMensaje(200, global.PROCESO_OK);  
      }  
    }
    setIsOpen(false)
  }
  const handlecloseAgg = (accion) => {
    console.log('ejecutaCierremodal',accion);
    if(accion) {
      refreshDestinatarios(idGrupo)
      // $(`#refreshDest_${idGrupo}`).click()
     // getDataListas()
     
      mostrarMensaje(200, global.PROCESO_OK)
    }
    setIsOpenAg(false)
    setDestinatario({})
    setIdGrupo('')
    setHeaderAg('')
    setModalButton2Ag('')
  }
  useEffect(() => {
    try {
      getDataListas()
    } catch (e) {
    }
    validarPermisos();
  }, [])
  const getDataListas = async () => {
  setSpiner(true)
  // console.log('buscarGrupo',$('#buscarGrupo').prop('value'));
  // $('#buscarGrupo').prop('value', '')
  console.log('objetoSend', obSe);
  let paramsGrupo = helpServices.formatUrlParams(obSe)
  console.log('params', paramsGrupo);

  let templates = await gruposServices.getGruposDestinatarios(paramsGrupo)
  console.log(templates)
  let objMessage = helpServices.getMessage(templates.data)
      console.log('objMessage', objMessage)

      if(objMessage.code === 200){
        console.log('entroo 200')
        if(templates.data.data.rows.length > 0){
          for(let i = 0; i < templates.data.data.rows.length; i++){
            let objetoSend = {
              idGrupo: templates.data.data.rows[i].idGrupo,
              filtro: 'fecha',
              nombreBusqueda: '',
              pagina: 1,
              size: 5
            }
            let params = helpServices.formatUrlParams(objetoSend)
            let det = await gruposServices.getDestinatariosXGrupo(params)
            console.log('det', det);

            let objMessage2 = helpServices.getMessage(det.data)
            console.log('objMessage2', objMessage2)

            if(objMessage2.code === 200){
              templates.data.data.rows[i].listaDestinatarios = det.data.data.rows;
              templates.data.data.rows[i].totalDestinatarios = det.data.data.totalItems;
            }else{
              templates.data.data.rows[i].listaDestinatarios = [];
              templates.data.data.rows[i].totalDestinatarios = 0;
              mostrarMensaje(objMessage2.code, objMessage2.message)
            }
          }
          setListas(templates.data.data.rows)
          setListasGen(templates.data.data.rows)
        }else{
          setListas([])
          setListasGen([])
        }
       
        let totalPageResult = templates.data.data.totalItems / obSe.size;
        console.log('totalGrupos', totalPageResult);
       setTotalPage(Math.ceil(totalPageResult))
       setProcessData(true)
       setTotalGrupo(templates.data.data.totalItems)
      }else{
        mostrarMensaje(objMessage.code, objMessage.message)
      } 

  setSpiner(false)
  }
  const searchByName = (event, initPageS) => {
    console.log('event', event);
    let valid = helpServices.validaNombres(event)
    if(!valid) return event.preventDefault();

    if (event.charCode === 13) {
      console.log('value', event.target.value);
      setPageActual(initPageS + 1)
      setInitPage(initPageS)

      obSe.nombreBusqueda = event.target.value;
      obSe.size = perPage;
      obSe.pagina = 1;

      getDataListas()
      console.log('initPage', initPage);
      setProcessData(false)
    }
  }
  const handlePageClick = (event) => {
    console.log('event.selected', event);
    if(event.nextSelectedPage !== undefined){
      setInitPage(event.nextSelectedPage)
      setPageActual(event.nextSelectedPage + 1)
    
      obSe.nombreBusqueda = nombreBusqueda;
      obSe.size = perPage;
      obSe.pagina = event.nextSelectedPage + 1;
      getDataListas()
    }
  }
  const refreshDestinatarios = (idGrupoRefresh) => {
    $(`#refreshDest_${idGrupoRefresh}`).click()
  }
  const refreshDataGrupos = () => {

    // setInitPage(initPageS)
    // setNombreBusqueda(name)
    // setPerPage(perPageS)

    obSe.nombreBusqueda = nombreBusqueda;
    obSe.size = perPage;
    obSe.pagina = initPage + 1;

    getDataListas()
  }

  //  Funcion para restringir los permisos 
  const [isValidCrearListas, setIsValidCrearListas] = useState(false);
  const [isValidConsultarListas, setIsValidConsultarListas] = useState(false);
  const validarPermisos = async () => {
    try {
      const response = JSON.parse(localStorage.getItem('permisos'));
      // const response = await loginServices.ValidarToken();
      const tienePermisoCrearListas = response.find(
        (permiso) => permiso.nombre === "CREATE_LISTS"
      );
      setIsValidCrearListas(tienePermisoCrearListas);
      const tienePermisoConsultarListas = response.find(
        (permiso) => permiso.nombre === "VIEW_LISTS"
      );
      setIsValidConsultarListas(tienePermisoConsultarListas);
    } catch (error) {
      console.error(error)
    }
  }
  console.log('isValidCrearListas', isValidCrearListas);

  return (
      <div className='success-panel' id='panel-list'>
        <div className= 'container-fluid'>
          {/* <span onClick={ () => { setIsOpenInfo(true) }}>Prueba Info</span> */}
      <ModalSpiner opt={spiner} />
     {isOpenInfo && <ModalInformacion isOpenInfo={isOpenInfo} header={global.HEADER_SAVE_LISTA} body={global.TEXT_SAVE_LISTA} setIsOpenInfo={setIsOpenInfo}></ModalInformacion>}
     {isOpenAg && <ModalContactListAgg mostrarMensaje={mostrarMensaje} tipoGrupo={tipoGrupo} opcionLista={opcionLista} idGrupo={idGrupo} destinatarioEdit={destinatarioEdit} handlecloseAgg={handlecloseAgg} isOpenAg={isOpenAg} header={headerAg} color={coloHeader2Ag} button={modalButton2Ag}/>}
     {isOpen && <ModalNewMessages mostrarMensaje={mostrarMensaje} isOpen={isOpen} header={header} color={coloHeader} button={modalButton} handleclose ={handleclose}/>}
        <div className='content-head-grupo row col-12 pt-3 pb-2 justify-content-betweend align-items-center pr-0'>
            <div className='col-0'><i className='far fa-list-alt'></i></div>
            <div className='col-3 font-small'>Actualmente tienes {totalGrupos} listas </div>
            <div className='content-paginate d-flex col-4'>
              { processData &&
              <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onClick={ (event) => { handlePageClick(event) } }
                  pageRangeDisplayed={1}
                  pageCount={totalPage}
                  previousLabel="<"
                  initialPage={initPage}
                  // forcePage={initPage}
                  className="paginate-custom"
                  renderOnZeroPageCount={null}
                />
              }
            </div>
            <div className='search-campaign col-3 row ml-3'>
                <div className="row no-gutters align-items-center">
                  <div className="col col-md-12">
                  <input id="buscarGrupo" 
                         onChange={({ target }) => setNombreBusqueda(target.value)} 
                         onKeyPress={(event) => { searchByName(event, 0) }} 
                         className='ml-2 input_buscar col-9' 
                         type="text" 
                         placeholder={global.TEMPLATE_SEARCH}></input>
                  </div>
                  <div className="col-auto contacts-search">
                    <a className="btn text-dark border-0 rounded-pill disabled-a">
                      <i className="fa fa-search disabled-a grey-color"></i>
                    </a>
                  </div>
                </div>
            </div>
            {isValidCrearListas && (
               <div className='col-2'>
                <a type='button' onClick={() => { handleModal(1) }} className="btn btn-success btn-campania contacts">
                  <b>
                    <i className='far fa-list-alt'></i> Crear nueva lista
                  </b>
                </a>
              </div>

            )}
           
            <div data-tip="Actualizar">
              <i onClick={ (event) => { refreshDataGrupos() } } className='fa fa-sync-alt fa-sync-color'></i>
              <ReactTooltip place="bottom" type="dark" effect="solid"/>
            </div>
        </div>
        <div className='success-panel container-fluid' id='panel-list'>
          <div className='scroll container-list scrollbar group-scroll'>
          { dataListas.length > 0 ? 
            dataListas.map((lista, index) => {
              let clase;
              index % 2 === 0 ? clase = '' : clase = 'color-reglon2'
              return <Listas permisosListas = {isValidCrearListas} helpServices={ helpServices } refreshDataGrupos={refreshDataGrupos} refreshDestinatarios={refreshDestinatarios} setSpiner={setSpiner} mostrarMensaje={mostrarMensaje} getDataListas={getDataListas} handleModal={handleModal} key={index} handleModalAgg={handleModalAgg} clase={clase} dataLista={lista} orden={index} />
            }) :
            <>
              <div className='none-data'>
                <span> No hay data disponible</span>
              </div>
            </>
          }

          </div>
        </div>
        </div>
      </div>
  )
}
export default contactLists

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import InputEmoji from 'react-input-emoji'
import '../../../scss/_tenmplateSms.scss'
import ModalSpiner from '../../../components/util/ModalSpiner'
import templateServices from '../../../services/templates'
import ModalCancelTemplate from '../../../components/util/ModalCancelTemplate'
import Picker from 'emoji-picker-react'
import Toast from '../../../components/util/Toast'
import $ from 'jquery'
import helpServices from '../../../services/helper'
const CreateTemplate = () => {
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  const [nameTemplate, setNameTemplate] = useState('')
  const [text, setText] = useState('')
  const [descTemplate, setDescTemplate] = useState('')
  const [textSave, setTextSave] = useState('')
  const [spiner, setSpiner] = useState(false)
  const [isOpenC, setIsOpenC] = useState(false)
  const [headerC, setHeaderC] = useState('')
  const [handleC, setHandleC] = useState()
  const [bodyC, setBodyC] = useState('')
  const [modalButtonC, setModalButtonC] = useState('')
  const [coloHeaderC, setColorHeaderC] = useState('')
  const [modalButtonCerrarC, setModalButtonCerrarC] = useState('')
  const [type, setType] = useState('')
  const [opcion, setOpcion] = useState(1)

  useEffect(() => {
    const idPlantillaWath = localStorage.getItem('idPlantillaWhat')
    if (idPlantillaWath) {
      loadEditTemplate()
    }
  }, [])

  const loadEditTemplate = async () => {
    try {
      setSpiner(true)
      const resp = await templateServices.loadTemplatesEdit()
      setSpiner(false)
      mostrarMensaje(global.CODEERROR, global.LABEL_INPUT_ERROR)
      if (resp.error === 200) {
        setNameTemplate(resp.data.data.nombre)
        setText(resp.dataDet.data.row[0].contenido)
        setDescTemplate(resp.data.data.descripcion)
        if (localStorage.getItem('usarPublica')) {
          localStorage.removeItem('idPlantillaWhat')
          localStorage.removeItem('DetallePlantillaCreadaWath')
          localStorage.removeItem('usarPublica')
        }
      }
    } catch (e) {
    }
  }

  const automaticSave = async () => {
    try {
      saveWhatsTemplates()
    } catch (e) {
    }
  }

  setInterval(automaticSave, 15000000)

  const saveWhatsTemplates = () => {
    save()
  }

  const onEmojiClick = (event, emojiObject) => {
    console.log(emojiObject)
    const sym = emojiObject.unified.split('-')
    const codesArray = []
    sym.forEach(el => codesArray.push('0x' + el))
    const emoji = String.fromCodePoint(...codesArray)
    console.log(emoji)
    const data = text + ' ' + emoji
    const dataSave = text + ' ' + emoji
    setText(data)
    setTextSave(dataSave)
    $('#emoji').removeClass()
    $('#emoji').addClass('no-success-panel')
  }

  const save = async () => {
    try {
      $('#nameTemplate').removeClass('error')
      $('#descTemplate').removeClass('error')
      $('#textTemplate').removeClass('error')
      setSpiner(true)
      const data = await templateServices.saveHeadTemplatesWhat({
        nameTemplate,
        text,
        descTemplate
      })
      setSpiner(false)
      if (data.errorValidation === 0) {
        if (data.code === 200) {
          mostrarMensaje(data.code, global.PROCESO_OK)
          setOpcion(1)
          window.localStorage.removeItem('idPlantillaWhat')
          window.localStorage.removeItem('DetallePlantillaCreadaWath')
          window.localStorage.removeItem('editTipo')
          if (window.localStorage.getItem('newTemplate') === '1') {
            window.localStorage.removeItem('newTemplate')
            setTimeout(() => {
              window.location.href = global.ROUTE_TEMPLASTES
            }, 2000)
          } else {
            if (window.localStorage.getItem('wizard') === '1') {
              window.localStorage.setItem('returnW', 1)
              window.localStorage.removeItem('wizard')
              setTimeout(() => {
                window.location.href = global.ROUTE_WELCOME
              }, 2000)
            } else {
              window.localStorage.setItem('steps', 2)
              setTimeout(() => {
                window.location.href = global.ROUTE_CREATECAMPAIGN
              }, 2000)
            }
          }
        } else {
          if (data.code === 400) {
            mostrarMensaje(data.code, global.CAMPOS_OBLIGATORIOS)
          } else {
            mostrarMensaje(data.code, global.ERROR_SERVICE_OFF)
          }
        }
      } else {
        mostrarMensaje('400', global.CAMPOS_OBLIGATORIOS)
        setOpcion(data.validate.clickPanel)
        data.validate.nameTemplate && $('#' + data.validate.nameTemplate).addClass('error')
        data.validate.descTemplate && $('#' + data.validate.descTemplate).addClass('error')
        data.validate.textTemplate && $('#' + data.validate.textTemplate).addClass('error')
      }
    } catch (e) {
    }
  }

  const handleClose = () => () => {
    setIsOpenC(false)
  }

  const cancelSmsTemplates = () => {
    setIsOpenC(true)
    setColorHeaderC(global.MODAL_HEAD_CANCEL_TEMPLATE)
    setBodyC(global.MODAL_CANCEL_TEMPLATE)
    setModalButtonC('Aceptar')
    setModalButtonCerrarC('Cancelar')
    setHandleC(handleClose)
    setType('E')
  }

  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000)
  }

  const message = (message) => {
    $('.' + global.ERROR_LABEL + '-alert').html('')
    $('.' + global.ERROR_LABEL + '-alert').append(message)
    $('.' + global.ERROR_LABEL).removeClass(global.ERROR_LABEL + '-alert-hide')
    $('.' + global.ERROR_LABEL).addClass(global.ERROR_LABEL + '-alert-show')
    setTimeout(function () {
      $('.' + global.ERROR_LABEL + '-alert').html('')
      $('.' + global.ERROR_LABEL).removeClass(global.ERROR_LABEL + '-alert-show')
      $('.' + global.ERROR_LABEL).addClass(global.ERROR_LABEL + '-alert-hide')
    }, 5000)
  }

  const HandleReturn = () => {
    localStorage.removeItem('Publica')
    window.localStorage.removeItem('idPlantillaWhat')
    window.localStorage.removeItem('DetallePlantillaCreadaWath')
    window.localStorage.removeItem('editTipo')
    if (window.localStorage.getItem('newTemplate') === '1') {
      window.localStorage.removeItem('newTemplate')
      window.location.href = global.ROUTE_TEMPLASTES
    } else {
      if (window.localStorage.getItem('wizard') === '1') {
        window.location.href = global.ROUTE_WELCOME
        window.localStorage.removeItem('wizard')
      } else {
        window.localStorage.setItem('steps', 2)
        window.location.href = global.ROUTE_CREATECAMPAIGN
      }
    }
  }

  const clear = () => {
    setNameTemplate('')
    setDescTemplate('')
    setText('')
    mostrarMensaje('200', 'Se limpió el formulario')
  }
  const handleSegment = (opt) => {
    setOpcion(opt)
  }
  const removeError = (opt) => {
    $('#' + opt.id).removeClass('error')
  }

  const openEmoji = () => {
    if ($('#emoji').hasClass('no-success-panel')) {
      $('#emoji').removeClass()
      $('#emoji').addClass('success-panel')
    } else {
      $('#emoji').removeClass()
      $('#emoji').addClass('no-success-panel')
    }
  }
  return (
    <div className='container-fluid-master'>
      {isOpenToast && <Toast message={messageToast} codigo={codigoToast} />}
      <ModalSpiner opt={spiner} />
      <ModalCancelTemplate isOpen={isOpenC} type={type} header={coloHeaderC} body={bodyC} color={coloHeaderC} button={modalButtonC} buttonCerrar={modalButtonCerrarC} handle={handleC} />

      <form>
        <div className="row">
          <div className="col">
            <div className='col-md-7'><a onClick={HandleReturn}><h4><i className="fa fa-chevron-left" aria-hidden="true"></i>{global.RETURN_TEMPLATE}</h4></a></div>
          </div>
        </div>
      </form>
      <h3 className='center'><b>{global.CREATE_WHTAS_TEMPLATE}</b></h3>
      <hr className='hr2' />
      <form>
        <div className="row">
          <div className="col-md-2">
            <a type="button" title={global.DELETE_BTN} onClick={clear} className="btn btn-outline-dark"><i className='fa fa-eraser'></i></a>
          </div>
        </div>
      </form>
      <div className='row col-12 align-items-center justify-content-center mb-2'>
        <div className='col-4 p-text-l'>
          <div type='button' onClick={() => handleSegment(1)}>{global.CONTENT}
            <div className={opcion === 2 ? 'd-none' : 'success-panel'} id='panel-sub'>
              <hr className='sub-color-template'></hr>
            </div>
          </div>
        </div>
        <div className='col-4'></div>
        <div className='col-4'>
          <div type='button' onClick={() => handleSegment(2)}>{global.TEMPLATE_CONFIG}
            <div className={opcion === 1 ? 'd-none' : 'success-panel'} id='panel-sub2'>
              <hr className='sub-color2-template'></hr>
            </div>
          </div>
        </div>
        {opcion === 1

          ? <div className='container-fluid'>
            <div className="form-group">
              <label className="form-label"><b>{global.TEMPLATE_NAME_SMS}</b><span className="text-input name-input"></span> </label><strong className="obl">{global.REQUIRED}</strong>
              <input onKeyUp={({ target }) => removeError(target)} id="nameTemplate" placeholder={global.TEMPLATE_NAME_SMS} value={nameTemplate} type="text" onChange={({ target }) => setNameTemplate(target.value)} minLength="20" maxLength={global.TEMPLATE_NAME_LENGTH} className="form-control item" pattern='Ingrese Nombre' />
            </div>
            <div className="form-group">
              <label className="form-label"><b>{global.TEMPLATE_DESCRIPTION_SMS}</b><span className="text-input name-input"></span> </label>
              <input onKeyUp={({ target }) => removeError(target)} id="descTemplate" placeholder={global.TEMPLATE_DESCRIPTION_SMS} value={descTemplate} type="text" onChange={({ target }) => setDescTemplate(target.value)} minLength="20" maxLength={global.DESCRIPTION_LENGTH} className="form-control item" pattern='Ingrese Nombre' />
            </div>
          </div>
          : <div className='container-fluid'>
            <div className="form-group">
              <label className="form-label"><b>{global.TEMPLATE_ASUN_SMS}</b><span className="text-area"></span> </label><strong className="obl">{global.REQUIRED}</strong>
              <div className="form-group col-4 position-absolute mb-2">

                  <div>

                    <span><i className='far fa-grin-alt pt-2' onClick={openEmoji}></i></span>
                    <div className = 'no-success-panel' id='emoji'>
                      <Picker onEmojiClick={onEmojiClick} pickerStyle={{ width: '100%', heigth: '20%' }} />
                    </div>

                  </div>

                  </div>
              <textarea onKeyUp={({ target }) => removeError(target)} id="textTemplate" rows='5' placeholder={global.TEMPLATE_ASUN_SMS} value={text} type="text" onChange={({ target }) => setText(target.value)} minLength="0" maxLength={global.WHT_LENGTH} className="form-control item pt-4" />
              <div className='count-text'>
                <span>{`${text.length}/${global.WHT_LENGTH}`}</span>
              </div>
            </div>
          </div>
        }
      </div>
      <div className="container">
        <div className="row">
          <div className="text-center form-inline-div">
            <div className="form-group">
              <button onClick={cancelSmsTemplates} className='btnCancel' >{global.CANCEL}</button> {' '}{' '}{' '}
              <button onClick={saveWhatsTemplates} className='btnAcep' >{global.ACCEPT}</button>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  )
}

export default CreateTemplate

import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import '../../../src/scss/_campaaign.scss'
import { Form } from 'react-bootstrap'
import date from '../../services/global'
import Toast from '../../components/util/Toast'
const CampaignStep4 = () => {
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  const [pDate, setDate] = useState('')
  const [pTime, setTime] = useState('')
  const [t, setT] = useState()
  const d = date.getDate()
  useEffect(() => {
    if (window.localStorage.getItem('señal') === '0') {
      setDate(window.localStorage.getItem('dia'))
    }
    if (window.localStorage.getItem('señal') === '0') {
      setTime(window.localStorage.getItem('hora'))
    }
  }, [])
  const time = () => {
    $('#time_').removeClass('colorRed')
    $('#time_').addClass('colorinput')
    if ($('#r-date').val() === d) {
      setT(date.getTime())
    } else {
      setT('')
    }
  }
  const hDate = () => {
    $('#date_').removeClass('colorRed')
    $('#date_').addClass('colorinput')
  }
  if (pDate !== '') {
    window.localStorage.setItem('dia', pDate)
  }
  if (pTime !== '') {
    window.localStorage.setItem('hora', pTime)
  }

  const handlebtnanterior = (op) => () => {
    if (window.localStorage.getItem('señal') === '0') {
      if (window.localStorage.getItem('steps') === '4') {
        $('#contact-table').dataTable().fnDestroy()
        $('input[name=id_' + window.localStorage.getItem('idPlanSteps2') + ']').prop('checked', true)
        $('#b-siguiente').click()
      }
    }
    window.localStorage.setItem('steps', 3)
    $('#circle4').removeClass()
    $('#circle4').addClass('circle-camp2')
    $('#chevron4').removeClass()
    $('#chevron4').addClass('fas fa-chevron-right fas-camp ml-2')
    $('#panel-campa2').removeClass()
    $('#panel-campa2').addClass('no-success-panel')
    $('#panel-section2').removeClass()
    $('#panel-section2').addClass('success-panel')
    $('#panel-info').removeClass()
    $('#panel-info').addClass('success-panel')
    $('#underlined-position').removeClass()
    $('#underlined-position').addClass('underlined-ajus3')
  }
  const handlebtn = (op) => () => {
    if ((pDate === '' && pTime === '') || ('' + pDate === 'null' && '' + pTime === 'null')) {
      global.LABEL_INPUT_ERROR = global.CAMPAIGN_TIME
      $('#date_').addClass('colorRed')
      $('#time_').addClass('colorRed')
      mostrarMensaje('400', global.LABEL_INPUT_ERROR)
    } else if (pDate === '' || pDate === 'null') {
      global.LABEL_INPUT_ERROR = global.CAMPAIGN_FECHA
      $('#date_').addClass('colorRed')
      mostrarMensaje('400', global.LABEL_INPUT_ERROR)
    } else if (pTime === '' || pTime === 'null') {
      $('#time_').addClass('colorRed')
      global.LABEL_INPUT_ERROR = global.CAMPAIGN_HORA
      mostrarMensaje('400', global.LABEL_INPUT_ERROR)
    } else if ($('#r-date').val() === d && pTime <= t) {
      $('#time_').addClass('colorRed')
      global.LABEL_INPUT_ERROR = global.CAMPAIGN_TIME_ERROR
      mostrarMensaje('400', global.LABEL_INPUT_ERROR)
    } else {
      window.localStorage.setItem('steps', 5)
      $('#circle5').addClass('circle-color')
      $('#panel-campa2').removeClass()
      $('#panel-campa2').addClass('no-success-panel')
      $('#panel-info').removeClass()
      $('#panel-info').addClass('success-panel')
      $('#panel-info1').removeClass()
      $('#panel-info1').addClass('success-panel')
      $('#panel-campa3').removeClass()
      $('#panel-campa3').addClass('success-panel')
      $('#underlined-position').removeClass()
      $('#underlined-position').addClass('underlined-ajus5')
    }
  }
  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000)
  }
  return (
        <div className='no-success-panel' id='panel-campa2'>
          {isOpenToast && <Toast message={messageToast} codigo={codigoToast} />}
            <div className="container-fluid">
                <h5 className='w-100 mt-3 mb-4 ml-3'><b>Elegir cuándo se despachará la campaña</b></h5>
                <div className="row h-container">
                    <div className="col-md-3" id='dates_' onClick={hDate}>
                        <Form.Group controlId="dob" className='form-control container-datepicker' id='date_'>
                            <label className='w-100'>Fecha de envío</label>
                            <input className='date-input' id='r-date' type="date" name="dob" value={pDate} onChange={({ target }) => setDate(target.value)} min={d} />
                        </Form.Group>
                    </div>
                    <div className="col-md-3" onClick={time}>
                        <Form.Group controlId="dob" className='form-control container-datepicker' id='time_'>
                            <label className='w-100'>Hora de envío</label>
                            <input className='date-input' id='r-time' type="time" name="dob" value={pTime} onChange={({ target }) => setTime(target.value)} min={t} />
                        </Form.Group>
                    </div>
                </div>
                <div className='btn-posicionatras mb-5 p-atras'><button className='btnatras' onClick={handlebtnanterior(1)} >Anterior</button></div>
                <div className='btn-posicionsgt mb-5 p-sgt'><button className='btnsgt' id='b-step4' onClick={handlebtn(1)} >Siguiente</button></div>
            </div>
        </div>
  )
}
export default CampaignStep4

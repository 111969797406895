/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import '../../scss/_campaaign.scss'
import ReactPaginate from 'react-paginate'
import date from '../../services/global'
import $ from 'jquery'
import Toast from '../../components/util/Toast'
import serviceReport from '../../services/report'
import ReactTooltip from 'react-tooltip'
import ModalSpiner from '../../components/util/ModalSpiner'
import helpServices from '../../services/helper'
import ModalCampaignConfirmacion from '../../components/util/ModalCampaignConfirmacion'
import campaignServices from '../../services/campaign'
import Select from 'react-select'
const ListaNegra = () => {
  const [fechaInicio, setFechaInicio] = useState(null)
  const [fechaFin, setFechaFin] = useState(null)
  const [correo, setCorreo] = useState(null)
  const [idCampana, setIdCampana] = useState(null)
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [messageToast, setMessageToast] = useState('')
  const [codigoToast, setCodigoToast] = useState(400)
  const [spiner, setSpiner] = useState(false)
  const [dataReport, setDataReport] = useState([])
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const d = date.getDate()

  const [isOpen, setIsOpen] = useState(false)
  const [header, setHeader] = useState()
  const [classbody, setClassbody] = useState()
  const [color, setColor] = useState()
  const [body, setBody] = useState()
  const [button, setButton] = useState()
  const [buttonCerrar, setButtonCerrar] = useState()
  const [handle, setHandle] = useState()
  const [btnAceptar, setBtnAceptar] = useState()

  const [processData, setProcessData] = useState(false)
  const [initPage, setInitPage] = useState(0)
  const [pageActual, setPageActual] = useState(1)
  const [perPage, setPerPage] = useState(5)
  const [nombreBusqueda, setNombreBusqueda] = useState('')
  const [totalPage, setTotalPage] = useState(0)
  const [searchCampaign, setsearchCampaign] = useState('')
  const [dataCampaign, setDataCampaign] = useState([])
  const obSe = {
    filtro: 'fecha',
    nombreBusqueda: '',
    pagina: 1,
    size: 5
  }
  useEffect(() => {
    onKeyUpSearch()
  }, [])

  const handleGenerar = () => {
    console.log((fechaInicio, fechaFin, correo === ''))
    if (('' + fechaInicio === '' && '' + fechaFin === '' && '' + correo === '') || ('' + fechaInicio === 'null' && '' + fechaFin === 'null' && '' + correo === 'null')) {
      global.LABEL_INPUT_ERROR = global.CAMPAIGN_FECHA
      $('#fechaInicio').addClass('error')
      $('#fechaFin').addClass('error')
      mostrarMensaje('400', global.LABEL_INPUT_ERROR)
    } else if (fechaInicio === null && fechaFin === null) {
      console.log(3)
      global.LABEL_INPUT_ERROR = global.CAMPAIGN_FECHA
      $('#fechaInicio').addClass('error')
      $('#fechaFin').addClass('error')
      mostrarMensaje('400', global.LABEL_INPUT_ERROR)
    } else if (fechaInicio > fechaFin) {
      $('#fechaInicio').addClass('error')
      mostrarMensaje('400', 'Su rango de fecha es inválido')
    } else {
      $('#fechaInicio').removeClass('error')
      $('#fechaFin').removeClass('error')
      setSpiner(true)
      getDataReport()
    }
  }
  const mostrarMensaje = (codigo, mensaje) => {
    setCodigoToast(codigo)
    setMessageToast(mensaje)
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 1000)
  }
  const getDataReport = async () => {
    setSpiner(true)
    const serviceRe = await serviceReport.getListaNegra({ fechaInicio, fechaFin, obSe, correo, idCampana })
    setSpiner(false)
    if (serviceRe.data.code === 200) {
      if (serviceRe.data.data.totalItems > 0) {
        $('#menu-search').removeClass()
        $('#menu-search').addClass('success-panel col-12')
        $('.fa-file-excel').addClass('green-color')
        $('.fa-arrow-down').addClass('red-color')
        $('.btnEXCEL').addClass('color-border')
      } else {
        $('#menu-search').removeClass()
        $('#menu-search').addClass('no-success-panel')
        $('.fa-file-excel').removeClass('green-color')
        $('.fa-arrow-down').removeClass('red-color')
        $('.btnEXCEL').removeClass('color-border')
      }
      setProcessData(true)
      setDataReport(serviceRe.data.data.rows)
      setTotal(serviceRe.data.data.totalItems)
      setTotalPage(Math.ceil(serviceRe.data.data.totalItems / obSe.size))
    } else {
      mostrarMensaje(serviceRe.data.code, serviceRe.data.message)
    }
  }

  const handlePageClick = (event) => {
    console.log('event.selected', event)
    if (event.nextSelectedPage !== undefined) {
      setInitPage(event.nextSelectedPage)
      setPageActual(event.nextSelectedPage + 1)
      obSe.nombreBusqueda = nombreBusqueda
      obSe.size = perPage
      obSe.pagina = event.nextSelectedPage + 1
      getDataReport()
    }
  }

  const searchPerPage = (initPageS, value) => {
    setInitPage(initPageS)
    setPageActual(Number(initPageS))
    setProcessData(false)

    obSe.size = Number(value)
    obSe.nombreBusqueda = nombreBusqueda
    obSe.pagina = 1

    getDataReport()
    console.log('initPageS', initPageS)
  }

  const searchByName = (event, initPageS) => {
    console.log('event', event)
    let valid = helpServices.validaNombres(event)
    if (!valid) return event.preventDefault()
    if (event.charCode === 13) {
      console.log('value', event.target.value)
      setPageActual(initPageS + 1)
      setInitPage(initPageS)

      obSe.nombreBusqueda = event.target.value
      obSe.size = perPage
      obSe.pagina = 1

      getDataReport()
      console.log('initPage', initPage)
      setProcessData(false)
    }
  }

  const deleterListaNegra = (id, user) => {
    console.log('usuari', user)
    const usuario = JSON.parse(window.localStorage.getItem('loggedUser'))
    console.log(usuario.idUsuario)
    if (user === usuario.idUsuario) {
      const eliminar = () => async () => {
        setSpiner(true)
        setIsOpen(false)
        console.log(id)
        const data = await serviceReport.deleteListaNegra({ id })
        console.log(data.data.code)
        if (data.data.code === 200) {
          console.log('si')
          setSpiner(false)
          $('#prog-table-report').dataTable().fnDestroy()
          getDataReport()
          mostrarMensaje('' + data.data.code, '' + global.PROCESO_OK)
        } else {
          setSpiner(false)
          mostrarMensaje('' + data.data.code, 'No se pudo eliminar la programación, intente más tarde')
        }
      }
      setIsOpen(true)
      setColor()
      setHeader()
      setClassbody()
      setBody('¿Está seguro de eliminar lista negra?')
      setBtnAceptar(eliminar)
      setButton('Aceptar')
      setHandle(handleClose)
      setButtonCerrar('Cancelar')
    } else {
      mostrarMensaje('400', 'No se puede eliminar de la lista negra de otro usuario.')
    }
  }
  const handleClose = () => () => {
    setIsOpen(false)
  }
  const handleExcel = async () => {
    if ($('.fa-file-excel').hasClass('green-color')) {
      setSpiner(true)
      const localData = JSON.parse(window.localStorage.getItem('loggedUser'))
      const servicePdf = await serviceReport.getEXCELCampaingn({ fechaInicio, fechaFin, correo, idCampana })
      setSpiner(false)
      if (servicePdf.code === 200) {
        const url = window.URL.createObjectURL(new Blob([servicePdf.data]))
        const link = document.createElement('a')
        console.log(link)
        link.href = url
        link.setAttribute('download', 'reporteListaNegra.xlsx')
        document.body.appendChild(link)
        link.click()
      }
    }
  }

  const onKeyUpSearch = async (event) => {
    const campaign = await campaignServices.getCampaignTable({})
    setDataCampaign(campaign.data.data)
    if (event.keyCode === 13) {
      setSpiner(true)
      const campaign = await campaignServices.getCampaignSeach({
        search: searchCampaign
      })
      setDataCampaign(campaign.data.data)
    } else {
      if (event.keyCode === 8) {
        if (searchCampaign === '') {
          setSpiner(true)
          const campaign = await campaignServices.getCampaignTable({})
          setDataCampaign(campaign.data.data)
        }
      }
    }
    setSpiner(false)
  }
  const options = dataCampaign.map((item) => ({
    value: item.idCampana,
    label: item.campana
  }))

  const handleSelectChange = (selectedOption) => {
    console.log(selectedOption)
    setIdCampana(selectedOption.value)
  }
  return (
        <div className='container-fluid p-0'>
          <ModalSpiner opt={spiner} />
          {isOpenToast && <Toast message={messageToast} codigo={codigoToast} />}
          <ModalCampaignConfirmacion isOpen={isOpen} classbody={classbody} color={color} header={header} body={body} button={button} buttonCerrar={buttonCerrar} handle={handle} btnAceptar={btnAceptar}/>
            <div className="mb-12 panel-group pt-3 pb-3 header-report mb-4">
                <div className='d-flex'>
                    <div className='col-5 p-0'>
                        <span className='campaing-tittle'><b>Lista Negra</b></span>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='form-group col-md-2'>
                    <span className="text-input name-input every-label" id='error_fecha'></span>
                    <label className='grey-color'>Fecha de inicio</label>
                    <input id='fechaInicio'
                          value={fechaInicio}
                          type="date"
                          max={d}
                          onChange={({ target }) => setFechaInicio(target.value)}
                          className="form-control item"></input>
                </div>
                <div className='form-group col-md-2'>
                    <span className="text-input name-input every-label" id='error_fecha'></span>
                    <label className='grey-color'>Fecha de finalización</label>
                    <input id='fechaFin'
                          value={fechaFin}
                          type="date"
                          max={d}
                          onChange={({ target }) => setFechaFin(target.value)}
                          className="form-control item"></input>
                </div>
                <div className='form-group col-md-2'>
                    <span className="text-input name-input every-label"></span>
                    <label className='grey-color'>Correo</label>
                    <input id='correo'
                          value={correo}
                          type="text"
                          max={d}
                          onChange={({ target }) => setCorreo(target.value)}
                          className="form-control item"></input>
                </div>
                  <div className='form-group col-md-2 p-0 size-select'>
                  <label className='grey-color'>Campaña</label>
                  <Select
                  className='grey-color col-12 '
                      options={options}
                      placeholder="Seleccionar Campaña"
                      isSearchable={true}
                      // value={idCampana}
                    onChange={handleSelectChange}
                    />
                  </div>
                <div className='form-group col-1' >
                    <label className='grey-color'>Descarga Excel</label>
                    <div className='row col btnEXCEL ml-3' onClick={handleExcel}><i className="far fa-file-excel"></i> <i className="fa fa-arrow-down ml-1" aria-hidden="true"></i></div>
                </div>
                <div className='form-group col-md-2 button-generate report-listas'>
                  <a type='button' onClick={() => { handleGenerar() }} className="btn btn-success btn-campania contacts report-btn">
                    <b>
                     Generar
                    </b>
                  </a>
                </div>
            </div>
            <div className="panel-group pt-2 pb-2 header-report mb-3 data-report">
                <div className='d-flex align-items-center'>
                    <div className='col-5 p-0'>
                        <span className='campaing-tittle'><b>Historial de Reportes</b></span>
                    </div>
                    <div className='col-7 show-cant'>
                        <i className='fa fa-chart-pie'></i> <span>Actualmente tienes { total } reportes </span>
                    </div>
                </div>
            </div>
            <div className="mb-12 panel-group data-report listas-report">
            <div className='no-success-panel' id='menu-search' >
              <div className='search-table search-tables row' >
                <div className='col-md-4 search-perPage'>
                  <label className='mr-2 font-sizze'> Mostrando: </label>
                  <select onChange={({ target }) => { setPerPage(target.value); searchPerPage(0, target.value) } }
                        value={perPage}
                        id='selectPerPage'
                        className="form-control item styled-select mr-2" >
                      <option value="5" >5</option>
                      <option value="10" >10</option>
                      <option value="15" >15</option>
                  </select> <label className='font-sizze'>Registros </label>
                </div>
                <div className='search-name col-md-8'>
                  <div className="row no-gutters align-items-center">
                    <div className="col col-md-12">
                      <input onKeyPress={(event) => { searchByName(event, 0) }} onChange={({ target }) => setNombreBusqueda(target.value)} value={nombreBusqueda} type='text' className='input_buscar pl-3 font-sizze' placeholder='Buscar por Nombre'></input>
                    </div>
                    <div className="col-auto contacts-search">
                      <a className="btn text-dark border-0 rounded-pill disabled-a">
                        <i className="fa fa-search disabled-a grey-color"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <table id='prog-table-report' className="table">
            <thead>
              <tr>
                {/* <th>Usuario</th> */}
                <th>Correo</th>
                <th>Ticket</th>
                <th>Fecha Registro</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody id='tbody'>
            {dataReport.length > 0
              ? dataReport.map((val, index) => {
                return (<React.Fragment key={val.index}>
                                  <tr key={`tr_${val.index}`}>
                                    {/* <td>{val.usuarioCreacion}</td> */}
                                    <td className='dataTables_empty'>
                                      {val.mail.length > 25
                                        ? <div className='ajust-text' data-tip={`<p class="content-tool"> ${val.mail.toLowerCase()}</p>`} data-html={true}>
                                        {val.mail.toLowerCase()}
                                        <ReactTooltip place="bottom" type="dark" effect="solid"/>
                                        </div>

                                        : <>
                                        <span>{val.mail.toLowerCase()}</span>
                                        </>
                                      }
                                    </td>
                                    <td>{val.idTicket}</td>

                                    <td>{val.fechaRegistro}</td>
                                    <td >
                                    <div className="dropdown">
                                      <a type="text" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <i className="fa fa-ellipsis-v fa-color"></i>
                                      </a>
                                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <div onClick={() => { deleterListaNegra(val.idListaNegra, val.idUsuario) }} >
                                            <i className='ml-2 far fa-trash-alt notify-color'></i>
                                            <span className='ml-3 detail-drop-down'>Eliminar</span>
                                          </div>
                                      </div>
                                    </div>
                                       </td>
                                  </tr>
                                  </React.Fragment>
                )
              }
              )
              : <tr>
              <td colSpan={5}>
                  <span>No hay información</span>
              </td>
            </tr>
            }
            </tbody>
            </table>
            <div className='content-paginate d-flex mb-5'>
              { (processData && totalPage > 0) &&
              <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onClick={ (event) => { handlePageClick(event) } }
                  pageRangeDisplayed={1}
                  pageCount={totalPage}
                  previousLabel="<"
                  initialPage={initPage}
                  // forcePage={initPage}
                  className="paginate-custom"
                  renderOnZeroPageCount={null}
                />
              }
            </div>
          </div>
        </div>
  )
}

export default ListaNegra
